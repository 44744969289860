import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  DropdownMenu,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import { MDBDataTable } from "mdbreact"
import { success } from "toastr"
import { AvForm, AvField } from "availity-reactstrap-validation"
import TitleBar from "./TitleBar"

const ShippingListAdmin = props => {
  const history = useHistory()

  const [modal, setModal] = useState(false)
  const [modalPrice, setModalPrice] = useState(false)

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    callOrderAPI()
  }, [])

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [shippingData, setShippingData] = useState([])
  const [packageData, setPackageData] = useState([])
  const [priceData, setPriceData] = useState([])

  const callOrderAPI = async () => {
    const response = await fetch(url.HAPIVITY_GET_ADMIN_SHIPPING_LIST)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      console.log("Admin shipping list", postresponse.data)
      setShippingData(postresponse.data)
      if (selectedShippingData) {
        if (
          postresponse.data.findIndex(
            item => item.id == selectedShippingData.id
          ) >= 0
        )
          setSelectedShippingData(
            postresponse.data.find(item => item.id == selectedShippingData.id)
          )
      }
      // setTableData(filterData(postresponse.data))
    }

    const priceResponse = await fetch(url.HAPIVITY_GET_ADMIN_PRICE_LIST)

    const postPriceResponse = await priceResponse.json()

    if (postPriceResponse.status == 200) {
      console.log("Admin shipping list", postPriceResponse.data)
      setPriceData(postPriceResponse.data)
    }
  }

  const [actionType, setActionType] = useState(null)

  const onclick_edit_shipping = selectedShippingData => {
    setModal(true)
    setActionType("edit")
    setSelectedShippingData(selectedShippingData)
  }

  const onclick_add_shipping = () => {
    setModal(true)
    setActionType("add")
    setSelectedShippingData(null)
  }

  function handleValidSubmit(event, values) {
    onclick_apply_shipping(values)
  }

  const onclick_apply_shipping = async formValues => {
    console.log(formValues)

    let formdata = new FormData()
    formdata.append("country", formValues.country)
    formdata.append("code", formValues.code)
    formdata.append("region", formValues.region)
    formdata.append("express", formValues.express)
    formdata.append("duration", formValues.duration)
    formdata.append("tracking", formValues.tracking)
    formdata.append("sid", selectedShippingData ? selectedShippingData.id : 0)
    formdata.append("action", actionType)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_EDIT_SHIPPING, requestOptions)
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setModal(false)
      callOrderAPI()
    }
  }

  const [selectedPrice, setSelectedPrice] = useState(null)

  const [selectedShippingData, setSelectedShippingData] = useState(null)

  const onclick_edit_shipping_price = (selectedShippingData, type) => {
    setModalPrice(true)
    setSelectedPrice(selectedShippingData.price_id)
    setSelectedShippingData(selectedShippingData)
  }

  const onclick_apply_shipping_price = async () => {
    let formdata = new FormData()
    formdata.append("price", selectedPrice)
    formdata.append("sid", selectedShippingData.id)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_EDIT_SHIPPING_PRICE,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      callOrderAPI()
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card className="bg-transparent">
              <CardBody>
                <TitleBar />
                <CardTitle className="font-size-20 font-weight-bold">
                  Shipping List
                </CardTitle>
                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block mb-3"
                  style={{ height: "40px" }}
                  onClick={() => {
                    // history.push("/admzzz-shop")
                    onclick_add_shipping()
                  }}
                >
                  {"Add a new Shipping"}
                </button>
                {shippingData &&
                  shippingData.map(item => (
                    <Card className="p-3">
                      <Row>
                        <Col>
                          <Row className="mb-2">
                            <Col>
                              <span className="font-size-16 font-weight-bold">
                                {item.country +
                                  " (" +
                                  item.code +
                                  ")" +
                                  " (" +
                                  item.region +
                                  ")"}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <span className="font-size-14">
                                {item.express}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <span className="font-size-14">
                                {item.duration}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            {Object.entries(item.price).map(([key, value]) => (
                              <Col>
                                <span className="font-weight-bold">
                                  {key + " "}
                                </span>
                                <span>{formatter.format(value)}</span>
                              </Col>
                            ))}
                          </Row>
                          <Row>
                            <Col xs="auto">
                              <button
                                className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                style={{ height: "40px" }}
                                onClick={() => {
                                  // history.push("/admzzz-shop")
                                  onclick_edit_shipping_price(item)
                                }}
                              >
                                {"Edit Price"}
                              </button>
                            </Col>
                            <Col xs="auto">
                              <button
                                className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                style={{ height: "40px" }}
                                onClick={() => {
                                  // history.push("/admzzz-shop")
                                  onclick_edit_shipping(item)
                                }}
                              >
                                {"Edit Shipping"}
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <ModalHeader toggle={() => setModal(!modal)}>
                {actionType == "add" ? "Add" : "Edit"}
                {" Shipping"}
              </ModalHeader>
              <ModalBody>
                {
                  <>
                    <AvField
                      name="country"
                      label="Country"
                      value={
                        selectedShippingData
                          ? selectedShippingData.country
                          : null
                      }
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="Country"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="code"
                      label="Country Code"
                      value={
                        selectedShippingData ? selectedShippingData.code : null
                      }
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="Country code"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="region"
                      label="Region"
                      value={
                        selectedShippingData
                          ? selectedShippingData.region
                          : null
                      }
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="Region"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="express"
                      label="Type"
                      value={
                        selectedShippingData
                          ? selectedShippingData.express
                          : null
                      }
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="Express"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="duration"
                      label="Duration"
                      value={
                        selectedShippingData
                          ? selectedShippingData.duration
                          : null
                      }
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="Duration"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="tracking"
                      label="Tracking"
                      value={
                        selectedShippingData
                          ? selectedShippingData.tracking
                          : null
                      }
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="Tracking"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                  </>
                }
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-8 btn-block"
                  style={{ height: "38px" }}
                  type="submit"
                >
                  {"Save"}
                </button>
                <Button type="button" onClick={() => setModal(!modal)}>
                  Close
                </Button>
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
        <Modal
          toggle={() => setModalPrice(!modalPrice)}
          isOpen={modalPrice}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <ModalHeader toggle={() => setModalPrice(!modalPrice)}>
              {"Edit Price"}
            </ModalHeader>
            <ModalBody>
              {selectedShippingData && (
                <>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-16 font-weight-bold">
                        {selectedShippingData.country}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col>
                      <span className="font-size-16 font-weight-bold">
                        {"Current price"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    {Object.entries(selectedShippingData.price).map(
                      ([key, value]) => (
                        <Col xs="auto">
                          <span className="font-weight-bold">{key + " "}</span>
                          <span>{formatter.format(value)}</span>
                        </Col>
                      )
                    )}
                  </Row>
                </>
              )}
              <AvForm>
                <AvField
                  name="price"
                  value={selectedPrice}
                  className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                  placeholder="Price"
                  type="select"
                  onChange={e => {
                    setSelectedPrice(e.target.value)
                  }}
                >
                  {priceData &&
                    selectedShippingData &&
                    priceData.map((object, index) => (
                      <option value={object.id}>
                        {"(" +
                          object.description +
                          ") MYR" +
                          formatter.format(object.MYR)}
                      </option>
                    ))}
                </AvField>
              </AvForm>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary font-weight-bold font-size-12 border-radius-8 btn-block"
                style={{ height: "38px" }}
                onClick={() => {
                  onclick_apply_shipping_price()
                }}
              >
                {"Save"}
              </button>
              <Button type="button" onClick={() => setModalPrice(!modalPrice)}>
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(ShippingListAdmin))

ShippingListAdmin.propTypes = { user: PropTypes.object }
