import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import { MDBDataTable } from "mdbreact"
import TitleBar from "./TitleBar"

const OrderListAdmin = props => {
  const history = useHistory()

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState("")

  const [modalCommission, setModalCommission] = useState(false)

  useEffect(() => {
    callOrderAPI()
  }, [])

  const [userId, setUserId] = useState(null)

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (
        userJSON.id == 1 ||
        userJSON.id == 66 ||
        userJSON.id == 201 ||
        userJSON.id == 182
      ) {
        setUserId(userJSON.id)
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [tableData, setTableData] = useState([])
  const [pageData, setPageData] = useState([])
  const [countrySelected, setCountrySelected] = useState("United Kingdom")
  const [statusSelected, setStatusSelected] = useState("")

  const [countryDropdown, setCountryDropdown] = useState(false)
  const [statusDropdown, setStatusDropdown] = useState(false)

  useEffect(() => {
    callOrderAPI()
  }, [countrySelected, statusSelected])

  const callOrderAPI = async () => {
    let formdata = new FormData()
    formdata.append("country", countrySelected)
    if (statusSelected != "") formdata.append("status", statusSelected)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_GET_SHIPPING_BY_COUNTRY_LIST,
      requestOptions
    )

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setTableData(filterData(postresponse.data))
      setPageData(postresponse.commission.data)
    }
  }

  const filterData = data => {
    console.log("Filtering")
    let rows = []
    data.forEach(
      item =>
        (item.entitlement == "immediate" ||
          item.distance_completed >= item.total_distance) &&
        rows.push({
          counter: rows.length + 1,
          order_id: item.id,
          order_date: item.created_at.split(" ")[0],
          last_update: item.updated_at.split(" ")[0],
          product: (
            <>
              <Row>
                <Col>{item.name.split(",")[0]}</Col>
              </Row>
              <Row>
                <Col>{item.package_name.split(":")[0]}</Col>
              </Row>
              {item.size && (
                <Row>
                  <Col>{item.size}</Col>
                </Row>
              )}
              {item.color && (
                <Row>
                  <Col>{item.color}</Col>
                </Row>
              )}
            </>
          ),
          completion_status:
            ((item.distance_completed / item.total_distance) * 100).toFixed(2) +
            " %",
          entitlement: item.entitlement,
          total: (
            <>
              <Row>
                <Col>{item.currency + " " + item.amount.toFixed(2)}</Col>
              </Row>
              <Row>
                <Col>{item.payment_status}</Col>
              </Row>
            </>
          ),
          name: item.first_name + " " + item.last_name,
          contact: (
            <>
              <Row>
                <Col>{item.contact}</Col>
              </Row>
              <Row>
                <Col>{item.email}</Col>
              </Row>
            </>
          ),
          delivery_detail: orderAddress(item),
          shipping_status:
            item.status == "Shipped" ? (
              item.status +
              " " +
              item.tracking_number +
              " " +
              item.shipping_company
            ) : item.entitlement == "immediate" ||
              item.distance_completed >= item.total_distance ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  setModal(true)
                  setModalData(item)
                }}
              >
                {"Ready for shipping"}
              </button>
            ) : (
              item.status
            ),
          tracking_number: item.tracking_number,
          details: (
            <button
              className="btn btn-primary"
              onClick={() => {
                setModal(true)
                setModalData(item)
              }}
            >
              {"Details"}
            </button>
          ),
          // invoice: (
          //   <Link>
          //     <button className="btn btn-primary">{"Print"}</button>
          //   </Link>
          // ),
          // refund: (
          //   <Link>
          //     <button className="btn btn-danger">{"Refund"}</button>
          //   </Link>
          // ),
        })
    )
    return rows
  }

  const orderAddress = data => {
    let address = ""
    address += data.address1 + ", "
    if (data.address2) address += data.address2 + ", "

    address +=
      data.town + ", " + data.state + ", " + data.postcode + ", " + data.country

    return (
      <>
        <Row>
          <Col>{data.address1}</Col>
        </Row>
        {data.address2 && (
          <Row>
            <Col>{data.address2}</Col>
          </Row>
        )}
        <Row>
          <Col>{data.town + ", " + data.state}</Col>
        </Row>
        <Row>
          <Col>{data.postcode}</Col>
        </Row>
        <Row>
          <Col>{data.country}</Col>
        </Row>
      </>
    )
  }

  // const orderAddress = data => {
  //   let address = ""
  //   address += data.address1 + ", "
  //   if (data.address2) address += data.address2 + ", "

  //   address +=
  //     data.town + ", " + data.state + ", " + data.postcode + ", " + data.country

  //   return address
  // }

  const data = {
    columns: [
      {
        label: "No.",
        field: "counter",
        sort: "asc",
      },
      {
        label: "OID",
        field: "order_id",
        sort: "asc",
      },
      {
        label: "Date",
        field: "order_date",
      },
      {
        label: "Product",
        field: "product",
      },
      {
        label: "Completion",
        field: "completion_status",
      },
      // {
      //   label: "Entitlement",
      //   field: "entitlement",
      // },
      {
        label: "Total",
        field: "total",
      },
      {
        label: "Name",
        field: "name",
      },
      {
        label: "Contact",
        field: "contact",
      },
      {
        label: "Shipping Address",
        field: "delivery_detail",
      },
      {
        label: "Shipping",
        field: "shipping_status",
      },
      {
        label: "Last update",
        field: "last_update",
      },
      // {
      //   label: "Details",
      //   field: "details",
      // },
    ],
    rows: tableData,
  }

  function handleValidSubmit(event, values) {
    onclick_update_shipping(values)
    console.log(values)
    console.log(modalData.id)
  }

  const onclick_update_shipping = async formValues => {
    console.log(formValues)

    let formdata = new FormData()
    formdata.append("tracking", formValues.tracking)
    formdata.append("shipping_company", formValues.shipping_company)
    formdata.append("oid", modalData.id)
    // formdata.append("oid", 471)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_UPDATE_SHIPPING_STATUS,
      requestOptions
    )
    const postresponse = await response.json()

    console.log(postresponse)

    if (postresponse.status == 200) {
      setModal(false)
      callOrderAPI()
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <TitleBar />
                <Row className="mb-3">
                  <Col xs="auto">
                    <Dropdown
                      isOpen={countryDropdown}
                      toggle={() => setCountryDropdown(!countryDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Country"} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {userId && userId != 182 && (
                          <DropdownItem
                            onClick={() => {
                              setCountrySelected("Malaysia")
                            }}
                          >
                            {"Malaysia"}
                          </DropdownItem>
                        )}
                        {userId && userId != 182 && (
                          <DropdownItem
                            onClick={() => {
                              setCountrySelected("Singapore")
                            }}
                          >
                            {"Singapore"}
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={() => {
                            setCountrySelected("United Kingdom")
                          }}
                        >
                          {"United Kingdom"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCountrySelected("United States")
                          }}
                        >
                          {"United States"}
                        </DropdownItem>
                        {userId && userId != 182 && (
                          <DropdownItem
                            onClick={() => {
                              setCountrySelected("Australia")
                            }}
                          >
                            {"Australia"}
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Dropdown
                      isOpen={statusDropdown}
                      toggle={() => setStatusDropdown(!statusDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Filter : " + statusSelected}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setStatusSelected("")
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setStatusSelected("Not Shipped")
                          }}
                        >
                          {"Not Shipped"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setStatusSelected("Shipped")
                          }}
                        >
                          {"Shipped"}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <CardTitle className="font-weight-bold font-size-20">
                      {"Shipping " + countrySelected}
                    </CardTitle>
                  </Col>
                  <Col xs="auto">
                    <button
                      className="btn btn-light btn-block waves-effect waves-light font-size-14"
                      style={{
                        borderRadius: "8px",
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        setModalCommission(true)
                      }}
                    >
                      {"Commissions"}
                    </button>
                  </Col>
                </Row>
                <MDBDataTable
                  responsive
                  bordered
                  hover
                  noBottomColumns
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <ModalHeader toggle={() => setModal(!modal)}>
                Order Details
              </ModalHeader>
              {modalData && (
                <ModalBody>
                  <AvField
                    name="shipping_company"
                    // value={selectedPrice}
                    label="Shipping provider"
                    className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-8 border-width-2"
                    placeholder="Shipping provider"
                    type="select"
                    // onChange={e => {
                    //   setSelectedPrice(e.target.value)
                    // }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Required",
                      },
                    }}
                  >
                    <option value={"Evri"} defaultValue>
                      Evri
                    </option>
                    <option value={"Royalmail"}>Royalmail</option>
                    <option value={"Aramex"}>Aramex</option>
                    <option value={"Pgeon Prime"}>Pgeon Prime</option>
                  </AvField>
                  <AvField
                    name="tracking"
                    label="Shipping tracking code"
                    // value={
                    //   selectedShippingData ? selectedShippingData.country : null
                    // }
                    className="form-control font-weight-semibold font-size-14 mb-3"
                    placeholder="Shipping tracking code"
                    type="text"
                    style={{
                      // border: "2px solid",
                      // borderColor: "#F3F4F8",
                      borderWidth: "2px",
                      borderRadius: "8px",
                      height: "40px",
                    }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Required",
                      },
                    }}
                  />
                  <Row className="mb-1">
                    <Col>
                      <span className="font-weight-bold">{"Challenge"}</span>
                    </Col>
                    <Col>
                      <span>{modalData.name}</span>
                    </Col>
                  </Row>
                  {modalData.medal == 1 && (
                    <Row className="mb-1">
                      <Col>
                        <span className="font-weight-bold">{"Medal"}</span>
                      </Col>
                      <Col>
                        <AvGroup check>
                          <Label check>
                            <AvInput
                              type="checkbox"
                              name="medal"
                              trueValue="true"
                              required
                            />{" "}
                            1
                          </Label>
                        </AvGroup>
                      </Col>
                    </Row>
                  )}
                  {modalData.tshirt == 1 && (
                    <>
                      <Row className="mb-1">
                        <Col>
                          <span className="font-weight-bold">{"Jersey"}</span>
                        </Col>
                        <Col>
                          {/* <span>{modalData.tshirt}</span> */}
                          <AvGroup check>
                            <Label check>
                              <AvInput
                                type="checkbox"
                                name="tshirt"
                                trueValue="true"
                                required
                              />{" "}
                              1
                            </Label>
                          </AvGroup>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col>
                          <span className="font-weight-bold">
                            {"Jersey Size"}
                          </span>
                        </Col>
                        <Col>
                          <span>{modalData.size}</span>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col>
                          <span className="font-weight-bold">
                            {"Jersey Color"}
                          </span>
                        </Col>
                        <Col>
                          <span>{modalData.color}</span>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="mt-3 mb-1">
                    <Col>
                      <span className="font-weight-bold">
                        {"Shipping Details"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col>
                      <span className="font-weight-bold">{"Name"}</span>
                    </Col>
                    <Col>
                      <span>
                        {modalData.first_name + " " + modalData.last_name}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col>
                      <span className="font-weight-bold">{"Contact"}</span>
                    </Col>
                    <Col>
                      <span>{modalData.contact}</span>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col>
                      <span className="font-weight-bold">{"Email"}</span>
                    </Col>
                    <Col>
                      <span>{modalData.email}</span>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col>
                      <span className="font-weight-bold">{"Address"}</span>
                    </Col>
                    <Col>
                      <span>{orderAddress(modalData)}</span>
                    </Col>
                  </Row>
                </ModalBody>
              )}
              <ModalFooter>
                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-8 btn-block"
                  style={{ height: "38px" }}
                  type="submit"
                >
                  {"Save"}
                </button>
                <Button type="button" onClick={() => setModal(!modal)}>
                  Close
                </Button>
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
        <Modal
          toggle={() => setModalCommission(!modalCommission)}
          isOpen={modalCommission}
          role="dialog"
          // size="l"
          // className="modal-fullscreen"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          {pageData && (
            <div>
              <ModalHeader toggle={() => setModalCommission(!modalCommission)}>
                {"Commissions"}
              </ModalHeader>
              <ModalBody>
                <Row className="mx-2">
                  <Col style={{ border: "2px solid #f3f3f3" }}>
                    <span className="font-weight-bold font-size-14">
                      {"Year/Month"}
                    </span>
                  </Col>
                  <Col style={{ border: "2px solid #f3f3f3" }}>
                    <span className="font-weight-bold font-size-14 text-primary">
                      {"Amount"}
                    </span>
                  </Col>
                  <Col style={{ border: "2px solid #f3f3f3" }}>
                    <span className="font-weight-bold font-size-14 text-secondary">
                      {"Payment"}
                    </span>
                  </Col>
                </Row>
                {pageData.map((object, index) => (
                  <Row className="mx-2">
                    <Col style={{ border: "2px solid #f3f3f3" }}>
                      <span className="font-size-14">
                        {object.year + "/" + object.month}
                      </span>
                    </Col>
                    <Col style={{ border: "2px solid #f3f3f3" }}>
                      <span className="font-size-14 text-primary">
                        {"MYR " + formatter.format(object.amount)}
                      </span>
                    </Col>
                    <Col style={{ border: "2px solid #f3f3f3" }}>
                      <button
                        className="btn btn-light btn-block waves-effect waves-light w-100 font-size-14 py-0"
                        style={{
                          borderRadius: "8px",
                          height: "20px",
                          fontWeight: 700,
                        }}
                        onClick={e => {
                          e.preventDefault()
                          if (object.receipt != null) {
                            window.open(object.receipt, "_blank")
                          }
                        }}
                      >
                        {object.receipt ? "Download" : "Pending"}
                      </button>
                    </Col>
                  </Row>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={() => setModalCommission(!modalCommission)}
                >
                  Close
                </Button>
              </ModalFooter>
            </div>
          )}
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(OrderListAdmin))

OrderListAdmin.propTypes = { user: PropTypes.object }
