import React from "react"
import ReactDOM from "react-dom"
import App from "App"
import * as serviceWorker from "serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "i18n"
import { Provider } from "react-redux"
//Stripe
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
//sentry
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import store from "store"

const stripePromise = loadStripe(
  // "pk_test_51L5K24GUhLIKCwE6ITSAp8esbRdI5W6lcrqXjvVNSAJVXy7cKlAS7NjyCiVRjU1wvydU5m2gUO63ebRjmFs6WAeJ00ehQDAStx"
  "pk_live_51L5K24GUhLIKCwE6Emxobie53WyNW8gCTsgWqJOQM2U82zhNbzFNs9ZlDHlQoWaVV1x4Fxbe3qE3LZ3atG4RBNL000JFNV8GoR"
)

Sentry.init({
  dsn:
    "https://87c9f27759e54fbfb2b0f557e297d162@o644914.ingest.sentry.io/6540631",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const app = (
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Elements>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
