import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  CardSubtitle,
  Badge,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

import Imgix from "react-imgix"
import { Blurhash } from "react-blurhash"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

import { loginUser } from "store/actions"

const category = [
  { active: true, icon: "bx bx-book-content", name: "All" },
  { active: false, icon: "bx bx-book-content", name: "Sales" },
  { active: false, icon: "bx bx-book-content", name: "New" },
  { active: false, icon: "bx bx-book-content", name: "Joined" },
  { active: false, icon: "bx bx-book-content", name: "Completed" },
]

const Shop = props => {
  const history = useHistory()

  const [currencyDisplay, setCurrency] = useState(null)
  const [country, setCountry] = useState(null)
  const [displayName, setDisplayName] = useState(null)

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   history.push("/dashboard")
    // }

    // switch (geoplugin_currencyCode()) {
    //   case "MYR":
    //     setCurrency("MYR")
    //     break
    //   case "SGD":
    //     setCurrency("SGD")
    //     break
    //   case "AUD":
    //     setCurrency("AUD")
    //     break
    //   case "IDR":
    //     setCurrency("IDR")
    //     break
    //   default:
    //     setCurrency("USD")
    // }
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())

    gtag("event", "page_view", { page_title: "Web Store" })

    if (localStorage.getItem("hUser")) {
      console.log(localStorage.getItem("hUser"))
      const obj = JSON.parse(localStorage.getItem("hUser"))
      setDisplayName(obj.display_name)
    }
    if (localStorage.getItem("hCurrency")) {
      setCurrency(localStorage.getItem("hCurrency"))
    } else {
      getip()
    }
    if (localStorage.getItem("hCountry")) {
      setCountry(localStorage.getItem("hCountry"))
    } else {
      getip()
    }
  }, [])

  const getip = async () => {
    // https://ipapi.co/json
    // const response = await fetch("https://ipinfo.io/?token=08d58819dae512")
    const response = await fetch("https://ipapi.co/json")
    const postresponse = await response.json()

    if (response.status == 200) {
      console.log(postresponse.country)
      updateCurrency(postresponse.country)
      localStorage.setItem("hCountry", postresponse.country)
      setCountry(postresponse.country)
    }
  }

  const updateCurrency = country => {
    switch (country) {
      case "MY":
      case "my":
        setCurrency("MYR")
        localStorage.setItem("hCurrency", "MYR")
        break
      case "SG":
      case "sg":
        setCurrency("SGD")
        localStorage.setItem("hCurrency", "SGD")
        break
      case "AU":
      case "au":
        setCurrency("AUD")
        localStorage.setItem("hCurrency", "AUD")
        break
      case "ID":
      case "id":
        setCurrency("IDR")
        localStorage.setItem("hCurrency", "IDR")
        break
      case "GB":
      case "gb":
        setCurrency("GBP")
        localStorage.setItem("hCurrency", "GBP")
        break
      default:
        setCurrency("USD")
        localStorage.setItem("hCurrency", "USD")
    }
  }

  useEffect(() => {
    if (currencyDisplay) {
      setIsLoading(true)
      getProductList()
    }
  }, [currencyDisplay])

  const [isLoading, setIsLoading] = useState(false)
  const [productData, setProductData] = useState([])
  const [productImageLoaded, setProductImageLoaded] = useState({})
  const getProductList = async () => {
    const response = await fetch(
      url.HAPIVITY_GET_SHOP_PRODUCT_LIST +
        "?currency=" +
        currencyDisplay +
        "&dev=" +
        dev
    )
    const postresponse = await response.json()

    // console.log(postresponse)

    if (response.status == 200) {
      setProductData(postresponse.data)
      // postresponse.data.foreach(object => {
      //   setProductImageLoaded({ ...productImageLoaded, [object.id]: false })
      // })
      setIsLoading(false)
    }
  }

  const [dev, setDev] = useState(0)
  const [user, setUser] = useState(null)
  useEffect(() => {
    if (props.user) {
      setUser(JSON.parse(props.user))
      updateCurrency(JSON.parse(props.user).nationality)
      localStorage.setItem("hCountry", JSON.parse(props.user).nationality)
      setCountry(JSON.parse(props.user).nationality)

      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        setDev(1)
      }
    } else setUser(null)
  }, [props.user])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-0" style={{ backgroundColor: "#FBFBFB" }}>
        <div className="container-fluid">
          {/* <Row className="text-center mt-4 justify-content-center d-none d-md-flex">
            <Col lg={8} className="px-5">
              <span className="text-primary font-size-48 font-weight-extrabold">
                {"Welcome"}
                {user && ", " + user.display_name}
              </span>
            </Col>
          </Row> */}
          <Row className="text-center mt-4 justify-content-center d-none d-md-flex">
            <Col lg={8} className="px-5">
              <span className="text-secondary font-size-24 font-weight-semibold">
                {
                  "All the Virtual Challenge we carefully crafted. More coming soon!"
                }
              </span>
            </Col>
          </Row>
          {/* <ScrollContainer className="scroll-container px-3">
            <Row className="flex-nowrap mt-4">
              {category.map(object => (
                <Col xs="auto" className="ps-0 mb-3">
                  <button
                    className={object.active ? activeClass : inactiveClass}
                    style={{ height: "32px" }}
                    onClick={() => {}}
                  >
                    <i
                      className={
                        object.icon + " font-size-16 align-middle me-1"
                      }
                    />
                    {object.name}
                  </button>
                </Col>
              ))}
            </Row>
          </ScrollContainer> */}
          <Row className="mt-4">
            {/* <Col lg={3} className="mb-3">
              <div className="accordion" id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className={
                        t_category
                          ? "accordion-button shop"
                          : "accordion-button shop collapsed"
                      }
                      type="button"
                      onClick={() => {
                        sett_category(!t_category)
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {"Category"}
                    </button>
                  </h2>

                  <Collapse isOpen={t_category} className="accordion-collapse">
                    <div className="accordion-body pt-0">
                      <div className="form-check form-check-primary mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customCheckcolor1"
                          // checked={customchkPrimary}
                          // onChange={() => {
                          //   setcustomchkPrimary(!customchkPrimary)
                          // }}
                          style={{ height: "16px", width: "16px" }}
                        />

                        <label
                          className="form-check-label font-weight-bold font-size-14"
                          htmlFor="customCheckcolor1"
                        >
                          {"Virtual Challenges"}
                        </label>
                      </div>
                      <div className="form-check form-check-primary">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customCheckcolor1"
                          // checked={customchkPrimary}
                          // onChange={() => {
                          //   setcustomchkPrimary(!customchkPrimary)
                          // }}
                          style={{ height: "16px", width: "16px" }}
                        />

                        <label
                          className="form-check-label font-weight-bold font-size-14"
                          htmlFor="customCheckcolor1"
                        >
                          {"T-Shirt"}
                        </label>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className={
                        t_type
                          ? "accordion-button shop"
                          : "accordion-button shop collapsed"
                      }
                      type="button"
                      onClick={() => {
                        sett_type(!t_type)
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {"Type"}
                    </button>
                  </h2>

                  <Collapse isOpen={t_type} className="accordion-collapse">
                    <div className="accordion-body pt-0">
                      <div className="form-check form-check-primary mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customCheckcolor1"
                          // checked={customchkPrimary}
                          // onChange={() => {
                          //   setcustomchkPrimary(!customchkPrimary)
                          // }}
                          style={{ height: "16px", width: "16px" }}
                        />

                        <label
                          className="form-check-label font-weight-bold font-size-14"
                          htmlFor="customCheckcolor1"
                        >
                          {"Leisure"}
                        </label>
                      </div>
                      <div className="form-check form-check-primary">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customCheckcolor1"
                          // checked={customchkPrimary}
                          // onChange={() => {
                          //   setcustomchkPrimary(!customchkPrimary)
                          // }}
                          style={{ height: "16px", width: "16px" }}
                        />

                        <label
                          className="form-check-label font-weight-bold font-size-14"
                          htmlFor="customCheckcolor1"
                        >
                          {"Survival"}
                        </label>
                      </div>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mt-3">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={
                        t_country
                          ? "accordion-button shop"
                          : "accordion-button shop collapsed"
                      }
                      type="button"
                      onClick={() => {
                        sett_country(!t_country)
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {"Country"}
                    </button>
                  </h2>
                  <Collapse isOpen={t_country} className="accordion-collapse">
                    <div className="accordion-body pt-0">
                      <div className="form-check form-check-primary mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customCheckcolor1"
                          // checked={customchkPrimary}
                          // onChange={() => {
                          //   setcustomchkPrimary(!customchkPrimary)
                          // }}
                          style={{ height: "16px", width: "16px" }}
                        />

                        <label
                          className="form-check-label font-weight-bold font-size-14"
                          htmlFor="customCheckcolor1"
                        >
                          {"Korea"}
                        </label>
                      </div>
                      <div className="form-check form-check-primary mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customCheckcolor1"
                          // checked={customchkPrimary}
                          // onChange={() => {
                          //   setcustomchkPrimary(!customchkPrimary)
                          // }}
                          style={{ height: "16px", width: "16px" }}
                        />

                        <label
                          className="form-check-label font-weight-bold font-size-14"
                          htmlFor="customCheckcolor1"
                        >
                          {"Japan"}
                        </label>
                      </div>
                      <div className="form-check form-check-primary mb-3">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customCheckcolor1"
                          // checked={customchkPrimary}
                          // onChange={() => {
                          //   setcustomchkPrimary(!customchkPrimary)
                          // }}
                          style={{ height: "16px", width: "16px" }}
                        />

                        <label
                          className="form-check-label font-weight-bold font-size-14"
                          htmlFor="customCheckcolor1"
                        >
                          {"United States"}
                        </label>
                      </div>
                      <div className="form-check form-check-primary">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          id="customCheckcolor1"
                          // checked={customchkPrimary}
                          // onChange={() => {
                          //   setcustomchkPrimary(!customchkPrimary)
                          // }}
                          style={{ height: "16px", width: "16px" }}
                        />

                        <label
                          className="form-check-label font-weight-bold font-size-14"
                          htmlFor="customCheckcolor1"
                        >
                          {"Ukraine"}
                        </label>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col> */}
            <Col lg={12}>
              <Row className="d-flex">
                {!isLoading && productData != null ? (
                  productData.map(object => (
                    <Col md={6} xl={4} className="d-flex" key={object.id}>
                      <Card
                        className="border-radius-16"
                        style={{ flexGrow: 1, cursor: "pointer" }}
                        onClick={() => {
                          history.push(object.path + "?id=" + object.id)
                        }}
                      >
                        {!productImageLoaded[object.id] && (
                          <Blurhash
                            hash={object.hash}
                            // width={"100%"}
                            // height={"100%"}
                            resolutionX={32}
                            resolutionY={32}
                            style={{
                              width: "100%",
                              height: "auto",
                              objectFit: "cover",
                              borderTopLeftRadius: "16px",
                              borderTopRightRadius: "16px",
                              borderBottomLeftRadius: "0px",
                              borderBottomRightRadius: "0px",
                              aspectRatio: "1/1",
                            }}
                          />
                        )}
                        <Imgix
                          src={object.image}
                          // sizes="calc(10% - 10px)"
                          className="img-fluid"
                          htmlAttributes={{
                            style: {
                              // height: "295px",
                              objectFit: "cover",
                              borderTopLeftRadius: "16px",
                              borderTopRightRadius: "16px",
                              borderBottomLeftRadius: "0px",
                              borderBottomRightRadius: "0px",
                              aspectRatio: "1/1",
                              display: productImageLoaded[object.id]
                                ? "block"
                                : "none",
                            },
                            onLoad: () =>
                              setProductImageLoaded({
                                ...productImageLoaded,
                                [object.id]: true,
                              }),
                          }}
                        />
                        {/* <CardImg
                          // className="img-fluid"
                          src={object.image}
                          srcSet={`${
                            object.image +
                            "?w=515&h=515&fit=clip&dpr=1&q=75&auto=format"
                          } 1x, ${
                            object.image +
                            "?w=515&h=515&fit=clip&dpr=2&q=37&auto=format"
                          } 2x, ${
                            object.image +
                            "?w=515&h=515&fit=clip&dpr=3&q=25&auto=format"
                          } 3x`}
                          alt="hero"
                          style={{
                            // height: "295px",
                            objectFit: "cover",
                            borderTopLeftRadius: "16px",
                            borderTopRightRadius: "16px",
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px",
                            aspectRatio: "1/1",
                          }}
                        /> */}
                        <CardImgOverlay className="">
                          <Row>
                            <Col>
                              {object.discount != "" && (
                                <Badge className="bg-primary font-size-14 font-weight-extrabold p-2 border-radius-12">
                                  {object.discount}
                                </Badge>
                              )}
                            </Col>
                            {/* <Col xs="auto" className="text-end">
                              <button
                                className="btn bg-white p-0"
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "16px",
                                }}
                              >
                                <img
                                  src={
                                    object.favourite
                                      ? asset.iconHeartActive
                                      : asset.iconHeart
                                  }
                                  srcSet={`${
                                    object.favourite
                                      ? asset.iconHeartActive
                                      : asset.iconHeart
                                  } 300w, ${
                                    object.favourite
                                      ? asset.iconHeartActivex2
                                      : asset.iconHeartx2
                                  } 768w, ${
                                    object.favourite
                                      ? asset.iconHeartActivex3
                                      : asset.iconHeartx3
                                  } 1280w`}
                                  alt="hero"
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                  }}
                                />
                              </button>
                            </Col> */}
                          </Row>
                        </CardImgOverlay>
                        <CardBody>
                          <Row>
                            <Col>
                              <CardTitle
                                className="shop-item-title font-weight-extrabold text-secondary mb-2"
                                style={{
                                  overflow: "hidden",
                                  WebkitLineClamp: 2,
                                  display: "-webkit-box",
                                  lineHeight: "20px",
                                  maxHeight: "40px",
                                }}
                              >
                                {object.name}
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CardSubtitle
                                className="shop-item-subtitle font-weight-semibold color-8F9BB3 mb-3"
                                style={{
                                  overflow: "hidden",
                                  WebkitLineClamp: 2,
                                  display: "-webkit-box",
                                  lineHeight: "18px",
                                  maxHeight: "36px",
                                }}
                              >
                                {object.description}
                              </CardSubtitle>
                            </Col>
                          </Row>
                          <Row className="mt-auto">
                            <Col>
                              <CardSubtitle>
                                {/* {object.discount != null ? (
                                  <span className="shop-item-subtitle text-secondary font-weight-semibold opacity-60 align-middle me-3">
                                    <s>
                                      {object.currency +
                                        " " +
                                        formatter.format(object.price_ori)}
                                    </s>
                                  </span>
                                ) : (
                                  <span className="shop-item-title text-secondary font-weight-extrabold align-middle me-3">
                                    {object.currency +
                                      " " +
                                      formatter.format(object.price_ori)}
                                  </span>
                                )} */}
                                {object.price_discount_min != 0 &&
                                  (object.price_min <
                                  object.price_discount_min ? (
                                    <span className="shop-item-subtitle text-secondary font-weight-semibold opacity-60 align-middle me-3">
                                      <s>
                                        {"From " +
                                          object.currency +
                                          " " +
                                          formatter.format(object.price_min)}
                                      </s>
                                    </span>
                                  ) : (
                                    <span className="shop-item-title text-secondary font-weight-extrabold align-middle me-3">
                                      {"From " +
                                        object.currency +
                                        " " +
                                        formatter.format(
                                          object.price_discount_min
                                        )}
                                    </span>
                                  ))}
                                {/* {object.discount && (
                                  <span className="shop-item-title text-secondary font-weight-extrabold align-middle">
                                    {object.currency +
                                      " " +
                                      formatter.format(object.price_discount)}
                                  </span>
                                )} */}
                              </CardSubtitle>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col>
                    <Row className="justify-content-center mt-4">
                      <Col xs="auto">
                        <Spinner
                          className=""
                          color="primary"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, { loginUser })(Shop))

Shop.propTypes = { loginUser: PropTypes.func }
