import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  Input,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"
import TitleBar from "./TitleBar"
import { select } from "redux-saga/effects"

const ActivityDashboard = props => {
  const history = useHistory()

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     history.push("/dashboard")
  //   }
  // }, [])
  const [admLogin, setadmLogin] = useState(false)

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const [modalAll, setModalAll] = useState(false)
  const [modalCommission, setModalCommission] = useState(false)

  useEffect(() => {
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [props.user])

  useEffect(() => {
    getPageData()
  }, [])

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  const [pageData, setPageData] = useState(null)

  const getPageData = async () => {
    const response = await fetch(url.HAPIVITY_GET_ACTIVITY_BY_COUNTRY_ADMIN)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setPageData(postresponse.data)
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card className="bg-transparent">
              <CardBody>
                <TitleBar />
                {admLogin && pageData && (
                  <>
                    <Row>
                      {pageData &&
                        pageData.map((object, index) => (
                          <Col md={4}>
                            <Row className="mt-3">
                              <Col>
                                <Row className="mb-3">
                                  <span className="font-weight-bold font-size-16">
                                    {object.nationality}
                                  </span>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Card className="p-3">
                                  <ReactApexChart
                                    options={{
                                      labels: object.activity_raw,
                                      // colors: ["#34c38f", "#556ee6", "#f46a6a"],
                                      legend: {
                                        show: true,
                                        position: "bottom",
                                        horizontalAlign: "center",
                                        verticalAlign: "middle",
                                        floating: false,
                                        fontSize: "14px",
                                        offsetX: 0,
                                        offsetY: -10,
                                      },
                                      chart: {
                                        animations: {
                                          enabled: false,
                                        },
                                      },
                                    }}
                                    series={object.activity_count_raw}
                                    type="pie"
                                    height="400"
                                  />
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps)(ActivityDashboard))

ActivityDashboard.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  user: PropTypes.any,
}
