import PropTypes from "prop-types"
import React from "react"

import { Row, Col } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

const TitleBar = props => {
  const history = useHistory()
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-dashboard")
            }}
          >
            {"Dashboard"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-order")
            }}
          >
            {"Order"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-shop")
            }}
          >
            {"Shop"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-package")
            }}
          >
            {"Package"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-price")
            }}
          >
            {"Price"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-photo")
            }}
          >
            {"Photo"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-shipping")
            }}
          >
            {"Shipping"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-promo")
            }}
          >
            {"Promo Code"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-activity-dashboard")
            }}
          >
            {"Activity Dashboard"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-shipping-uk")
            }}
          >
            {"Shipping by Country"}
          </button>
        </Col>
        <Col xs="auto">
          <button
            className="btn btn-link font-weight-bold font-size-16 border-radius-8 btn-block"
            style={{ height: "40px" }}
            onClick={() => {
              history.push("/admzzz-batch-control")
            }}
          >
            {"Batch Control"}
          </button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps)(TitleBar))

TitleBar.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  user: PropTypes.any,
}
