import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
  DropdownMenu,
  Input,
  Label,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

const UserDashboard = props => {
  if (props.location.dashboardSelect == undefined) {
    props.location.dashboardSelect = "profile"
  }

  const [profile, setProfile] = useState(null)
  const [addresses, setAddresses] = useState(null)
  const [orders, setOrders] = useState(null)
  const [selected, setSelected] = useState(props.location.dashboardSelect)
  const [order_number, setOrder_number] = useState(null)
  const [order, setOrder] = useState(null)
  const [address, setAddress] = useState(null)
  const [countries, setCountries] = useState(null)

  useEffect(() => {
    clearSelection()
    setSelected(props.location.dashboardSelect)
    switch (props.location.dashboardSelect) {
      case "profile":
        document.title = "My Profile"
        break
      case "order":
        document.title = "My Order"
        break
      case "address":
        document.title = "Address Book"
        break
    }
  }, [props.location.dashboardSelect])

  useEffect(() => {
    getOrderDetails(order_number)
  }, [order_number])

  useEffect(() => {
    getUserData()
    getSupportedCountries()
  }, [])

  const clearSelection = () => {
    setOrder(null)
    setOrder_number(null)
    setAddress(null)
  }

  const getOrderDetails = async order_number => {
    if (order_number != null) {
      const response = await fetch(
        url.HAPIVITY_GET_ORDER + "?id=" + order_number
      )
      
      const postresponse = await response.json()
      console.log("order details", postresponse)
      setOrder(postresponse.data)
    }
  }

  const getUserData = async () => {
    let [response1, response2, response3] = await Promise.all([
      fetch(url.HAPIVITY_GET_MY_DETAILS + "?uid=" + localStorage.hId),
      fetch(url.HAPIVITY_GET_USER_ADDRESS_BOOK + "?id=" + localStorage.hId),
      fetch(url.HAPIVITY_GET_ORDER_LIST_USER + "?id=" + localStorage.hId),
    ])

    const postresponse1 = await response1.json()
    const postresponse2 = await response2.json()
    const postresponse3 = await response3.json()
    console.log(postresponse1.data.user_details)
    setProfile(postresponse1.data.user_details)
    setAddresses(postresponse2.data)
    setOrders(postresponse3.data)
  }

  const getSupportedCountries = async () => {
    const response = await fetch(url.HAPIVITY_SUPPORTED_COUNTRY + "")
    const postresponse = await response.json()
    console.log("supported countries", postresponse)
    if (postresponse.status == 200) {
      setCountries(postresponse.data)
    }
  }

  const orderHandler = () => {
    return (
      order != null && (
        <>
          <Row className="dashboard-responsive mb-4">
            <span
              className="font-weight-bold font-size-12 text-secondary"
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                padding: "4px 8px",
                width: "fit-content",
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
              }}
              onClick={() => {
                clearSelection()
              }}
            >
              Back
            </span>
          </Row>

          <Row className="font-size-16 font-weight-extrabold text-uppercase dashboard-responsive mb-2">
            Order Status
          </Row>
          <Card className="order-details-card">
            <span
              className="font-weight-semibold font-size-14 text-secondary"
              style={{ padding: "0" }}
            >
              <p style={{ float: "left" }}>{order.status}</p>
            </span>
          </Card>

          <Row className="font-size-16 font-weight-extrabold text-uppercase dashboard-responsive mb-2">
            Order Detail
          </Row>
          <Card className="order-details-card mb-3">
            <Row>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Order ID:</p>
                <p style={{ float: "right" }}>{order.id}</p>
              </span>
            </Row>
            <Row>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Order Date:</p>
                <p style={{ float: "right" }}>{order.created_at}</p>
              </span>
            </Row>
          </Card>

          <Row
            className="dashboard-responsive mb-4"
            style={{
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
              borderRadius: "16px",
              paddingTop: "12px",
              marginRight: "24px",
            }}
          >
            <Col className="d-flex">
              <Card className="bg-transparent shadow-none">
                <Row>
                  <Col>
                    <img
                      src={order.shop.image}
                      style={{
                        borderRadius: "16px",
                        aspectRatio: "1/1",
                        height: "72px",
                        width: "72px",
                      }}
                    />
                  </Col>
                  <Col>
                    <CardBody
                      style={{
                        justifyContent: "start",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                        marginTop: "-19px",
                        marginLeft: "-28px",
                      }}
                    >
                      <CardTitle className="font-weight-extrabold font-size-16 text-secondary">
                        {order.shop.name}
                      </CardTitle>
                      <CardText
                        className="font-weight-semibold font-size-14 text-secondary"
                        style={{
                          marginLeft: "-6px",
                          marginBottom: "-42px",
                          marginTop: "-5px",
                        }}
                      >
                        <ul>
                          <li>Challenge Entry</li>
                          <li>Finisher Medal</li>
                          <li>Finisher Jersey</li>
                        </ul>
                      </CardText>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className="font-size-16 font-weight-extrabold text-uppercase dashboard-responsive mb-2">
            Summary
          </Row>
          <Card className="order-details-card">
            <Row>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Subtotal</p>
                <p style={{ float: "right" }}>
                  {order.currency + " " + order.subtotal.toFixed(2)}
                </p>
              </span>
              {/* <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Tax</p>
                <p style={{ float: "right" }}>Tax</p>
              </span> */}
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Delivery To: {order.country}</p>
                <p style={{ float: "right" }}>
                  {order.currency + " " + order.delivery_price.toFixed(2)}
                </p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Promo: {order.promo_desc}</p>
                <p style={{ float: "right" }}>
                  {"-" + order.currency + " " + order.promo.toFixed(2)}
                </p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>TOTAL</p>
                <p style={{ float: "right" }}>
                  {order.currency +
                    " " +
                    (
                      order.subtotal +
                      order.delivery_price -
                      order.promo
                    ).toFixed(2)}
                </p>
              </span>
            </Row>
          </Card>

          <Row className="font-size-16 font-weight-extrabold text-uppercase dashboard-responsive mb-2">
            Payment Detail
          </Row>
          <Card className="order-details-card">
            <Row>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Payment Type</p>
                <p style={{ float: "right" }}>
                  {order.card_type + " " + order.card_last4}
                </p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Payment Status</p>
                <p style={{ float: "right" }}>{order.payment_status}</p>
              </span>
            </Row>
          </Card>

          <Row className="font-size-16 font-weight-extrabold text-uppercase dashboard-responsive mb-2">
            Delivery Detail
          </Row>
          <Card className="order-details-card">
            <Row>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Recipient Name</p>
                <p style={{ float: "right" }}>
                  {order.first_name + " " + order.last_name}
                </p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Contact No.</p>
                <p style={{ float: "right" }}>{order.contact}</p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Delivery Option</p>
                <p style={{ float: "right" }}></p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "left" }}>Address</p>
                <p style={{ float: "right" }}>
                  {order.address1 + ", " + order.address2}
                </p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "right" }}>
                  {order.address3 == null ? "--" : order.address3}
                </p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "right" }}>{order.town}</p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "right" }}>{order.state}</p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "right" }}>{order.postcode}</p>
              </span>
              <span className="font-weight-semibold font-size-14 text-secondary">
                <p style={{ float: "right" }}>{order.country}</p>
              </span>
            </Row>
          </Card>
        </>
      )
    )
  }

  const dashboardHandler = selected => {
    switch (selected) {
      case "profile":
        return (
          <>
            <Row className="font-weight-bold font-size-16 mb-2 dashboard-responsive">Email</Row>
            <Row className="profile-textbox mb-4">
              {profile && profile.email}
            </Row>
            <Row className="font-weight-bold font-size-16 mb-2 dashboard-responsive">Gender</Row>
            <Row className="profile-textbox mb-4">
              {profile && profile.gender}
            </Row>
            <Row className="font-weight-bold font-size-16 mb-2 dashboard-responsive">
              Nationality
            </Row>
            <Row className="profile-textbox mb-4">
              {profile && profile.nationality}
            </Row>
          </>
        )
      case "address":
        let defaultAddress =
          addresses && addresses.filter(a => a.default_shipping == 1)
        return (
          <>
            {defaultAddress &&
              defaultAddress.map(a => {
                return (
                  <Col
                    className="address-box mb-3"
                    style={{ border: "2px solid #FF3D00" }}
                  >
                    <Row
                      className="mb-2"
                      style={{
                        paddingLeft: "16px",
                      }}
                    >
                      <span
                        className="font-weight-bold font-size-12"
                        style={{
                          color: "white",
                          backgroundColor: "#FF3D00",
                          borderRadius: "8px",
                          padding: "4px 8px",
                          width: "fit-content",
                        }}
                      >
                        Default
                      </span>
                    </Row>
                    <Row className="address-text text-secondary font-weight-extrabold">
                      <p
                        style={{
                          textAlign: "left",
                          marginLeft: "-12px",
                          marginBottom: "2px",
                        }}
                      >
                        {a.name}
                        <span
                          style={{
                            float: "right",
                            marginRight: "-12px",
                          }}
                        >
                          <img
                            src={asset.editaddress}
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            onClick={() => {
                              setAddress(a)
                            }}
                          />
                        </span>
                      </p>
                    </Row>
                    <Row className="address-text text-secondary font-weight-semibold">
                      {a.address1 + ", " + a.address2}
                    </Row>
                  </Col>
                )
              })}
            {addresses &&
              addresses
                .filter(a => a.default_shipping != 1)
                .map(a => {
                  return (
                    <Col className="address-box mb-3">
                      <Row className="address-text text-secondary font-weight-extrabold mb-2">
                        <p
                          style={{
                            textAlign: "left",
                            marginLeft: "-12px",
                            marginBottom: "2px",
                          }}
                        >
                          {a.name}
                          <span
                            style={{
                              float: "right",
                              marginRight: "-12px",
                            }}
                          >
                            <img
                              src={asset.editaddress}
                              style={{
                                width: "24px",
                                height: "24px",
                              }}
                              onClick={() => {
                                setAddress(a)
                              }}
                            />
                          </span>
                        </p>
                      </Row>
                      <Row className="address-text text-secondary font-weight-semibold">
                        {a.address1 + ", " + a.address2}
                      </Row>
                    </Col>
                  )
                })}
          </>
        )
      case "order":
        return (
          <>
            {orders &&
              orders.map(o => {
                return (
                  <Col
                    className="address-box mb-3"
                    onClick={() => setOrder_number(o.order_id)}
                  >
                    <Row
                      style={{
                        paddingLeft: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      {o.created_at}
                    </Row>
                    <hr style={{ margin: "0" }} />
                    <Col className="d-flex">
                      <Card className="bg-transparent shadow-none">
                        <Row
                          className=""
                          style={{ marginTop: "16px", marginBottom: "-8px" }}
                        >
                          <Col>
                            <img
                              src={o.image}
                              style={{
                                borderRadius: "16px",
                                aspectRatio: "1/1",
                                height: "72px",
                                width: "72px",
                              }}
                            />
                          </Col>
                          <Col>
                            <CardBody
                              style={{
                                justifyContent: "start",
                                whiteSpace: "nowrap",
                                textAlign: "left",
                                marginTop: "-19px",
                                marginLeft: "-25px",
                              }}
                            >
                              <CardTitle className="font-weight-extrabold font-size-16 text-secondary mb-1">
                                {o.name}
                              </CardTitle>
                              <CardText className="font-weight-bold font-size-14 text-primary">
                                {o.currency + " " + o.amount.toFixed(2)}
                              </CardText>
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <hr style={{ marginTop: "0" }} />
                    <Row
                      style={{
                        paddingLeft: "16px",
                      }}
                    >
                      {o.status}
                    </Row>
                  </Col>
                )
              })}
          </>
        )
    }
  }

  const addressEditor = () => {
    return address != null && (
      <>
        <Row className="dashboard-responsive">
          <span
            className="font-weight-bold font-size-12 text-secondary mb-3"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              padding: "4px 8px",
              width: "fit-content",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
            }}
            onClick={() => {
              clearSelection()
            }}
          >
            Back
          </span>

          <Row className="font-size-14 font-weight-extrabold text-uppercase mb-2">
            Order Status
          </Row>
          <Col
            style={{
              marginLeft: "-14px",
            }}
          >
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="Address name* (e.g. home)*"
              defaultValue={"" || address.name}
            />
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="First Name*"
              defaultValue={"" || address.first_name}
            />
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="Last Name*"
              defaultValue={"" || address.last_name}
            />
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="Contact no.*"
              defaultValue={"" || address.contact}
            />
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="Address 1*"
              defaultValue={"" || address.address1}
            />
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="Address 2*"
              defaultValue={"" || address.address2}
            />
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="Suburb/town*"
              defaultValue={"" || address.town}
            />
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="State/territory*"
              defaultValue={"" || address.state}
            />
            <Input
              id=""
              name=""
              className="font-size-14 font-weight-semibold edit-address"
              placeholder="Postcode/ZIP Code*"
              defaultValue={"" || address.postcode}
            />
            <select
              className="font-size-14 font-weight-bold form-select border-radius-16 border-width-2 button-outline-f0f0f0 text-secondary p-3 mb-3"
              defaultValue={address.country || "default"}
            >
              <option value="default" disabled hidden>Country*</option>
              {countries 
                && countries.map((c) => {
                  return (
                    <option id={c.id} value={c.country}>
                      {c.country}
                    </option>
                  )
              })}
            </select>
            <Row>
              <p
                className="font-weight-semibold font-size-14"
                style={{
                  textAlign: "left",
                  float: "left",
                }}
              >
                Set as default address    
                <Input
                  id=""
                  name=""
                  style={{
                    float: "right",
                    padding: "16px",
                  }}
                  checked={address.default_shipping}
                  type="checkbox"
                /> 
              </p>
            </Row>            
          </Col>
        </Row>
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content px-0">
        <Row className="mt-4 justify-content-center text-center">
          <Col lg="4" md="4" className="mb-3">
            <Row>
              <Col className="selection-box text-start">
                <Row className="selection-item mt-1 mb-1">
                  <Col
                    onClick={() => {
                      setSelected("profile")
                      document.title = "My Profile"
                      clearSelection()
                    }}
                  >
                    <img
                      src={asset.profile}
                      style={{ width: "24px", height: "24px" }}
                      className="me-2"
                    />
                    <span className="font-size-14 font-weight-bold align-middle">
                      <a className="text-secondary">My Profile</a>
                    </span>
                  </Col>
                </Row>
                <Row
                  className="selection-item mb-1"
                  onClick={() => {
                    setSelected("order")
                    document.title = "My Order"
                    clearSelection()
                  }}
                >
                  <Col>
                    <img
                      src={asset.order}
                      style={{ width: "24px", height: "24px" }}
                      className="me-2"
                    />
                    <span className="font-size-14 font-weight-bold align-middle">
                      <a className="text-secondary">My Order</a>
                    </span>
                  </Col>
                </Row>
                <Row
                  className="selection-item mb-1"
                  onClick={() => {
                    setSelected("address")
                    document.title = "Address Book"
                    clearSelection()
                  }}
                >
                  <Col>
                    <img
                      src={asset.address}
                      style={{ width: "24px", height: "24px" }}
                      className="me-2"
                    />
                    <span className="font-size-14 font-weight-bold align-middle">
                      <a className="text-secondary">Address Book</a>
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg="8" md="8" className="justify-content-center">
            {addressEditor() || orderHandler() || dashboardHandler(selected)}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default UserDashboard
