import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"

const Help = props => {
  const history = useHistory()

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     history.push("/dashboard")
  //   }
  // }, [])

  useEffect(() => {
    getPageData()
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())

    gtag("event", "page_view", {
      page_title: "Web Home",
    })
  }, [])

  const [pageData, setPageData] = useState(null)
  const [t_faq, sett_faq] = useState({})

  const getPageData = async () => {
    const response = await fetch(url.HAPIVITY_HOME_PAGE)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setPageData(postresponse.data)
      postresponse.data[9].content.forEach(object => {
        sett_faq({ ...t_faq, [object.id]: false })
      })
    }
  }

  const componentHandler = (data, index) => {
    switch (data.item) {
      case "faq":
        return (
          <>
            <Row className="mt-4 content-justify-center text-center">
              <Col className="">
                <span
                  className="font-weight-black text-secondary font-size-24 text-uppercase"
                  style={{ paddingLeft: "16px", paddingRight: "16px" }}
                >
                  {"Frequently asked questions"}
                </span>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={6} className="mb-3">
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    {data.content
                      .filter(
                        (object, index) => index < data.content.length / 2
                      )
                      .map(object => (
                        <div key={object.id}>
                          <h2 className="accordion-header">
                            <button
                              className={
                                t_faq[object.id]
                                  ? "accordion-button faq"
                                  : "accordion-button faq collapsed"
                              }
                              type="button"
                              onClick={() => {
                                sett_faq({
                                  ...t_faq,
                                  [object.id]: !t_faq[object.id],
                                })
                                gtag("event", "FAQ")
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {object.title}
                            </button>
                          </h2>

                          <Collapse
                            isOpen={t_faq[object.id]}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body pt-0 faq">
                              {object.content}
                            </div>
                          </Collapse>
                        </div>
                      ))}
                  </div>
                </div>
              </Col>

              <Col md={6} className="mb-3">
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    {data.content
                      .filter(
                        (object, index) => index >= data.content.length / 2
                      )
                      .map(object => (
                        <div key={object.id}>
                          <h2 className="accordion-header">
                            <button
                              className={
                                t_faq[object.id]
                                  ? "accordion-button faq"
                                  : "accordion-button faq collapsed"
                              }
                              type="button"
                              onClick={() => {
                                sett_faq({
                                  ...t_faq,
                                  [object.id]: !t_faq[object.id],
                                })
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {object.title}
                            </button>
                          </h2>

                          <Collapse
                            isOpen={t_faq[object.id]}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body pt-0 faq">
                              {object.content}
                            </div>
                          </Collapse>
                        </div>
                      ))}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-0">
        <div className="container-fluid">
          {/* <div className="container-fluid"> */}
          {pageData &&
            pageData.map((object, index) => componentHandler(object, index))}

          <Row className="mt-4 justify-content-center">
            <Col xs="auto" className="text-center">
              <span className="font-size-24 font-weight-black text-secondary text-uppercase">
                {"Can't find the answer you're looking for?"}
              </span>
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center d-flex">
            <Col md={6} className="d-flex">
              <Card className="border-radius-16" style={{ flexGrow: 1 }}>
                <CardImg
                  className="mx-auto mt-3"
                  src={asset.contactChat}
                  srcSet={`${asset.contactChat} 300w, ${asset.contactChatx2} 768w, ${asset.contactChatx3} 1280w`}
                  alt="hero"
                  style={{
                    height: "48px",
                    width: "48px",
                  }}
                />
                <CardBody>
                  <Row>
                    <span className="font-size-18 font-weight-bold text-black text-center">
                      {"Chat with us using the live chat on our website"}
                    </span>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} className="d-flex">
              <Card
                className="border-radius-16"
                style={{ flexGrow: 1 }}
                // onClick={() => {
                //   window.open("https://help.hapivity.com/", "_blank")
                //   return null
                // }}
              >
                <CardImg
                  className="mx-auto mt-3"
                  src={asset.contactEmail}
                  srcSet={`${asset.contactEmail} 300w, ${asset.contactEmailx2} 768w, ${asset.contactEmailx3} 1280w`}
                  alt="hero"
                  style={{
                    height: "48px",
                    width: "48px",
                  }}
                />
                <CardBody>
                  <Row>
                    <span className="font-size-18 font-weight-bold text-black text-center">
                      {"Email us at: support@hapivity.com"}
                    </span>
                    {/* <span
                      className="btn btn-link font-size-18 font-weight-bold text-center p-0"
                      onClick={() =>
                        (window.location = "mailto:hello@hapivity.com")
                      }
                    >
                      {"support@hapivity.com"}
                    </span> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            {/* <Col md={4} lg={3} xl={2} className="d-flex">
              <Card className="border-radius-16" style={{ flexGrow: 1 }}>
                <CardImg
                  className="mx-auto mt-3"
                  src={asset.contactEmail}
                  srcSet={`${asset.contactEmail} 300w, ${asset.contactEmailx2} 768w, ${asset.contactEmailx3} 1280w`}
                  alt="hero"
                  style={{
                    height: "48px",
                    width: "48px",
                  }}
                />
                <CardBody>
                  <Row>
                    <span className="font-size-18 font-weight-bold text-black text-center">
                      {"Email us at "}
                    </span>
                    <span
                      className="btn btn-link font-size-18 font-weight-bold text-center p-0"
                      onClick={() =>
                        (window.location = "mailto:hello@hapivity.com")
                      }
                    >
                      {"hello@hapivity.com"}
                    </span>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          {/* ===== OLD ======
        <Card>
          <CardImg
            className="img-fluid"
            src={asset.heroHome}
            srcSet={`${asset.heroHome} 300w, ${asset.heroHomex2} 768w, ${asset.heroHomex3} 1280w`}
            alt="hero"
          />
          <CardImgOverlay className="text-center d-flex align-items-center justify-content-center">
            <Row className="">
              <Col className="">
                <CardTitle className="text-primary mt-0 hero-font-title">
                  {"Fitness, redefined"}
                </CardTitle>
                <CardText className="text-secondary hero-font-subtitle font-weight-bold">
                  {"In Hapivity, our vision is to take "}{" "}
                  <strong>Virtual Workout</strong>{" "}
                  {
                    " to another level. We believed that with the right motivation, everyone can achieve their fitness's goal."
                  }
                </CardText>
                <button
                  className="btn btn-primary font-weight-extrabold font-size-16 px-4"
                  style={{ borderRadius: "16px", height: "54px" }}
                  onClick={() => {
                    props.showJoinNowAction(true)
                  }}
                >
                  {"Join now"}
                </button>
              </Col>
            </Row>
          </CardImgOverlay>
        </Card>*/}
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  // const { error } = state.Home
  return {}
}

export default withRouter(connect(mapStateToProps, { showJoinNowAction })(Help))

Help.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
