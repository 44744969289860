import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
  DropdownMenu,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"

const ourStory = [
  "Hapivity is a product by Beuniq.",
  "Beuniq is a seasoned software development company based in Kuala Lumpur, the capital of Malaysia. Over 15 years of experience creating unique software & gaming solutions for businesses worldwide.",
  "Hapivity is a virtual training platform made for people who lead active lifestyles as well as those who constantly struggle to meet their fitness objectives in the midst of a busy daily schedule. The platform includes a virtual challenge experience that enables users to work out while learning about secret hiding places throughout the globe.",
  "Participants can sync their exercise progress, including steps, runs, walks, and cycles, with the Google Street view-powered virtual maps.",
  "Additionally, the theme-based Quest function keeps everyone engaged and motivated at all times by immersing them in a game-like environment.",
  "After successfully completing the Challenge, participants will receive a tangible, gorgeous Medal.",
]

const About = props => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())

    gtag("event", "page_view", { page_title: "Web About" })
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-0">
        <Row className="mt-4 justify-content-center">
          <Card className="shadow-none bg-transparent">
            <Row>
              <CardImg
                className="about-top-image"
                src={"https://hapivity.imgix.net/about-us.png"}
                srcSet={`${
                  "https://hapivity.imgix.net/about-us.png" +
                  "?w=774&h=569&fit=clip&dpr=1&q=75&auto=format"
                } 1x, ${
                  "https://hapivity.imgix.net/about-us.png" +
                  "?w=774&h=569&fit=clip&dpr=2&q=37&auto=format"
                } 2x, ${
                  "https://hapivity.imgix.net/about-us.png" +
                  "?w=774&h=569&fit=clip&dpr=3&q=25&auto=format"
                } 3x`}
              />
            </Row>
          </Card>
        </Row>
        <Row className="mt-2 justify-content-center">
          <Card className="shadow-none bg-transparent">
            <Row>
              <Col lg={{ offset: 1, size: 5 }}>
                <CardBody>
                  <Row className="mb-3">
                    <span className="about-text font-weight-black">
                      {"OUR STORY"}
                    </span>
                  </Row>
                  {ourStory.map(text => (
                    <Row className="mt-3 about-text font-weight-semibold">
                      <span>{text}</span>
                    </Row>
                  ))}
                  <Row className="mt-5">
                    <span className="text-primary font-weight-extrabold font-size-16">
                      <a
                        href="http://beuniq.my/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {"FIND OUT MORE ABOUT US"}
                      </a>
                    </span>
                  </Row>
                </CardBody>
              </Col>
              <Col lg="5">
                <CardImg
                  className="about-side-image"
                  src={"https://hapivity.imgix.net/about-us.png"}
                  srcSet={`${
                    "https://hapivity.imgix.net/about-us.png" +
                    "?w=774&h=569&fit=clip&dpr=1&q=75&auto=format"
                  } 1x, ${
                    "https://hapivity.imgix.net/about-us.png" +
                    "?w=774&h=569&fit=clip&dpr=2&q=37&auto=format"
                  } 2x, ${
                    "https://hapivity.imgix.net/about-us.png" +
                    "?w=774&h=569&fit=clip&dpr=3&q=25&auto=format"
                  } 3x`}
                />
              </Col>
            </Row>
          </Card>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default About
