import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

const ContestTerm = props => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())

    gtag("event", "page_view", { page_title: "Web Contest Term" })

    getPageData()
  }, [])

  const [pageData, setPageData] = useState(null)

  const getPageData = async () => {
    const response = await fetch(url.HAPIVITY_CONTEST_TERM)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setPageData(postresponse.data)
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-5">
        <Row className="justify-content-center">
          <Col lg={10}>
            {pageData && <h1 className="mt-3">{pageData.title}</h1>}
            {pageData && pageData.content.map((item, index) => <p>{item}</p>)}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ContestTerm
