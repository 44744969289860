import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"

const Home = props => {
  const history = useHistory()

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     history.push("/dashboard")
  //   }
  // }, [])
  const [admLogin, setadmLogin] = useState(false)
  const [admUK, setadmUK] = useState(false)

  useEffect(() => {
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        setadmLogin(true)
      }

      if (userJSON.id == 201 || userJSON.id == 182) {
        setadmUK(true)
      }
    }
  }, [props.user])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-0">
        {(admLogin || admUK) && (
          <div className="container-fluid">
            <Row className="mt-3">
              <Col>
                <Row className="mb-3">
                  <span className="font-weight-bold font-size-16">Admin</span>
                </Row>
                {admLogin && (
                  <>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-dashboard")
                          }}
                        >
                          {"Dashboard"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-order")
                          }}
                        >
                          {"Order"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-shop")
                          }}
                        >
                          {"Shop"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-package")
                          }}
                        >
                          {"Package"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-price")
                          }}
                        >
                          {"Price"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-photo")
                          }}
                        >
                          {"Photo"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-shipping")
                          }}
                        >
                          {"Shipping"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-promo")
                          }}
                        >
                          {"Promo Code"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-activity-dashboard")
                          }}
                        >
                          {"Activity Dashboard"}
                        </button>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "40px" }}
                          onClick={() => {
                            history.push("/admzzz-batch-control")
                          }}
                        >
                          {"Batch Control"}
                        </button>
                      </Col>
                    </Row>
                  </>
                )}
                <Row className="mb-3">
                  <Col>
                    <button
                      className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                      style={{ height: "40px" }}
                      onClick={() => {
                        history.push("/admzzz-shipping-uk")
                      }}
                    >
                      {"Shipping by Country"}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps)(Home))

Home.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  user: PropTypes.any,
}
