import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"

import {
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  Tooltip,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  CardSubtitle,
  Badge,
  Spinner,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory, useLocation } from "react-router-dom"

//Stripe
import { loadStripe } from "@stripe/stripe-js"
import CheckoutForm from "pages/Hapivity/CheckoutForm"
import {
  CardElement,
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import ScrollContainer from "react-indiana-drag-scroll"

const stripePromise = loadStripe(
  // "pk_test_51L5K24GUhLIKCwE6ITSAp8esbRdI5W6lcrqXjvVNSAJVXy7cKlAS7NjyCiVRjU1wvydU5m2gUO63ebRjmFs6WAeJ00ehQDAStx"
  "pk_live_51L5K24GUhLIKCwE6Emxobie53WyNW8gCTsgWqJOQM2U82zhNbzFNs9ZlDHlQoWaVV1x4Fxbe3qE3LZ3atG4RBNL000JFNV8GoR"
)

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ShopPayment = props => {
  const history = useHistory()
  const query = useQuery()
  const id = query.get("id")

  const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM })

  const [currencyDisplay, setCurrency] = useState(null)
  const [clientSecret, setClientSecret] = useState("")

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    if (localStorage.getItem("hCurrency")) {
      setCurrency(localStorage.getItem("hCurrency"))
    }
  }, [])

  useEffect(() => {
    if (currencyDisplay) {
      setIsLoading(true)
      getClientSecret()
      getOrderData()
    }
  }, [currencyDisplay])

  const getClientSecret = async () => {
    let formdata = new FormData()
    formdata.append("id", id)

    fetch(url.HAPIVITY_STRIPE_INTENT, {
      method: "POST",
      // headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
      body: formdata,
    })
      .then(res => res.json())
      .then(data => {
        console.log(data.data)
        setIsLoading(false)
        setClientSecret(data.data)
      })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [orderData, setOrderData] = useState(null)
  const getOrderData = async () => {
    const response = await fetch(
      url.HAPIVITY_GET_ORDER + "?id=" + id + "&currency=" + currencyDisplay
    )
    const postresponse = await response.json()

    console.log(response.status, postresponse)

    if (response.status == 200) {
      setOrderData(postresponse.data)
    }
  }

  const [paymentLoading, setPaymentLoading] = useState(false)

  function handleValidSubmit(event, values) {
    // console.log(values)
    // setPaymentLoading(true)
    // payServer()
  }

  const payServer = async () => {
    let formdata = new FormData()
    formdata.append("nonce", "fake-valid-nonce")

    const requestOptions = {
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      body: formdata,
    }

    for (var pair of formdata.entries()) {
      console.log(pair[0], pair[1])
    }
    // console.log(formdata.entries())

    const response = await fetch(url.HAPIVITY_POST_CREATE_ORDER, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      setPaymentLoading(false)
      history.replace("/store-order?id=" + postresponse.data)
      // history.push("/shop-order?id=" + postresponse.data)
    }
  }

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#ff7300",
      colorText: "#71727f",
    },
  }
  const options = {
    clientSecret,
    appearance,
  }

  const [user, setUser] = useState(null)
  useEffect(() => {
    if (props.user) setUser(JSON.parse(props.user))
    else setUser(null)
  }, [props.user])

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "0.01",
          },
        },
      ],
    })
  }

  const onApprove = (data, actions) => {
    return actions.order.capture()
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-0" style={{ backgroundColor: "#FBFBFB" }}>
        {!isLoading ? (
          <div className="container-fluid">
            <Row className="mt-4">
              <Col>
                <Alert
                  color="secondary"
                  role="alert"
                  className="border-radius-8 font-size-14 font-weight-semibold bg-F3F4F8 text-center align-middle"
                >
                  <i className="mdi mdi-shield-lock font-size-16 align-middle me-2" />
                  {
                    "We do not store any of your credit card details, all payments are processed by "
                  }
                  <a href="https://stripe.com/" target="_blank">
                    <img
                      src="https://hapivity.imgix.net/stripe.png?w=106"
                      width="106px"
                      height="24px"
                      border="0"
                    />
                  </a>
                </Alert>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={6} className="mb-4 mx-auto">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col>
                        <span className="font-size-16 font-weight-extrabold text-uppercase">
                          {"Order Summary"}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2">
                          <CardBody>
                            <Row>
                              <Col>
                                <Row className="px-2">
                                  <Col>
                                    <input
                                      className="form-control px-0 font-size-14 font-weight-semibold border-none"
                                      type="text"
                                      placeholder="Promo Code"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <button
                                      className="btn btn-primary font-weight-bold font-size-14 border-radius-16 align-middle px-3"
                                      style={{ height: "39px" }}
                                    >
                                      {"Apply"}
                                    </button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card> */}
                        <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2">
                          <CardBody>
                            <Row>
                              <Col>
                                <Row className="mb-2">
                                  <Col>
                                    <span className="font-weight-bold font-size-14 text-secondary">
                                      {"Subtotal"}
                                    </span>
                                  </Col>
                                  <Col xs="auto">
                                    <span className="font-size-14 text-secondary font-weight-bold">
                                      {orderData &&
                                        orderData.currency +
                                          " " +
                                          formatter.format(orderData.subtotal)}
                                    </span>
                                  </Col>
                                </Row>
                                {/* <Row className="mb-2">
                                  <Col>
                                    <span className="font-weight-semibold font-size-13 text-secondary opacity-70">
                                      {"Duties & taxes included"}
                                    </span>
                                  </Col>
                                </Row> */}
                                <Row className="mb-2">
                                  <Col>
                                    <span className="font-weight-bold font-size-14 text-secondary">
                                      {orderData &&
                                        "Shipping to " + orderData.country}
                                    </span>
                                  </Col>
                                  <Col xs="auto">
                                    <span className="font-size-14 text-secondary font-weight-bold">
                                      {orderData &&
                                        orderData.currency +
                                          " " +
                                          formatter.format(
                                            orderData.delivery_price
                                          )}
                                    </span>
                                  </Col>
                                </Row>
                                {/* <Row className="mb-2">
                                  <Col>
                                    <span className="font-weight-semibold font-size-13 text-secondary opacity-70">
                                      {productData &&
                                        productData.details.shipping[
                                          selectedCountry
                                        ].express}
                                      {" ( "}
                                      {productData &&
                                        productData.details.shipping[
                                          selectedCountry
                                        ].duration}
                                      {productData &&
                                        productData.details.shipping[
                                          selectedCountry
                                        ].tracking == "Tracking" &&
                                        ", " +
                                          productData.details.shipping[
                                            selectedCountry
                                          ].tracking}
                                      {" )"}
                                    </span>
                                  </Col>
                                </Row> */}
                                {orderData && orderData.promo != null && (
                                  <Row>
                                    <Col>
                                      <span className="font-weight-bold font-size-14 text-primary">
                                        {"PROMO"}
                                      </span>
                                    </Col>
                                    <Col xs="auto">
                                      <span className="font-size-14 text-primary font-weight-bold">
                                        {orderData &&
                                          "-" +
                                            orderData.currency +
                                            " " +
                                            formatter.format(orderData.promo)}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                                {orderData && orderData.promo != null && (
                                  <Row className="mb-2">
                                    <Col>
                                      <span className="font-weight-semibold font-size-13 text-secondary opacity-70">
                                        {"NEWLAUNCH -20%"}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2 bg-primary mb-0">
                          <CardBody>
                            <Row>
                              <Col>
                                <Row>
                                  <Col>
                                    <span className="font-weight-semibold font-size-16 text-white">
                                      {"Total"}
                                    </span>
                                  </Col>
                                  <Col xs="auto">
                                    <span className="font-size-20 text-white font-weight-extrabold">
                                      {orderData &&
                                        orderData.currency +
                                          " " +
                                          formatter.format(orderData.amount)}
                                    </span>
                                  </Col>
                                </Row>
                                {/* <Row className="mb-3">
                                  <Col></Col>
                                  <Col xs="auto">
                                    <span className="font-weight-bold font-size-14 text-white opacity-70">
                                      <s>{"RP 299,231"}</s>
                                    </span>
                                  </Col>
                                </Row> */}
                                {/* <Row className="px-2">
                                  <button
                                    className="btn bg-white text-primary btn-block font-weight-extrabold font-size-14 border-radius-16 align-middle "
                                    style={{ height: "56px" }}
                                    form="address-form"
                                    type="submit"
                                    onClick={() => {
                                      // pay()
                                      // setPaymentError(null)
                                    }}
                                    disabled={paymentLoading}
                                  >
                                    {paymentLoading ? (
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-1" />
                                    ) : (
                                      <i
                                        className={
                                          "bx bx-credit-card font-size-16 align-middle me-1"
                                        }
                                      />
                                    )}{" "}
                                    {paymentLoading
                                      ? "Placing your order"
                                      : "Place Order"}
                                  </button>
                                </Row> */}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col>
                        <span className="font-size-16 font-weight-extrabold text-uppercase">
                          {"Payment Method"}
                        </span>
                      </Col>
                      <Col xs="auto">
                        <a href="https://stripe.com/" target="_blank">
                          <img
                            src="https://hapivity.imgix.net/stripe.png?w=106"
                            width="106px"
                            height="24px"
                            border="0"
                          />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2">
                          <CardBody>
                            <Row>
                              <Col>
                                <Row className="px-2">
                                  <Col>
                                    <input
                                      className="form-control px-0 font-size-14 font-weight-semibold border-none"
                                      type="text"
                                      placeholder="Promo Code"
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <button
                                      className="btn btn-primary font-weight-bold font-size-14 border-radius-16 align-middle px-3"
                                      style={{ height: "39px" }}
                                    >
                                      {"Apply"}
                                    </button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card> */}
                        <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2">
                          <CardBody>
                            {clientSecret ? (
                              <Elements
                                options={options}
                                stripe={stripePromise}
                              >
                                <CheckoutForm oid={id} />
                              </Elements>
                            ) : (
                              <Row className="justify-content-center">
                                <Col xs="auto">
                                  <Spinner className="ms-2" color="primary" />
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col>
                        <span className="font-size-16 font-weight-extrabold text-uppercase">
                          {"Payment Method"}
                        </span>
                      </Col>
                      <Col xs="auto">
                        <a href="https://stripe.com/" target="_blank">
                          <img
                            src="https://hapivity.imgix.net/stripe.png?w=106"
                            width="106px"
                            height="24px"
                            border="0"
                          />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2">
                          <CardBody>
                            <PayPalButton
                              createOrder={(data, actions) => {
                                createOrder(data, actions)
                              }}
                              onApprove={(data, actions) => {
                                onApprove(data, actions)
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card> */}
              </Col>
            </Row>
          </div>
        ) : (
          <div className="container-fluid">
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Spinner
                  className=""
                  color="primary"
                  style={{ width: "50px", height: "50px" }}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(ShopPayment))

ShopPayment.propTypes = {}
