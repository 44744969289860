import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  Input,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"
import TitleBar from "./TitleBar"
import { select } from "redux-saga/effects"

const Dashboard = props => {
  const history = useHistory()

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     history.push("/dashboard")
  //   }
  // }, [])
  const [admLogin, setadmLogin] = useState(false)

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const [modalAll, setModalAll] = useState(false)
  const [modalCommission, setModalCommission] = useState(false)

  useEffect(() => {
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [props.user])

  useEffect(() => {
    getPageData()
  }, [])

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  const [pageData, setPageData] = useState(null)

  const getPageData = async () => {
    const response = await fetch(url.HAPIVITY_GET_ADMIN_DASHBOARD)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setPageData(postresponse.data)
    }
  }

  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [commissionId, setCommissionId] = useState("")
  const changeHandler = e => {
    console.log("change handler", e.target.files[0])
    console.log("change handler", e.target.id)
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true)
    setCommissionId(e.target.id)

    var reader = new FileReader()
    // reader.onload = function (e) {
    //   document.getElementById("imgPreview").src = e.target.result
    // }
    reader.readAsDataURL(e.target.files[0])
  }

  useEffect(() => {
    if (commissionId != "") {
      sendOnboard(commissionId.split("-")[0], commissionId.split("-")[1])
    }
  }, [commissionId, selectedFile])

  function handleClick(e, handleId) {
    document.getElementById(handleId).click()
  }

  const sendOnboard = async (year, month) => {
    let formdata = new FormData()
    formdata.append("year", year)
    formdata.append("month", month)
    if (isFilePicked) {
      formdata.append("file", selectedFile, selectedFile.name)
    }

    const requestOptions = {
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_UPDATE_COMMISSION, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      getPageData()
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card className="bg-transparent">
              <CardBody>
                <TitleBar />
                {admLogin && pageData && (
                  <>
                    <Row className="mt-3">
                      <Col>
                        <Row className="mb-3">
                          <span className="font-weight-bold font-size-16">
                            Order by challenge
                          </span>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      {pageData.challenge.map((item, index) => (
                        <Col xs="6" md="4">
                          <Card
                            className="p-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModal(!modal)
                              setModalData(item)
                            }}
                          >
                            <Row>
                              <Col>
                                <span className="font-weight-bold font-size-16">
                                  {item.name}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col>
                                <span className="font-size-20 text-primary">
                                  {item.amount}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Col xs="6" md="4">
                        <Card
                          className="p-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalAll(!modalAll)
                          }}
                        >
                          <Row>
                            <Col>
                              <span className="font-weight-bold font-size-16">
                                {"All challenge"}
                              </span>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Row className="mb-3">
                          <span className="font-weight-bold font-size-16">
                            Order by package
                          </span>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      {pageData.challenge_package.map((item, index) => (
                        <Col md="4">
                          <Card className="p-3">
                            <Row className="mb-3">
                              <Col>
                                <span className="font-weight-bold font-size-16">
                                  {item.name}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              {item.count.map((object, index) => (
                                <Col xs="6">
                                  <Card
                                    className="p-3 mb-0"
                                    style={{ borderWidth: 2 }}
                                  >
                                    <Row>
                                      <Col>
                                        <span className="font-weight-bold font-size-16">
                                          {object.package_name.split(":")[0]}
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col>
                                        <span className="font-size-20 text-primary">
                                          {object.amount}
                                        </span>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          </Card>
                        </Col>
                      ))}
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Row className="mt-3">
                          <Col>
                            <Row className="mb-3">
                              <span className="font-weight-bold font-size-16">
                                Order by platform
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className="p-3">
                              {pageData && (
                                <ReactApexChart
                                  options={{
                                    labels: ["Web", "iOS", "Android"],
                                    colors: ["#34c38f", "#556ee6", "#f46a6a"],
                                    legend: {
                                      show: true,
                                      position: "bottom",
                                      horizontalAlign: "center",
                                      verticalAlign: "middle",
                                      floating: false,
                                      fontSize: "14px",
                                      offsetX: 0,
                                      offsetY: -10,
                                    },
                                  }}
                                  series={[
                                    pageData.platform.web,
                                    pageData.platform.ios,
                                    pageData.platform.android,
                                  ]}
                                  type="pie"
                                  height="300"
                                />
                              )}
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Row>
                              <Col xs="6">
                                <Card className="p-3">
                                  <Row>
                                    <Col>
                                      <span className="font-weight-bold font-size-16">
                                        Mobile
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span className="font-size-20 text-primary">
                                        {pageData && pageData.platform.mobile}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                              <Col xs="6">
                                <Card className="p-3">
                                  <Row>
                                    <Col>
                                      <span className="font-weight-bold font-size-16">
                                        Web
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span className="font-size-20 text-primary">
                                        {pageData && pageData.platform.web}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="6" md="3">
                                <Card className="p-3">
                                  <Row>
                                    <Col>
                                      <span className="font-weight-bold font-size-16">
                                        iOS
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span className="font-size-20 text-primary">
                                        {pageData && pageData.platform.ios}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                              <Col xs="6" md="3">
                                <Card className="p-3">
                                  <Row>
                                    <Col>
                                      <span className="font-weight-bold font-size-16">
                                        Android
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span className="font-size-20 text-primary">
                                        {pageData && pageData.platform.android}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="mt-3">
                          <Col>
                            <Row className="mb-3">
                              <span className="font-weight-bold font-size-16">
                                {"Order by age"}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className="p-3">
                              {pageData && (
                                <ReactApexChart
                                  options={{
                                    labels: pageData.age.age_title_raw,
                                    // colors: ["#34c38f", "#556ee6", "#f46a6a"],
                                    legend: {
                                      show: true,
                                      position: "bottom",
                                      horizontalAlign: "center",
                                      verticalAlign: "middle",
                                      floating: false,
                                      fontSize: "14px",
                                      offsetX: 0,
                                      offsetY: -10,
                                    },
                                  }}
                                  series={pageData.age.age_raw}
                                  type="pie"
                                  height="300"
                                />
                              )}
                            </Card>
                          </Col>
                        </Row>
                        {pageData && (
                          <Row>
                            {pageData.age.age.map((item, index) => (
                              <Col xs="6" md="3">
                                <Card className="p-3">
                                  <Row>
                                    <Col>
                                      <span className="font-weight-bold font-size-16">
                                        {item.item}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span className="font-size-20 text-primary">
                                        {item.amount}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                      </Col>
                      <Col md="6">
                        <Row className="mt-3">
                          <Col>
                            <Row className="mb-3">
                              <span className="font-weight-bold font-size-16">
                                {"Order by gender"}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        {pageData && (
                          <Row>
                            {pageData.gender.map((item, index) => (
                              <Col xs="6" md="3">
                                <Card className="p-3">
                                  <Row>
                                    <Col>
                                      <span className="font-weight-bold font-size-16">
                                        {item.item}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span className="font-size-20 text-primary">
                                        {item.amount}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                      </Col>
                      <Col md="6">
                        <Row className="mt-3">
                          <Col>
                            <Row className="mb-3">
                              <span className="font-weight-bold font-size-16">
                                Order by entitlement
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" md="3">
                            <Card className="p-3">
                              <Row>
                                <Col>
                                  <span className="font-weight-bold font-size-16">
                                    Medal
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <span className="font-size-20 text-primary">
                                    {pageData && pageData.entitlement.medal}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col xs="6" md="3">
                            <Card className="p-3">
                              <Row>
                                <Col>
                                  <span className="font-weight-bold font-size-16">
                                    Jersey
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <span className="font-size-20 text-primary">
                                    {pageData && pageData.entitlement.tshirt}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col xs="6" md="3">
                            <Card className="p-3">
                              <Row>
                                <Col>
                                  <span className="font-weight-bold font-size-16">
                                    Both
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <span className="font-size-20 text-primary">
                                    {pageData && pageData.entitlement.both}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row className="mt-3">
                          <Col>
                            <Row className="mb-3">
                              <span className="font-weight-bold font-size-16">
                                {"Order by completion"}
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        {pageData && (
                          <Row>
                            {pageData.completion.map((item, index) => (
                              <Col xs="6" md="3">
                                <Card className="p-3">
                                  <Row>
                                    <Col>
                                      <span className="font-weight-bold font-size-16">
                                        {item.item}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col>
                                      <span className="font-size-20 text-primary">
                                        {item.amount}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )}
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <Row className="mb-3">
                          <span className="font-weight-bold font-size-16">
                            Sales
                          </span>
                        </Row>
                      </Col>
                    </Row>
                    {pageData && (
                      <Row>
                        <Col md="3">
                          <Card className="p-3">
                            <Row>
                              <Col>
                                <span className="font-weight-bold font-size-16">
                                  {"Profit/loss"}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col>
                                <span
                                  className={
                                    "font-size-20 " +
                                    (pageData.sales.profit > 0
                                      ? "text-success"
                                      : "text-danger")
                                  }
                                >
                                  {"MYR " +
                                    formatter.format(pageData.sales.profit)}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col xs="6" md="3">
                          <Card className="p-3">
                            <Row>
                              <Col>
                                <span className="font-weight-bold font-size-16">
                                  {"Total Revenue"}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col>
                                <span className="font-size-20 text-success">
                                  {"MYR " +
                                    formatter.format(pageData.sales.total)}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col xs="6" md="3">
                          <Card className="p-3">
                            <Row>
                              <Col>
                                <span className="font-weight-bold font-size-16">
                                  {"Total Expenses"}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col>
                                <span className="font-size-20 text-primary">
                                  {"MYR " +
                                    formatter.format(pageData.expenses.total)}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    <Row className="mt-3">
                      <Col>
                        <Row className="mb-3">
                          <span className="font-weight-bold font-size-16">
                            Expenses Breakdown
                          </span>
                        </Row>
                      </Col>
                    </Row>
                    <Card className="p-3">
                      <Row className="mb-3">
                        <Col>
                          <span className="font-weight-bold font-size-16">
                            {"Commissions"}
                          </span>
                        </Col>
                      </Row>
                      {pageData && (
                        <Row>
                          {pageData.expenses.stripe.map((item, index) => (
                            <Col xs="6" md="3">
                              <Card
                                className="p-3 mb-0"
                                style={
                                  item.item == "Commission"
                                    ? { borderWidth: 2, cursor: "pointer" }
                                    : { borderWidth: 2 }
                                }
                                onClick={() => {
                                  if (item.item == "Commission") {
                                    setModalCommission(true)
                                  }
                                }}
                              >
                                <Row>
                                  <Col>
                                    <span className="font-weight-bold font-size-16">
                                      {item.item}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <span className="font-size-20 text-primary">
                                      {"MYR " + formatter.format(item.amount)}
                                    </span>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </Card>
                    <Card className="p-3">
                      <Row className="mb-3">
                        <Col>
                          <span className="font-weight-bold font-size-16">
                            {"Entitlements"}
                          </span>
                        </Col>
                      </Row>
                      {pageData && (
                        <Row>
                          {pageData.expenses.entitlement.map((item, index) => (
                            <Col xs="6" md="3">
                              <Card
                                className="p-3 mb-0"
                                style={{ borderWidth: 2 }}
                              >
                                <Row>
                                  <Col>
                                    <span className="font-weight-bold font-size-16">
                                      {item.item}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <span className="font-size-20 text-primary">
                                      {"MYR " + formatter.format(item.amount)}
                                    </span>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </Card>
                    <Card className="p-3">
                      <Row className="mb-3">
                        <Col>
                          <span className="font-weight-bold font-size-16">
                            {"Shipping"}
                          </span>
                        </Col>
                      </Row>
                      {pageData && (
                        <Row>
                          {pageData.expenses.shipping.map((item, index) => (
                            <Col xs="6" md="3">
                              <Card
                                className="p-3 mb-0"
                                style={{ borderWidth: 2 }}
                              >
                                <Row>
                                  <Col>
                                    <span className="font-weight-bold font-size-16">
                                      {item.item}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="mt-2">
                                  <Col>
                                    <span className="font-size-20 text-primary">
                                      {"MYR " +
                                        formatter.format(item.converted)}
                                    </span>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      )}
                    </Card>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          // size="xl"
          // className="modal-fullscreen"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          {modalData && (
            <div>
              <ModalHeader toggle={() => setModal(!modal)}>
                {modalData.name}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col className="p-3">
                    <Row className="">
                      <Col>
                        <Row className="mb-3">
                          <span className="font-weight-bold font-size-16">
                            Order entitlements
                          </span>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      {modalData.entitlement.map((item, index) => (
                        <Col xs="6">
                          <Card
                            className="p-3 mb-0"
                            style={{ borderWidth: "2px" }}
                          >
                            <Row>
                              <Col>
                                <span className="font-weight-bold font-size-16">
                                  {item.name}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col>
                                <span className="font-size-20 text-primary">
                                  {item.amount}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      ))}
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <Row className="mb-3">
                          <span className="font-weight-bold font-size-16">
                            Jersey size breakdown
                          </span>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {modalData.tshirt_count_by_size.map((item, index) => (
                          <Row className="mx-2">
                            <Col style={{ border: "1px solid grey" }}>
                              <span className="font-weight-bold font-size-16">
                                {item.size}
                              </span>
                            </Col>
                            <Col style={{ border: "1px solid grey" }}>
                              <span className="font-size-20 text-primary">
                                {item.amount}
                              </span>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button type="button" onClick={() => setModal(!modal)}>
                  Close
                </Button>
              </ModalFooter>
            </div>
          )}
        </Modal>
        <Modal
          toggle={() => setModalAll(!modalAll)}
          isOpen={modalAll}
          role="dialog"
          // size="xl"
          // className="modal-fullscreen"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          {pageData && (
            <div>
              <ModalHeader toggle={() => setModalAll(!modalAll)}>
                {"All summary"}
              </ModalHeader>
              <ModalBody>
                {pageData &&
                  pageData.challenge.map((object, index) => (
                    <>
                      <Row className="mb-1">
                        <span className="font-weight-bold font-size-14">
                          {object.name}
                        </span>
                      </Row>
                      <Card className="p-3" style={{ borderWidth: "2px" }}>
                        <Row>
                          <Col>
                            <Row className="mt-1">
                              <Col>
                                <Row className="mb-3">
                                  <span className="font-weight-bold font-size-14">
                                    Order entitlements
                                  </span>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              {object.entitlement.map((item, index) => (
                                <Col xs="6">
                                  <Card
                                    className="p-3 mb-0"
                                    style={{ borderWidth: "2px" }}
                                  >
                                    <Row>
                                      <Col>
                                        <span className="font-weight-bold font-size-14">
                                          {item.name}
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row className="mt-2">
                                      <Col>
                                        <span className="font-size-14 text-primary">
                                          {item.amount}
                                        </span>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <Row className="mb-3">
                                  <span className="font-weight-bold font-size-14">
                                    Jersey size breakdown
                                  </span>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {object.tshirt_count_by_size.map(
                                  (item, index) => (
                                    <Row className="mx-2">
                                      <Col
                                        style={{ border: "2px solid #f3f3f3" }}
                                      >
                                        <span className="font-weight-bold font-size-14">
                                          {item.size}
                                        </span>
                                      </Col>
                                      <Col
                                        style={{ border: "2px solid #f3f3f3" }}
                                      >
                                        <span className="font-size-14 text-primary">
                                          {item.amount}
                                        </span>
                                      </Col>
                                    </Row>
                                  )
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  ))}
              </ModalBody>
              <ModalFooter>
                <Button type="button" onClick={() => setModalAll(!modalAll)}>
                  Close
                </Button>
              </ModalFooter>
            </div>
          )}
        </Modal>
        <Modal
          toggle={() => setModalCommission(!modalCommission)}
          isOpen={modalCommission}
          role="dialog"
          // size="l"
          // className="modal-fullscreen"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          {pageData && (
            <div>
              <ModalHeader toggle={() => setModalCommission(!modalCommission)}>
                {"Commissions"}
              </ModalHeader>
              <ModalBody>
                <Row className="mx-2">
                  <Col style={{ border: "2px solid #f3f3f3" }}>
                    <span className="font-weight-bold font-size-14">
                      {"Year/Month"}
                    </span>
                  </Col>
                  <Col style={{ border: "2px solid #f3f3f3" }}>
                    <span className="font-weight-bold font-size-14 text-primary">
                      {"Amount"}
                    </span>
                  </Col>
                  <Col style={{ border: "2px solid #f3f3f3" }}>
                    <span className="font-weight-bold font-size-14 text-secondary">
                      {"Payment"}
                    </span>
                  </Col>
                </Row>
                {pageData.commission.data.map((object, index) => (
                  <Row className="mx-2">
                    <Col style={{ border: "2px solid #f3f3f3" }}>
                      <span className="font-size-14">
                        {object.year + "/" + object.month}
                      </span>
                    </Col>
                    <Col style={{ border: "2px solid #f3f3f3" }}>
                      <span className="font-size-14 text-primary">
                        {"MYR " + formatter.format(object.amount)}
                      </span>
                    </Col>
                    <Col style={{ border: "2px solid #f3f3f3" }}>
                      <button
                        className="btn btn-light btn-block waves-effect waves-light w-100 font-size-14 py-0"
                        style={{
                          borderRadius: "8px",
                          height: "20px",
                          fontWeight: 700,
                        }}
                        onClick={e => {
                          e.preventDefault()
                          if (object.receipt != null) {
                            window.open(object.receipt, "_blank")
                          } else {
                            handleClick(e, object.year + "-" + object.month)
                          }
                        }}
                      >
                        {object.receipt ? "Download" : "Upload"}
                      </button>
                      <Input
                        type="file"
                        className="form-control"
                        id={object.year + "-" + object.month}
                        accept="image/*,application/pdf"
                        // aria-describedby="inputGroupFileAddon04"
                        // aria-label="Upload"
                        onChange={changeHandler}
                        hidden
                      />
                    </Col>
                  </Row>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  onClick={() => setModalCommission(!modalCommission)}
                >
                  Close
                </Button>
              </ModalFooter>
            </div>
          )}
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps)(Dashboard))

Dashboard.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  user: PropTypes.any,
}
