import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Progress,
  CardImg,
  Tooltip,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  CardSubtitle,
  Badge,
  Spinner,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { isIOS, isAndroid } from "react-device-detect"
// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory, useLocation } from "react-router-dom"
import QRCode from "react-qr-code"
import moment from "moment"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import ScrollContainer from "react-indiana-drag-scroll"

const feature = [
  "View order history",
  "Track your shipment",
  "Faster checkout",
  "Latest Challenges or products arrivals",
  "Exclusive offers and promotions",
  // "Find and follow friends and families",
  "Understand more about you workouts in long run",
  // "Win tickets and play lucky draw game",
  "Sync with major fitness platforms such as Strava, Apple Health & Google Fit.",
]

const packageList = ["Challenge Entry", "Finisher Medal", "Finisher Jersey"]

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ShopCheckout = props => {
  const history = useHistory()
  const query = useQuery()
  const id = query.get("id")

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  const [isLoading, setIsLoading] = useState(false)
  const [orderData, setOrderData] = useState(null)
  const getOrderData = async () => {
    const response = await fetch(url.HAPIVITY_GET_ORDER + "?id=" + id)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      setOrderData(postresponse.data)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    getOrderData()
  }, [])

  const [redirect_alert, setredirect_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  var fallbackToStore = function () {
    if (isIOS) {
      // window.open("https://apps.apple.com/us/app/ugift-global/id1603585098")
    }

    if (isAndroid) {
      // window.open(
      //   "https://play.google.com/store/apps/details?id=com.ebuy.ugift"
      // )
    }
  }

  var openApp = function () {
    // window.location.replace("ugift://RedeemGift/?id=" + oid)
    // window.open("ugift://RedeemGift/?id=" + oid)
  }

  var triggerAppOpen = function () {
    openApp()
    setTimeout(fallbackToStore, 700)
  }

  function onRedeemAtAppClicked() {
    triggerAppOpen()
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      {redirect_alert ? (
        <SweetAlert
          // title={dynamic_title}
          onCancel={() => {
            setredirect_alert(false)
          }}
          customButtons={<React.Fragment></React.Fragment>}
        >
          <React.Fragment>
            <Card className="px-3 py-3 shadow-none bg-F3F4F8 border-radius-16">
              <CardTitle className="font-size-20 font-weight-extrabold text-black">
                {dynamic_title}
              </CardTitle>
              <span className="font-size-16 font-weight-semibold text-secondary">
                To view your order or continue to the Challenge, download the
                Hapivity mobile apps.
              </span>
            </Card>
            {/* <Row className="px-3 py-2">
              <button
                className="btn btn-primary waves-effect waves-light font-size-16 border-radius-16 height-56"
                onClick={() => {
                  setredirect_alert(false)
                  onRedeemAtAppClicked()
                }}
              >
                {"Download the App Now"}
              </button>
            </Row> */}
            <Row className="px-3 py-2">
              <button
                className="btn bg-white px-3 height-56"
                style={{
                  border: "2px solid #F3F4F8",
                  borderRadius: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/app/hapivity/id1589719031",
                    "_blank"
                  )
                  return null
                }}
              >
                <img
                  src={asset.appstoreDownload}
                  srcSet={`${asset.appstoreDownload} 300w, ${asset.appstoreDownloadx2} 768w, ${asset.appstoreDownloadx3} 1280w`}
                  alt=""
                  style={{
                    maxHeight: "100%",
                    // maxWidth: "100%",
                    aspectRatio: "95/22",
                  }}
                  className="me-1"
                />
              </button>
            </Row>
            <Row className="px-3 py-2">
              <button
                className="btn bg-white px-3 height-56"
                style={{
                  border: "2px solid #F3F4F8",
                  borderRadius: "16px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.hapivity",
                    "_blank"
                  )
                  return null
                }}
              >
                <img
                  src={asset.googleDownload}
                  srcSet={`${asset.googleDownload} 300w, ${asset.googleDownloadx2} 768w, ${asset.googleDownloadx3} 1280w`}
                  alt=""
                  style={{
                    maxHeight: "100%",
                    // maxWidth: "100%",
                    aspectRatio: "95/22",
                  }}
                  className="me-1"
                />
              </button>
            </Row>
          </React.Fragment>
        </SweetAlert>
      ) : null}
      <div className="page-content px-0" style={{ backgroundColor: "#FBFBFB" }}>
        {!isLoading ? (
          <div className="container-fluid">
            <Row className="justify-content-center">
              {orderData && (
                <Col lg={6} className="mb-4">
                  <Row className="mb-1">
                    <Col>
                      <span className="font-size-36 font-weight-black text-primary">
                        {"Thank you for signing up!"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <span className="font-size-14 font-weight-semibold text-secondary">
                        {"A confirmation email has been sent to the email "}
                      </span>
                      <span className="font-size-14 font-weight-extrabold text-secondary">
                        {orderData.email}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-16 font-weight-extrabold text-uppercase text-secondary">
                        {"Order detail"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="border-radius-16 shadow-none">
                        <CardBody>
                          <Row>
                            <Col>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Order ID"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70 me-2 text-uppercase">
                                    {orderData.id}
                                  </span>
                                  <i
                                    className={
                                      "bx bx-copy font-size-18 align-middle me-1 text-black"
                                    }
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Order Date"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {moment(orderData.created_at).format(
                                      "Do MMMM YYYY, h:mm:ss a"
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="border-radius-16 shadow-none">
                        <CardBody>
                          <Row>
                            <Col xs="auto">
                              <CardImg
                                src={
                                  orderData.shop.image +
                                  "?w=80&h=80&fit=clip&dpr=1&q=75&auto=format"
                                }
                                srcSet={`${
                                  orderData.shop.image +
                                  "?w=80&h=80&fit=clip&dpr=1&q=75&auto=format"
                                } 1x, ${
                                  orderData.shop.image +
                                  "?w=80&h=80&fit=clip&dpr=2&q=37&auto=format"
                                } 2x, ${
                                  orderData.shop.image +
                                  "?w=80&h=80&fit=clip&dpr=3&q=25&auto=format"
                                } 3x`}
                                alt="hero"
                                style={{
                                  width: "80px",
                                  objectFit: "cover",
                                  aspectRatio: "1/1",
                                }}
                                className="border-radius-16"
                              />
                            </Col>
                            <Col>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-extrabold font-size-18 text-secondary">
                                    {orderData.shop.name}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-16 text-secondary">
                                    {orderData.package.name}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <ul className="font-size-16 font-weight-semibold text-secondary">
                                    {orderData.package.entitlement.map(item => (
                                      <li>
                                        {item.name}{" "}
                                        {item.type == "tshirt" &&
                                          orderData.color +
                                            " " +
                                            orderData.size}
                                      </li>
                                    ))}
                                  </ul>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-16 font-weight-extrabold text-uppercase text-secondary">
                        {"Summary"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="border-radius-16 shadow-none">
                        <CardBody>
                          <Row>
                            <Col>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Subtotal"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.currency +
                                      " " +
                                      formatter.format(orderData.subtotal)}
                                  </span>
                                </Col>
                              </Row>
                              {/* <Row className="mb-2">
                              <Col>
                                <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                  {"Tax"}
                                </span>
                              </Col>
                              <Col xs="auto">
                                <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                  {"RP 299,231"}
                                </span>
                              </Col>
                            </Row> */}
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Delivery to: " +
                                      orderData.postcode +
                                      "," +
                                      orderData.country}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.currency +
                                      " " +
                                      formatter.format(
                                        orderData.delivery_price
                                      )}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {
                                      "PROMO: "
                                      // +
                                      // (orderData.promo_desc &&
                                      //   orderData.promo_desc)
                                    }
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"-" +
                                      orderData.currency +
                                      " " +
                                      formatter.format(orderData.promo)}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <span className="font-weight-bold font-size-14 text-secondary">
                                    {"TOTAL"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary">
                                    {orderData.currency +
                                      " " +
                                      formatter.format(orderData.amount)}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-16 font-weight-extrabold text-uppercase text-secondary">
                        {"Payment detail"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="border-radius-16 shadow-none">
                        <CardBody>
                          <Row>
                            <Col>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Payment type"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.card_type +
                                      " " +
                                      orderData.card_last4}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Payment status"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.payment_status}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-16 font-weight-extrabold text-uppercase text-secondary">
                        {"Delivery Details"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="border-radius-16 shadow-none">
                        <CardBody>
                          <Row>
                            <Col>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary">
                                    {"Delivery status"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary">
                                    {"Pending Challenge Completion"}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Recipent Name"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.first_name +
                                      " " +
                                      orderData.last_name}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Contact no."}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.contact}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Delivery option"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Express (3-5 business days, tracking)"}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {"Address"}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.address1}
                                  </span>
                                </Col>
                              </Row>
                              {orderData.address2 && (
                                <Row className="mb-2">
                                  <Col>
                                    <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                      {""}
                                    </span>
                                  </Col>
                                  <Col xs="auto">
                                    <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                      {orderData.address2}
                                    </span>
                                  </Col>
                                </Row>
                              )}
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {""}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.town}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {""}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.state}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {""}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.postcode}
                                  </span>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col>
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {""}
                                  </span>
                                </Col>
                                <Col xs="auto">
                                  <span className="font-weight-semibold font-size-14 text-secondary opacity-70">
                                    {orderData.country}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-14 font-weight-semibold text-secondary">
                        {
                          "You can start your Challenge right away without checking your email. To begin, download the "
                        }
                      </span>
                      <Link
                        to="#"
                        className="font-size-14 font-weight-semibold"
                      >
                        {"Hapivity"}
                      </Link>
                      <span className="font-size-14 font-weight-semibold text-secondary">
                        {" mobile apps & sign-in using the same credentials. "}
                      </span>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm="4" className="p-1 px-lg-2">
                      <Card className="shadow-none p-3 border-radius-16">
                        <QRCode
                          size={120}
                          value="https://apps.apple.com/app/hapivity/id1589719031"
                          className="mx-auto my-3"
                        />
                        <button
                          className="btn bg-white px-1 px-lg-2 px-xl-3"
                          style={{
                            border: "2px solid #F3F4F8",
                            borderRadius: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              "https://apps.apple.com/app/hapivity/id1589719031",
                              "_blank"
                            )
                            return null
                          }}
                        >
                          <img
                            src={asset.appstoreDownload}
                            srcSet={`${asset.appstoreDownload} 300w, ${asset.appstoreDownloadx2} 768w, ${asset.appstoreDownloadx3} 1280w`}
                            alt=""
                            style={{
                              // height: "100%",
                              maxWidth: "100%",
                              aspectRatio: "43/10",
                            }}
                            className="me-1"
                          />
                        </button>
                      </Card>
                    </Col>
                    <Col sm="4" className="p-1 px-lg-2">
                      <Card className="shadow-none p-3 border-radius-16">
                        <QRCode
                          size={120}
                          value="https://play.google.com/store/apps/details?id=com.hapivity"
                          className="mx-auto my-3"
                        />
                        <button
                          className="btn bg-white px-1 px-lg-2 px-xl-3"
                          style={{
                            border: "2px solid #F3F4F8",
                            borderRadius: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              "https://play.google.com/store/apps/details?id=com.hapivity",
                              "_blank"
                            )
                            return null
                          }}
                        >
                          <img
                            src={asset.googleDownload}
                            srcSet={`${asset.googleDownload} 300w, ${asset.googleDownloadx2} 768w, ${asset.googleDownloadx3} 1280w`}
                            alt=""
                            style={{
                              // height: "100%",
                              maxWidth: "100%",
                              aspectRatio: "43/10",
                            }}
                            className="me-1"
                          />
                        </button>
                      </Card>
                    </Col>
                    {/* <Col sm="4" className="p-1 px-lg-2">
                      <Card className="shadow-none p-3 border-radius-16">
                        <QRCode
                          size={120}
                          value="hey"
                          className="mx-auto my-3"
                        />
                        <button
                          className="btn bg-white px-1 px-lg-2 px-xl-3"
                          style={{
                            border: "2px solid #F3F4F8",
                            borderRadius: "16px",
                          }}
                        >
                          <img
                            src={asset.huaweiDownload}
                            srcSet={`${asset.huaweiDownload} 300w, ${asset.huaweiDownloadx2} 768w, ${asset.huaweiDownloadx3} 1280w`}
                            alt=""
                            style={{
                              // height: "100%",
                              maxWidth: "100%",
                              aspectRatio: "43/10",
                            }}
                            className="me-1"
                          />
                        </button>
                      </Card>
                    </Col> */}
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-14 font-weight-semibold text-secondary text-uppercase">
                        {"Some of the benefits of the mobile apps:"}
                      </span>
                    </Col>
                  </Row>
                  {feature.map(object => (
                    <Row className="mb-2">
                      <Col>
                        <i
                          className={
                            "bx bx-comment-error font-size-18 align-middle me-2 text-primary"
                          }
                        />
                        <span className="font-size-14 font-weight-semibold text-secondary align-middle">
                          {object}
                        </span>
                      </Col>
                    </Row>
                  ))}
                  <Row className="mt-3 mb-4">
                    <Col>
                      <span className="font-size-14 font-weight-semibold text-secondary align-middle">
                        {
                          "We hope you enjoy the premium experience we carefully crafted, thank you for supporting Hapivity."
                        }
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <span className="font-size-14 font-weight-semibold text-secondary align-middle">
                        {"Get moving and get fit!"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col>
                      <span className="font-size-14 font-weight-semibold text-secondary align-middle">
                        {"Hapivity Team,"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Link
                        to={"/home"}
                        className="font-size-14 font-weight-semibold align-middle"
                      >
                        {"hapivity.com"}
                      </Link>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <Row className="no-gutters px-3">
                        <button
                          className="btn btn-outline-primary font-weight-extrabold font-size-16 border-radius-16 btn-block bg-white text-primary"
                          style={{ height: "56px" }}
                          onClick={() => {
                            // history.push("/home")
                            setredirect_alert(true)
                            setdynamic_title("Order status")
                          }}
                        >
                          {"View Order"}
                        </button>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="no-gutters px-3">
                        <button
                          className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                          style={{ height: "56px" }}
                          onClick={() => {
                            history.push("/store")
                          }}
                        >
                          {"Continue Shopping"}
                        </button>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-16 font-weight-extrabold text-uppercase text-secondary">
                        {"Need help?"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="border-radius-16 shadow-none">
                        <CardBody>
                          <Row>
                            <Col>
                              <span className="font-weight-semibold font-size-14 text-secondary">
                                <i
                                  className={
                                    "bx bx-chat font-size-18 align-middle me-2"
                                  }
                                />
                                {"Contact us"}
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <Row>
                    <Col className="align-items-center d-flex">
                      <span
                        className="font-size-16 font-weight-extrabold text-uppercase align-middle"
                        style={{ color: "#8F9BB3" }}
                      >
                        {"Follow us on"}
                      </span>
                    </Col>
                    <Col xs={"auto"} className="pe-0">
                      <img
                        className="font-size-16 font-weight-extrabold text-uppercase"
                        src={asset.iconInstagram}
                        srcSet={`${asset.iconInstagram} 300w, ${asset.iconInstagramx2} 768w, ${asset.iconInstagramx3} 1280w`}
                        alt=""
                        style={{
                          width: "48px",
                          height: "48px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(
                            "https://instagram.com/hapivity",
                            "_blank"
                          )
                          return null
                        }}
                      />
                    </Col>
                    <Col xs={"auto"}>
                      <img
                        className="font-size-16 font-weight-extrabold text-uppercase"
                        src={asset.iconFacebook}
                        srcSet={`${asset.iconFacebook} 300w, ${asset.iconFacebookx2} 768w, ${asset.iconFacebookx3} 1280w`}
                        alt=""
                        style={{
                          width: "48px",
                          height: "48px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open("https://facebook.com/hapivity", "_blank")
                          return null
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        ) : (
          <div className="container-fluid">
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Spinner
                  className=""
                  color="primary"
                  style={{ width: "50px", height: "50px" }}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  // const { error } = state.Home
  return {}
}

export default withRouter(connect(mapStateToProps, {})(ShopCheckout))

ShopCheckout.propTypes = { user: PropTypes.object }
