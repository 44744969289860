import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  Tooltip,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  CardSubtitle,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Button,
  Spinner,
} from "reactstrap"

import { showJoinNowAction } from "store/actions"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory, useLocation } from "react-router-dom"
import Imgix from "react-imgix"
import { Blurhash } from "react-blurhash"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ShopDetails = props => {
  const history = useHistory()
  const query = useQuery()
  const id = query.get("id")

  const [currencyDisplay, setCurrency] = useState(null)
  const [country, setCountry] = useState(null)

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   history.push("/dashboard")
    // }

    // setCurrency("MYR")
    // switch (geoplugin_currencyCode()) {
    //   case "MYR":
    //     setCurrency("MYR")
    //     break
    //   case "SGD":
    //     setCurrency("SGD")
    //     break
    //   case "AUD":
    //     setCurrency("AUD")
    //     break
    //   case "IDR":
    //     setCurrency("IDR")
    //     break
    //   default:
    //     setCurrency("USD")
    // }

    if (localStorage.getItem("hCurrency")) {
      setCurrency(localStorage.getItem("hCurrency"))
    } else {
      getip()
    }

    if (localStorage.getItem("hCountry")) {
      setCountry(localStorage.getItem("hCountry"))
    } else {
      getip()
    }
  }, [])

  const getip = async () => {
    // const response = await fetch("https://ipinfo.io/?token=08d58819dae512")
    const response = await fetch("https://ipapi.co/json")
    const postresponse = await response.json()

    if (response.status == 200) {
      console.log(postresponse.country)
      updateCurrency(postresponse.country)
      localStorage.setItem("hCountry", postresponse.country)
      setCountry(postresponse.country)
    }
  }

  const updateCurrency = country => {
    switch (country) {
      case "MY":
      case "my":
        setCurrency("MYR")
        localStorage.setItem("hCurrency", "MYR")
        break
      case "SG":
      case "sg":
        setCurrency("SGD")
        localStorage.setItem("hCurrency", "SGD")
        break
      case "AU":
      case "au":
        setCurrency("AUD")
        localStorage.setItem("hCurrency", "AUD")
        break
      case "ID":
      case "id":
        setCurrency("IDR")
        localStorage.setItem("hCurrency", "IDR")
        break
      case "GB":
      case "gb":
        setCurrency("GBP")
        localStorage.setItem("hCurrency", "GBP")
        break
      default:
        setCurrency("USD")
        localStorage.setItem("hCurrency", "USD")
    }
  }

  const [t_howitworks, sett_howitworks] = useState(false)
  const [t_whatdoiget, sett_whatdoiget] = useState(false)
  const [t_category, sett_category] = useState(false)
  const [t_type, sett_type] = useState(false)
  const [t_country, sett_country] = useState(false)

  const [t_faq, sett_faq] = useState({})
  const [t_countrydropdown, sett_countrydropdown] = useState(false)

  const [selectedImage, setselected_image] = useState(0)
  const [selectedImageLoading, setselected_image_loading] = useState(null)
  const [selectedColor, setselected_color] = useState(0)
  const [selectedCountry, setselected_country] = useState(0)
  const [selectedPackage, setselected_package] = useState(0)
  const [selectedsize, setselected_size] = useState(0)

  const [isGallery, setisGallery] = useState(false)
  const [isFits, setisFits] = useState(false)

  const [tbottom, settbottom] = useState(false)

  useEffect(() => {
    if (currencyDisplay) {
      setIsLoading(true)
      getProductData()
    }
  }, [currencyDisplay])

  const [isLoading, setIsLoading] = useState(false)
  const [productData, setProductData] = useState(null)
  const [isThumbnailLoaded, setThumbnailLoaded] = useState({})
  const [isImageLoaded, setImageLoaded] = useState({})
  const getProductData = async () => {
    const response = await fetch(
      url.HAPIVITY_GET_SHOP_PRODUCT_DETAILS +
        "?id=" +
        query.get("id") +
        "&currency=" +
        currencyDisplay +
        "&uid=" +
        (user && user.id)
    )
    const postresponse = await response.json()

    if (response.status == 200) {
      setProductData(postresponse.data)
      postresponse.data.details.faq.forEach(object => {
        sett_faq({ ...t_faq, [object.id]: false })
      })
      postresponse.data.debug.images.forEach(object => {
        setThumbnailLoaded({ ...isThumbnailLoaded, [object.id]: false })
        setImageLoaded({ ...isImageLoaded, [object.id]: false })
      })
      setIsLoading(false)

      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag("js", new Date())

      gtag("event", "page_view", { page_title: postresponse.data.name })
    }
  }

  const [user, setUser] = useState(null)
  useEffect(() => {
    if (props.user) {
      setUser(JSON.parse(props.user))
      updateCurrency(JSON.parse(props.user).nationality)
      localStorage.setItem("hCountry", JSON.parse(props.user).nationality)
      setCountry(JSON.parse(props.user).nationality)
    } else setUser(null)
  }, [props.user])

  useEffect(() => {
    if (country && productData) {
      // setselected_country(
      //   productData.details.shipping.findIndex(
      //     object => object.code.toLowerCase() == country.toLowerCase()
      //   )
      // )
    }
  }, [country, productData])

  const activeClass =
    "btn btn-rounded btn-outline-primary py-1 px-3 font-size-14 font-weight-bold me-3 mb-3"
  const inactiveClass =
    "btn btn-rounded btn-outline-light button-outline-c4c4c4 py-1 px-3 font-size-14 text-secondary font-weight-bold me-3 mb-3"

  return (
    <React.Fragment>
      <MetaTags>
        <title>{productData && productData.name}</title>
        <meta
          name="description"
          content={productData && productData.description}
        />
        <meta property="og:title" content={productData && productData.name} />
        <meta property="og:image" content={productData && productData.image} />
      </MetaTags>
      {isFits ? (
        <Lightbox
          mainSrc={productData.details.size_chart}
          enableZoom={true}
          onCloseRequest={() => {
            setisFits(!isFits)
          }}
        />
      ) : null}
      {isGallery ? (
        <Lightbox
          // mainSrc={
          //   "https://sandbox-uploads.imgix.net/u/1648718656-c7be32fcf6f75e24a6b145eba8db8779?w=730&auto=format&dpr=1&q=75"
          // }
          mainSrc={
            productData.debug.images[selectedImage].image +
            "?w=920&auto=format&dpr=2&q=37"
          }
          nextSrc={
            productData.debug.images[
              (selectedImage + 1) % productData.debug.images.length
            ].image + "?w=920&auto=format&dpr=2&q=37"
          }
          prevSrc={
            productData.debug.images[
              (selectedImage + productData.debug.images.length - 1) %
                productData.debug.images.length
            ].image + "?w=920&auto=format&dpr=2&q=37"
          }
          enableZoom={true}
          onCloseRequest={() => {
            setisGallery(false)
          }}
          onMovePrevRequest={() => {
            setselected_image(
              (selectedImage + productData.debug.images.length - 1) %
                productData.debug.images.length
            )
          }}
          onMoveNextRequest={() => {
            setselected_image(
              (selectedImage + 1) % productData.debug.images.length
            )
          }}
          imageCaption={
            selectedImage + 1 + "/" + productData.debug.images.length + " "
            // productData.images[selectedImage].name
          }
        />
      ) : null}
      <div className="page-content px-0" style={{ backgroundColor: "#FBFBFB" }}>
        {!isLoading ? (
          <div className="container-fluid">
            <Row className="mt-4">
              <Col lg={7} className="mb-4">
                {productData && productData.debug.images.length > 0 && (
                  <Row>
                    <Card className="bg-transparent shadow-none mb-2">
                      {!isImageLoaded[selectedImageLoading] && (
                        <Blurhash
                          hash={productData.debug.images[selectedImage].hash}
                          // width={"100%"}
                          // height={"100%"}
                          resolutionX={32}
                          resolutionY={32}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            aspectRatio: "1/1",
                            cursor: "pointer",
                            borderRadius: "16px",
                          }}
                        />
                      )}
                      <CardImg
                        className="img-fluid"
                        src={
                          productData.debug.images[selectedImage].image +
                          "?w=920&h=920&fit=clip&dpr=1&q=75&auto=format"
                        }
                        srcSet={`${
                          productData.debug.images[selectedImage].image +
                          "?w=920&h=920&fit=clip&dpr=1&q=75&auto=format"
                        } 1x, ${
                          productData.debug.images[selectedImage].image +
                          "?w=920&h=920&fit=clip&dpr=2&q=37&auto=format"
                        } 2x, ${
                          productData.debug.images[selectedImage].image +
                          "?w=920&h=920&fit=clip&dpr=3&q=25&auto=format"
                        } 3x`}
                        // src={
                        //   "https://sandbox-uploads.imgix.net/u/1648718656-c7be32fcf6f75e24a6b145eba8db8779?w=730&auto=format&dpr=1&q=75"
                        // }
                        // srcSet={`https://sandbox-uploads.imgix.net/u/1648718656-c7be32fcf6f75e24a6b145eba8db8779?w=730&auto=format&dpr=1&q=75, https://sandbox-uploads.imgix.net/u/1648718656-c7be32fcf6f75e24a6b145eba8db8779?w=730&auto=format&dpr=2&q=37 2x, https://sandbox-uploads.imgix.net/u/1648718656-c7be32fcf6f75e24a6b145eba8db8779?w=730&auto=format&dpr=3&q=25 3x`}
                        alt="hero"
                        style={{
                          // height: "295px",
                          maxWidth: "100%",
                          objectFit: "cover",
                          aspectRatio: "1/1",
                          cursor: "pointer",
                          borderRadius: "16px",
                          display: isImageLoaded[selectedImageLoading]
                            ? "block"
                            : "none",
                        }}
                        onClick={() => {
                          setisGallery(true)
                          // tog_lightbox()
                        }}
                        onLoad={() => {
                          setImageLoaded({
                            ...isImageLoaded,
                            [selectedImageLoading]: true,
                          })
                        }}
                      />
                    </Card>
                  </Row>
                )}
                <Row>
                  <ScrollContainer className="scroll-container">
                    <Row className="flex-nowrap">
                      {productData &&
                        productData.debug.images.map((object, index) => (
                          <Col xs="auto" key={index}>
                            {!isThumbnailLoaded[object.id] && (
                              <Blurhash
                                hash={object.hash}
                                // width={"100%"}
                                // height={"100%"}
                                resolutionX={32}
                                resolutionY={32}
                                style={{
                                  height: "80px",
                                  width: "80px",
                                  objectFit: "cover",
                                  aspectRatio: "1/1",
                                  cursor: "pointer",
                                  borderRadius: "8px",
                                }}
                              />
                            )}
                            <img
                              // className="p-0"
                              src={
                                object.image +
                                "?w=80&h=80&fit=clip&dpr=1&q=75&auto=format"
                              }
                              srcSet={`${
                                object.image +
                                "?w=80&h=80&fit=clip&dpr=1&q=75&auto=format"
                              } 1x, ${
                                object.image +
                                "?w=80&h=80&fit=clip&dpr=2&q=37&auto=format"
                              } 2x, ${
                                object.image +
                                "?w=80&h=80&fit=clip&dpr=3&q=25&auto=format"
                              } 3x`}
                              alt="hero"
                              style={{
                                height: "80px",
                                width: "80px",
                                objectFit: "cover",
                                aspectRatio: "1/1",
                                cursor: "pointer",
                                borderRadius: "8px",
                                display: isThumbnailLoaded[object.id]
                                  ? "block"
                                  : "none",
                              }}
                              className={
                                selectedImage == index &&
                                "image-outline-primary"
                              }
                              onClick={() => {
                                setselected_image(index)
                                setselected_image_loading(object.id)
                              }}
                              onLoad={() => {
                                setThumbnailLoaded({
                                  ...isThumbnailLoaded,
                                  [object.id]: true,
                                })
                              }}
                            />
                          </Col>
                        ))}
                    </Row>
                  </ScrollContainer>
                </Row>
              </Col>
              <Col lg={5}>
                <Row className="mb-1">
                  <Col>
                    <span className="font-size-24 font-weight-black">
                      {productData && productData.name}
                    </span>
                  </Col>
                </Row>
                {productData && (
                  <Row className="mb-4">
                    <Col xs="auto">
                      {productData.discount != null ? (
                        <span className="font-size-16 text-secondary font-weight-semibold opacity-60 align-middle me-3">
                          <s>
                            {productData &&
                              productData.currency +
                                " " +
                                formatter.format(
                                  productData.price_ori +
                                    productData.debug.package[selectedPackage]
                                      .price_ori
                                )}
                          </s>
                        </span>
                      ) : (
                        <span className="font-size-20 text-primary font-weight-extrabold align-middle me-3">
                          {productData.currency +
                            " " +
                            formatter.format(
                              productData.price_ori +
                                productData.debug.package[selectedPackage]
                                  .price_ori
                            )}
                        </span>
                      )}
                      {productData.discount && (
                        <span className="font-size-20 text-primary font-weight-extrabold align-middle me-3">
                          {productData &&
                            productData.currency +
                              " " +
                              formatter.format(
                                productData.price_discount +
                                  productData.debug.package[selectedPackage]
                                    .price_discount
                              )}
                        </span>
                      )}
                      <Badge className="bg-primary font-size-14 font-weight-extrabold p-2 border-radius-12">
                        {productData && productData.discount}
                      </Badge>
                    </Col>
                  </Row>
                )}

                {/* <Row className="mb-3">
                <Col>
                  <img
                    src={asset.iconShippingTime}
                    srcSet={`${asset.iconShippingTime} 300w, ${asset.iconShippingTimex2} 768w, ${asset.iconShippingTimex3} 1280w`}
                    style={{ width: "16px", height: "16px" }}
                    alt="malaysia"
                    className="me-2"
                  />
                  <span className="font-size-14 font-weight-semibold text-secondary  align-middle">
                    {"Delivery after Challenge Completion "}
                  </span>
                  <span className="font-size-14 font-weight-semibold text-primary align-middle">
                    {"5 business day(s), tracking"}
                  </span>
                  <button
                    type="button"
                    className="btn text-primary btn-link p-0"
                    id="TooltipBottom"
                  >
                    <i
                      className={
                        "bx bx-help-circle font-size-16 align-middle ms-1"
                      }
                    />
                  </button>
                  <Tooltip
                    placement="bottom"
                    isOpen={tbottom}
                    target="TooltipBottom"
                    toggle={() => {
                      settbottom(!tbottom)
                    }}
                  >
                    {"Description in here"}
                  </Tooltip>
                </Col>
              </Row> */}
                {/* <Row className="mb-5">
                <Col>
                  <img
                    src={asset.iconWallet}
                    srcSet={`${asset.iconWallet} 300w, ${asset.iconWalletx2} 768w, ${asset.iconWalletx3} 1280w`}
                    style={{ width: "16px", height: "16px" }}
                    alt="malaysia"
                    className="me-2"
                  />
                  <span className="font-size-14 font-weight-semibold text-secondary align-middle">
                    {"Pay with: Credit Card / Paypal"}
                  </span>
                </Col>
              </Row> */}
                <Row className="mb-1">
                  <Col>
                    <span className="font-size-14 font-weight-extrabold text-secondary align-middle text-uppercase">
                      {"Select Package:"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <select
                      className="form-select border-radius-16 border-width-2 button-outline-f0f0f0 text-secondary font-weight-bold font-size-14 p-3"
                      style={{ height: "56px" }}
                      onChange={e => {
                        console.log(e.target.value)
                        setselected_package(e.target.value)
                      }}
                    >
                      {/* <option value="" disabled selected>
                        Select Package
                      </option> */}
                      {productData &&
                        // productData.details.package.map((object, index) => (
                        productData.debug.package.map((object, index) => (
                          <option
                            selected={selectedPackage == index}
                            value={index}
                            key={index}
                            disabled={object.published == 0}
                          >
                            {
                              object.name
                              // (object.price_ori != 0
                              //   ? " (+" +
                              //     productData.currency +
                              //     (object.price_discount
                              //       ? formatter.format(object.price_discount)
                              //       : formatter.format(object.price_ori)) +
                              //     ")"
                              //   : "")
                            }{" "}
                            {object.published == 0 && "(Sold out)"}
                          </option>
                        ))}
                    </select>
                  </Col>
                </Row>
                {productData &&
                  // productData.details.package[selectedPackage].tshirt && (
                  productData.debug.package[selectedPackage]?.tshirt && (
                    <Row className="mb-1">
                      <Col>
                        <span className="font-size-14 font-weight-extrabold text-secondary align-middle text-uppercase">
                          {"Select Jersey Size:"}
                        </span>
                      </Col>
                      <Col xs="auto">
                        <span
                          className="btn btn-link font-size-14 font-weight-semibold text-primary align-middle p-0 me-1"
                          onClick={() => {
                            setisFits(true)
                          }}
                        >
                          <i
                            className={
                              "bx bx-ruler font-size-18 align-middle me-1"
                            }
                          />{" "}
                          {"Size Chart"}
                        </span>
                      </Col>
                    </Row>
                  )}
                {productData &&
                  // productData.details.package[selectedPackage].tshirt && (
                  productData.debug.package[selectedPackage]?.tshirt && (
                    <Row className="mb-3">
                      <Col>
                        <select
                          className="form-select border-radius-16 border-width-2 button-outline-f0f0f0 text-secondary font-weight-bold font-size-14 p-3"
                          style={{ height: "56px" }}
                          onChange={e => {
                            console.log(e.target.value)
                            setselected_size(e.target.value)
                          }}
                        >
                          {/* <option value="" disabled selected>
                            Select T-shirt Size
                          </option> */}
                          {productData &&
                            productData.details.tshirt_size.map(
                              (object, index) => (
                                <option
                                  selected={selectedsize == index}
                                  value={index}
                                  key={index}
                                >
                                  {object}
                                </option>
                              )
                            )}
                        </select>
                      </Col>
                    </Row>
                  )}
                {productData &&
                  // productData.details.package[selectedPackage].tshirt && (
                  productData.debug.package[selectedPackage]?.tshirt && (
                    <Row className="mb-1">
                      <Col>
                        <span className="font-size-14 font-weight-extrabold text-secondary align-middle text-uppercase me-2">
                          {"Select Jersey Color:"}
                        </span>
                        {selectedColor != null && (
                          <span className="font-size-14 font-weight-semibold text-secondary align-middle text-uppercase">
                            {productData &&
                              productData.details.tshirt_color[selectedColor]
                                .name}
                          </span>
                        )}
                      </Col>
                    </Row>
                  )}
                {productData &&
                  // productData.details.package[selectedPackage].tshirt && (
                  productData.debug.package[selectedPackage]?.tshirt && (
                    <Row className="mb-3 px-2">
                      {productData &&
                        productData.details.tshirt_color.map(
                          (object, index) => (
                            <Col xs="auto" className="px-1" key={index}>
                              <button
                                className="btn p-1"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                  background: "white",
                                  // boxSizing: "content-box",
                                  border:
                                    selectedColor != null &&
                                    selectedColor == index
                                      ? "1px solid #8F9BB3"
                                      : "1px solid white",
                                }}
                                onClick={() => setselected_color(index)}
                              >
                                <div
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    background: object.color,
                                  }}
                                ></div>
                              </button>
                            </Col>
                          )
                        )}
                    </Row>
                  )}
                {/* <Row className="mb-3">
                <Col>
                  {challengePackage.map(object => (
                    <button
                      className={object.active ? activeClass : inactiveClass}
                      style={{ height: "40px" }}
                      onClick={() => {}}
                    >
                      {object.active && (
                        <i
                          className={
                            "bx bxs-check-circle font-size-16 align-middle me-1"
                          }
                        />
                      )}
                      {object.name}
                    </button>
                  ))}
                </Col>
              </Row> */}
                <Row className="mb-2">
                  <Col>
                    <Row className="no-gutters px-3">
                      <button
                        className={
                          "btn btn-block btn-primary text-uppercase font-size-14 font-weight-extrabold border-radius-16"
                        }
                        style={{ height: "56px" }}
                        onClick={() => {
                          if (!user) {
                            props.showJoinNowAction(true)
                          } else
                            history.push(
                              "/store-checkout?id=" +
                                id +
                                "&package=" +
                                selectedPackage +
                                "&size=" +
                                selectedsize +
                                "&color=" +
                                selectedColor +
                                "&country=" +
                                selectedCountry
                            )
                        }}
                        disabled={productData && productData.joined}
                        // disabled={!user}
                      >
                        {user
                          ? productData && productData.joined
                            ? "Joined"
                            : "Join Now"
                          : "Join Now"}
                      </button>
                    </Row>
                  </Col>
                </Row>
                {/* <Row className="mb-1">
                  <Col>
                    <img
                      src={asset.iconMalaysia}
                      srcSet={`${asset.iconMalaysia} 300w, ${asset.iconMalaysiax2} 768w, ${asset.iconMalaysiax3} 1280w`}
                      style={{ width: "16px", height: "16px" }}
                      alt="malaysia"
                      className="me-2"
                    />
                    <span className="font-size-14 font-weight-semibold text-secondary  align-middle">
                      {"Ships to "}
                    </span>
                    <ButtonDropdown
                      isOpen={t_countrydropdown}
                      toggle={() => sett_countrydropdown(!t_countrydropdown)}
                    >
                      <DropdownToggle
                        tag="button"
                        className="btn btn-link font-size-14 font-weight-extrabold text-secondary align-middle p-0 me-1"
                      >
                        {productData &&
                          productData.details.shipping[selectedCountry] &&
                          productData.details.shipping[selectedCountry].country}
                        <i
                          className={
                            "bx bx-chevron-down font-size-16 align-middle"
                          }
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        {productData &&
                          productData.details.shipping.map((object, index) => (
                            <DropdownItem
                              key={index}
                              onClick={() => {
                                setselected_country(index)
                              }}
                            >
                              {object.country}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                    <span className="font-size-14 font-weight-semibold text-primary align-middle me-2">
                      {productData &&
                        productData.details.shipping[selectedCountry] &&
                        productData.currency +
                          " " +
                          formatter.format(
                            productData.details.shipping[selectedCountry].price
                          )}
                    </span>
                    <button
                      type="button"
                      className="btn text-primary btn-link p-0"
                      id="TooltipBottom"
                    >
                      <i
                        className={
                          "bx bx-help-circle font-size-16 align-middle"
                        }
                      />
                    </button>
                    <Tooltip
                      placement="bottom"
                      isOpen={tbottom}
                      target="TooltipBottom"
                      toggle={() => {
                        settbottom(!tbottom)
                      }}
                    >
                      {
                        "There may be a slight delay in delivery due to peak season or unexpected circumstances."
                      }
                    </Tooltip>
                  </Col>
                </Row> */}
                <Row className="mb-1">
                  <Col>
                    {productData &&
                      (productData.details.region == "r1" ? (
                        <img
                          src={asset.iconMalaysia}
                          srcSet={`${asset.iconMalaysia} 300w, ${asset.iconMalaysiax2} 768w, ${asset.iconMalaysiax3} 1280w`}
                          style={{ width: "16px", height: "16px" }}
                          alt="malaysia"
                          className="me-2"
                        />
                      ) : productData.details.region == "r2" ? (
                        <img
                          src={asset.iconUK}
                          srcSet={`${asset.iconUK} 300w, ${asset.iconUKx2} 768w, ${asset.iconUKx3} 1280w`}
                          style={{ width: "16px", height: "16px" }}
                          alt="UK"
                          className="me-2"
                        />
                      ) : (
                        <img
                          src={asset.iconUK}
                          srcSet={`${asset.iconUK} 300w, ${asset.iconUKx2} 768w, ${asset.iconUKx3} 1280w`}
                          style={{ width: "16px", height: "16px" }}
                          alt="UK"
                          className="me-2"
                        />
                      ))}
                    <span className="font-size-14 font-weight-semibold text-secondary  align-middle">
                      {"Ships from "}
                    </span>
                    {productData &&
                      (productData.details.region == "r1" ? (
                        <span className="font-size-14 font-weight-extrabold text-secondary  align-middle">
                          {"Kuala Lumpur, Malaysia "}
                        </span>
                      ) : productData.details.region == "r2" ? (
                        <span className="font-size-14 font-weight-extrabold text-secondary  align-middle">
                          {"Liverpool, United Kingdom "}
                        </span>
                      ) : (
                        <span className="font-size-14 font-weight-extrabold text-secondary  align-middle">
                          {"Adelaide, Australia "}
                        </span>
                      ))}
                    <button
                      type="button"
                      className="btn text-primary btn-link p-0"
                      id="TooltipBottom"
                    >
                      <i
                        className={
                          "bx bx-help-circle font-size-14 align-middle"
                        }
                      />
                    </button>
                    <Tooltip
                      placement="bottom"
                      isOpen={tbottom}
                      target="TooltipBottom"
                      toggle={() => {
                        settbottom(!tbottom)
                      }}
                    >
                      {productData &&
                        (productData.details.region == "r1"
                          ? "The entitlements will be dispatched from our warehouse in Kuala Lumpur, Malaysia."
                          : productData.details.region == "r2"
                          ? "The entitlements will be dispatched from our warehouse in Liverpool, United Kingdom."
                          : "The entitlements will be dispatched from our warehouse in Adelaide, Australia.")}
                      {/* {
                        "There may be a slight delay in delivery due to peak season or unexpected circumstances."
                      } */}
                    </Tooltip>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <span className="font-size-14 font-weight-bold text-secondary  align-middle">
                      {"Shipping fee to be calculated at checkout"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-1">
                  <Col>
                    <span className="font-size-14 font-weight-bold text-secondary  align-middle">
                      {"PAYMENT METHOD (Availability depending on country)"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <img
                      src={
                        "https://hapivity.imgix.net/payment_method.png?w=190&h=40&fit=clip&dpr=1&q=75&auto=format"
                      }
                      srcSet={`${"https://hapivity.imgix.net/payment_method.png?w=190&h=40&fit=clip&dpr=1&q=75&auto=format"} 1x, ${"https://hapivity.imgix.net/payment_method.png?w=190&h=40&fit=clip&dpr=2&q=37&auto=format"} 2x, ${"https://hapivity.imgix.net/payment_method.png?w=190&h=40&fit=clip&dpr=3&q=25&auto=format"} 3x`}
                      style={{ height: "40px" }}
                      alt="payment method"
                      className="me-2"
                    />
                  </Col>
                </Row>
                {/* <Row className="mb-1">
                  <Col>
                    <img
                      src={asset.iconShippingFee}
                      srcSet={`${asset.iconShippingFee} 300w, ${asset.iconShippingFeex2} 768w, ${asset.iconShippingFeex3} 1280w`}
                      style={{ width: "16px", height: "16px" }}
                      alt="malaysia"
                      className="me-2"
                    />
                    <span className="font-size-14 font-weight-semibold text-secondary  align-middle">
                      {productData &&
                        productData.details.shipping[selectedCountry] &&
                        productData.details.shipping[selectedCountry].express}
                      {" ( "}
                      {productData &&
                        productData.details.shipping[selectedCountry] &&
                        productData.details.shipping[selectedCountry].duration}
                      {productData &&
                        productData.details.shipping[selectedCountry] &&
                        productData.details.shipping[selectedCountry]
                          .tracking == "Tracking" &&
                        ", " +
                          productData.details.shipping[selectedCountry]
                            .tracking}
                      {" )"}
                    </span>
                  </Col>
                </Row> */}
                {/* <Row className="">
                  <Col>
                    <Card className="mt-2 shop-detail-alert">
                      <CardText>
                        {productData &&
                          productData.details.shipping_info.map(
                            (object, index) => (
                              <li style={{ color: "#FF3D00" }} key={index}>
                                <span
                                  className="font-size-13 font-weight-semibold align-middle"
                                  style={{ color: "#FF3D00" }}
                                >
                                  {object.desc}
                                </span>
                              </li>
                            )
                          )}
                      </CardText>
                    </Card>
                  </Col>
                </Row> */}
                <Row className="mb-2">
                  <Col>
                    <span className="font-size-14 font-weight-extrabold text-secondary align-middle text-uppercase">
                      {"Challenge Info"}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <span className="shop-item-subtitle font-weight-semibold text-secondary ">
                      {productData && productData.description}
                    </span>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    {productData &&
                      productData.details.info.map((object, index) => (
                        <li className="mb-2" key={index}>
                          <span className="font-size-15 font-weight-bold text-secondary align-middle">
                            {object.title} {": "}
                          </span>
                          <span className="font-size-15 font-weight-semibold text-secondary  align-middle">
                            {object.content}
                          </span>
                        </li>
                      ))}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col className="mb-3">
                    <div className="accordion" id="accordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className={
                              t_howitworks
                                ? "accordion-button howitworks text-uppercase"
                                : "accordion-button howitworks text-uppercase collapsed"
                            }
                            type="button"
                            onClick={() => {
                              sett_howitworks(!t_howitworks)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {"How does it work?"}
                          </button>
                        </h2>

                        <Collapse
                          isOpen={t_howitworks}
                          className="accordion-collapse"
                        >
                          <div className="accordion-body pt-0 howitworks">
                            <ol className="ps-3 mb-0">
                              {productData &&
                                productData.details.how_it_works.map(
                                  (object, index) => (
                                    <li key={index}>{object.description}</li>
                                  )
                                )}
                            </ol>
                          </div>
                        </Collapse>
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className={
                              t_whatdoiget
                                ? "accordion-button howitworks text-uppercase"
                                : "accordion-button howitworks text-uppercase collapsed"
                            }
                            type="button"
                            onClick={() => {
                              sett_whatdoiget(!t_whatdoiget)
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {"What do I get?"}
                          </button>
                        </h2>

                        <Collapse
                          isOpen={t_whatdoiget}
                          className="accordion-collapse"
                        >
                          <div className="accordion-body pt-0 howitworks">
                            <ol className="ps-3 mb-0">
                              {productData &&
                                productData.details.what_do_i_get.map(
                                  (object, index) => (
                                    <li key={index}>{object.description}</li>
                                  )
                                )}
                            </ol>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row className="mt-4 justify-content-center">
            <Col xs="auto">
              <span className="font-size-36 font-weight-black text-secondary">
                {"How does it works?"}
              </span>
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center d-flex">
            {howItWorks.map((object, index) => (
              <Col xs="auto" className="d-flex">
                <Card
                  className="border-radius-16"
                  style={{ width: "180px", flexGrow: 1 }}
                >
                  <CardImg
                    // className="img-fluid"
                    src={object.image}
                    srcSet={`${object.image} 300w, ${object.imagex2} 768w, ${object.imagex3} 1280w`}
                    alt="hero"
                    style={{
                      // height: "295px",
                      objectFit: "cover",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      aspectRatio: "91/46",
                    }}
                  />
                  <CardBody>
                    <Row className="justify-content-center mb-3">
                      <Col xs="auto">
                        <Badge className="rounded-pill bg-F3F4F8 text-secondary font-size-12 font-weight-extrabold p-2">
                          {"Step " + (index + 1) + "/" + howItWorks.length}
                        </Badge>
                      </Col>
                    </Row>
                    <Row>
                      <span className="font-size-14 font-weight-bold text-black text-center">
                        {object.description}
                      </span>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
            <Row className="mt-4 justify-content-center shop-detail-features bg-360623">
              <Col>
                <Row className="mt-4 justify-content-center">
                  <Col xs="auto" className="text-center">
                    <span className="font-size-20 font-weight-black text-white">
                      {productData && productData.details.features_array.title}
                    </span>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs="auto" className="text-center">
                    <span className="font-size-20 font-weight-black text-white">
                      {productData &&
                        productData.details.features_array.subtitle}
                    </span>
                  </Col>
                </Row>
                {productData &&
                  productData.details.features_array.content.map(
                    (object, index) => (
                      <Row
                        className="mt-4 justify-content-center d-flex"
                        key={index}
                      >
                        <Col className="d-flex">
                          <Card
                            className="border-radius-16 bg-transparent"
                            style={{ flexGrow: 1 }}
                          >
                            <Row className="no-gutters align-items-center">
                              <Col
                                lg={6}
                                className={
                                  index % 2 == 1 ? "d-block d-lg-none" : ""
                                }
                              >
                                <CardImg
                                  // className="img-fluid"
                                  src={
                                    object.image +
                                    "?w=774&h=569&fit=clip&dpr=1&q=75&auto=format"
                                  }
                                  srcSet={`${
                                    object.image +
                                    "?w=774&h=569&fit=clip&dpr=1&q=75&auto=format"
                                  } 1x, ${
                                    object.image +
                                    "?w=774&h=569&fit=clip&dpr=2&q=37&auto=format"
                                  } 2x, ${
                                    object.image +
                                    "?w=774&h=569&fit=clip&dpr=3&q=25&auto=format"
                                  } 3x`}
                                  alt=""
                                  style={{
                                    // height: "295px",
                                    objectFit: "cover",
                                    borderRadius: "16px",
                                    aspectRatio: "450/331",
                                    // backgroundColor: "trans",
                                  }}
                                />
                              </Col>
                              <Col lg={6}>
                                <CardBody>
                                  <Row>
                                    <span className="font-size-18 font-weight-extrabold text-white home-alternate-text text-uppercase">
                                      {object.title}
                                    </span>
                                  </Row>
                                  {/* <Row className="mt-3">
                                  <span className="font-size-20 font-weight-semibold home-alternate-text text-white">
                                    {object.description}
                                  </span>
                                </Row> */}
                                  <Row className="mt-3">
                                    {object.description.map((item, index) => (
                                      <span
                                        className="font-size-20 font-weight-semibold home-alternate-text text-white"
                                        key={index}
                                      >
                                        {index + 1 + ". " + item}
                                      </span>
                                    ))}
                                  </Row>
                                </CardBody>
                              </Col>
                              <Col
                                lg={6}
                                className={
                                  index % 2 == 0
                                    ? "d-none"
                                    : "d-none d-lg-block"
                                }
                              >
                                <CardImg
                                  // className="img-fluid"
                                  src={
                                    object.image +
                                    "?w=774&h=569&fit=clip&dpr=1&q=75&auto=format"
                                  }
                                  srcSet={`${
                                    object.image +
                                    "?w=774&h=569&fit=clip&dpr=1&q=75&auto=format"
                                  } 1x, ${
                                    object.image +
                                    "?w=774&h=569&fit=clip&dpr=2&q=37&auto=format"
                                  } 2x, ${
                                    object.image +
                                    "?w=774&h=569&fit=clip&dpr=3&q=25&auto=format"
                                  } 3x`}
                                  alt=""
                                  style={{
                                    // height: "295px",
                                    objectFit: "cover",
                                    borderRadius: "16px",
                                    aspectRatio: "450/331",
                                    // backgroundColor: "white",
                                  }}
                                />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    )
                  )}
              </Col>
            </Row>

            {/* <Row className="mt-4 justify-content-center">
            <Col xs="auto">
              <span className="font-size-36 font-weight-black text-secondary">
                {"What do i get?"}
              </span>
            </Col>
          </Row>
          <Row className="mt-4 justify-content-center d-flex">
            {whatDoIGet.map((object, index) => (
              <Col xs="auto" className="d-flex">
                <Card
                  className="border-radius-16"
                  style={{ width: "180px", flexGrow: 1 }}
                >
                  <CardImg
                    // className="img-fluid"
                    src={object.image}
                    srcSet={`${object.image} 300w, ${object.imagex2} 768w, ${object.imagex3} 1280w`}
                    alt="hero"
                    style={{
                      // height: "295px",
                      objectFit: "cover",
                      borderTopLeftRadius: "16px",
                      borderTopRightRadius: "16px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      aspectRatio: "91/67",
                    }}
                  />
                  <CardBody>
                    <Row className="justify-content-center mb-3">
                      <Col xs="auto">
                        <Badge className="rounded-pill bg-secondary font-size-12 font-weight-extrabold p-2 px-3">
                          {index + 1}
                        </Badge>
                      </Col>
                    </Row>
                    <Row>
                      <span className="font-size-14 font-weight-bold text-black text-center">
                        {object.description}
                      </span>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
            <Row className="mt-4 justify-content-center">
              <Col xs="auto" className="text-center">
                <span className="font-size-20 font-weight-black text-secondary text-uppercase">
                  {"Frequently asked questions"}
                </span>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={6} className="mb-3">
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    {productData &&
                      productData.details.faq
                        .filter(
                          (object, index) =>
                            index < productData.details.faq.length / 2
                        )
                        .map(object => (
                          <div key={object.id}>
                            <h2 className="accordion-header">
                              <button
                                className={
                                  t_faq[object.id]
                                    ? "accordion-button faq"
                                    : "accordion-button faq collapsed"
                                }
                                type="button"
                                onClick={() => {
                                  sett_faq({
                                    ...t_faq,
                                    [object.id]: !t_faq[object.id],
                                  })
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {object.title}
                              </button>
                            </h2>

                            <Collapse
                              isOpen={t_faq[object.id]}
                              className="accordion-collapse"
                            >
                              <div className="accordion-body pt-0 faq">
                                {object.content}
                              </div>
                            </Collapse>
                          </div>
                        ))}
                  </div>
                </div>
              </Col>
              <Col lg={6} className="mb-3">
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    {productData &&
                      productData.details.faq
                        .filter(
                          (object, index) =>
                            index >= productData.details.faq.length / 2
                        )
                        .map(object => (
                          <div key={object.id}>
                            <h2 className="accordion-header">
                              <button
                                className={
                                  t_faq[object.id]
                                    ? "accordion-button faq"
                                    : "accordion-button faq collapsed"
                                }
                                type="button"
                                onClick={() => {
                                  sett_faq({
                                    ...t_faq,
                                    [object.id]: !t_faq[object.id],
                                  })
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {object.title}
                              </button>
                            </h2>

                            <Collapse
                              isOpen={t_faq[object.id]}
                              className="accordion-collapse"
                            >
                              <div className="accordion-body pt-0 faq">
                                {object.content}
                              </div>
                            </Collapse>
                          </div>
                        ))}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4 justify-content-center">
              <Col xs="auto" className="text-center">
                <span className="font-size-20 font-weight-black text-secondary text-uppercase">
                  {"Can't find the answer you're looking for?"}
                </span>
              </Col>
            </Row>
            <Row className="mt-4 justify-content-center d-flex">
              <Col md={6} className="d-flex">
                <Card className="border-radius-16" style={{ flexGrow: 1 }}>
                  <CardImg
                    className="mx-auto mt-3"
                    src={asset.contactChat}
                    srcSet={`${asset.contactChat} 300w, ${asset.contactChatx2} 768w, ${asset.contactChatx3} 1280w`}
                    alt="hero"
                    style={{
                      height: "48px",
                      width: "48px",
                    }}
                  />
                  <CardBody>
                    <Row>
                      <span className="font-size-18 font-weight-bold text-black text-center">
                        {"Chat with us using the live chat on our website"}
                      </span>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col md={6} className="d-flex">
                <Card
                  className="border-radius-16"
                  style={{ flexGrow: 1, cursor: "pointer" }}
                  onClick={() => {
                    window.open("https://help.hapivity.com/", "_blank")
                    return null
                  }}
                >
                  <CardImg
                    className="mx-auto mt-3"
                    src={asset.contactHelp}
                    srcSet={`${asset.contactHelp} 300w, ${asset.contactHelpx2} 768w, ${asset.contactHelpx3} 1280w`}
                    alt="hero"
                    style={{
                      height: "48px",
                      width: "48px",
                    }}
                  />
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col xs="auto">
                        <span className="font-size-18 font-weight-bold text-black text-center">
                          {"Visit our "}
                        </span>
                        <Link
                          to=""
                          className="font-size-18 font-weight-bold text-primary text-center"
                        >
                          {"Help Center"}
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
              <Col md={6} className="d-flex">
                <Card className="border-radius-16" style={{ flexGrow: 1 }}>
                  <CardImg
                    className="mx-auto mt-3"
                    src={asset.contactEmail}
                    srcSet={`${asset.contactEmail} 300w, ${asset.contactEmailx2} 768w, ${asset.contactEmailx3} 1280w`}
                    alt="hero"
                    style={{
                      height: "48px",
                      width: "48px",
                    }}
                  />
                  <CardBody>
                    <Row>
                      <span className="font-size-18 font-weight-bold text-black text-center">
                        {"Email us at: support@hapivity.com"}
                      </span>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col md={4} lg={3} xl={2} className="d-flex">
              <Card className="border-radius-16" style={{ flexGrow: 1 }}>
                <CardImg
                  className="mx-auto mt-3"
                  src={asset.contactEmail}
                  srcSet={`${asset.contactEmail} 300w, ${asset.contactEmailx2} 768w, ${asset.contactEmailx3} 1280w`}
                  alt="hero"
                  style={{
                    height: "48px",
                    width: "48px",
                  }}
                />
                <CardBody>
                  <Row>
                    <span className="font-size-18 font-weight-bold text-black text-center">
                      {"Email us at "}
                    </span>
                    <span
                      className="btn btn-link font-size-18 font-weight-bold text-center p-0"
                      onClick={() =>
                        (window.location = "mailto:hello@hapivity.com")
                      }
                    >
                      {"hello@hapivity.com"}
                    </span>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
            </Row>
          </div>
        ) : (
          <div className="container-fluid">
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Spinner
                  className=""
                  color="primary"
                  style={{ width: "50px", height: "50px" }}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(ShopDetails)
)

ShopDetails.propTypes = { user: PropTypes.object }
