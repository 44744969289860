import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"

const CookiePolicy = props => {
  useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		function gtag() { dataLayer.push(arguments); }
		gtag('js', new Date());

		gtag('event', 'page_view', { page_title: 'Web Cookies' })
	}, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-5">
        <Row className="justify-content-center">
          <Col lg={10}>
            <h1 className="mt-3">Definitions and key terms</h1>
            <p>
              To help explain things as clearly as possible in this Cookie
              Policy, every time any of these terms are referenced, are strictly
              defined as:
            </p>
            <ul>
              <li>
                Cookie: small amount of data generated by a website and saved by
                your web browser. It is used to identify your browser, provide
                analytics, remember information about you such as your language
                preference or login information.
              </li>
              <li>
                Company: when this policy mentions “Company,” “we,” “us,” or
                “our,” it refers to Hapivity, that is responsible for your
                information under this Cookie Policy.
              </li>
              <li>
                Device: any internet connected device such as a phone, tablet,
                computer or any other device that can be used to visit Hapivity
                and use the services.
              </li>
              <li>
                Personal Data: any information that directly, indirectly, or in
                connection with other information — including a personal
                identification number — allows for the identification or
                identifiability of a natural person.
              </li>
              <li>
                Service: refers to the service provided by Hapivity as described
                in the relative terms (if available) and on this platform.{" "}
              </li>
              <li>
                Third-party service: refers to advertisers, contest sponsors,
                promotional and marketing partners, and others who provide our
                content or whose products or services we think may interest you.
              </li>
              <li>
                Website: site, which can be accessed via this URL: hapivity.com
              </li>
            </ul>

            <h1>Introduction</h1>
            <p>
              This Cookie Policy explains how Hapivity and its affiliates
              (collectively "Hapivity", "we", "us", and "ours"), use cookies and
              similar technologies to recognize you when you visit our
              website/app, including without limitation hapivity.com and any
              related URLs, mobile or localized versions and related domains /
              sub-domains ("Websites"). It explains what these technologies are
              and why we use them, as well as the choices for how to control
              them.
            </p>

            <h1>What is a cookie?</h1>
            <p>
              A cookie is a small text file that is stored on your computer or
              other internet connected device in order to identify your browser,
              provide analytics, remember information about you such as your
              language preference or login information. They're completely safe
              and can't be used to run programs or deliver viruses to your
              device.
            </p>

            <h1>Why do we use cookies?</h1>
            <p>
              We use first party and/or third party cookies on our website/app
              for various purposes such as:
            </p>
            <ul>
              <li>
                To facilitate the operation and functionality of our
                website/app;
              </li>
              <li>
                To improve your experience of our website/app and make
                navigating around them quicker and easier;
              </li>
              <li>
                To allow us to make a bespoke user experience for you and for us
                to understand what is useful or of interest to you;
              </li>
              <li>
                To analyze how our website/app is used and how best we can
                customize it;
              </li>
              <li>
                To identify future prospects and personalize marketing and sales
                interactions with it;
              </li>
              <li>
                To facilitate the tailoring of online advertising to your
                interests.
              </li>
              <li>
                You: a person or entity that is registered with Hapivity to use
                the Services.
              </li>
            </ul>

            <h1>What type of cookies does Hapivity use?</h1>
            <p>
              Cookies can either be session cookies or persistent cookies. A
              session cookie expires automatically when you close your browser.
              A persistent cookie will remain until it expires or you delete
              your cookies. Expiration dates are set in the cookies themselves;
              some may expire after a few minutes while others may expire after
              multiple years. Cookies placed by the website you’re visiting are
              called “first party cookies”.
            </p>
            <p>
              Strictly Necessary cookies are necessary for our website/app to
              function and cannot be switched off in our systems. They are
              essential in order to enable you to navigate around the
              website/app and use its features. If you remove or disable these
              cookies, we cannot guarantee that you will be able to use our
              website/app.
            </p>
            <p>We use the following types of cookies in our website/app:</p>
            <h1>Social Media Cookies</h1>
            <p>
              These cookies are used when you share information using a social
              media sharing button or “like” button on our Site or you link your
              account or engage with our content on or through a social
              networking website such as Facebook, Twitter or Google+. The
              social network will record that you have done this. These cookies
              may also include certain code that has been placed on the platform
              to help track conversions from ads, optimize ads based on
              collected data, build targeted audiences for future ads, and
              remarket to qualified users who have already taken certain action
              on the platform.
            </p>
            <h1>Third Party Cookies</h1>
            <p>
              Some cookies that have been set on our website/app are not set on
              a first party basis by Hapivity. The Websites can be embedded with
              content from third parties to serve advertising. These third party
              service providers may set their own cookies on your web browser.
              Third party service providers control many of the performance and
              functionality, advertising, marketing and analytics cookies
              described above. We do not control the use of these third party
              cookies as cookies can only be accessed by the third party that
              originally set them.
            </p>
            <h1>How you can manage cookies?</h1>
            <p>
              Most browsers allow you to control cookies through their
              'settings' preferences. However, if you limit the ability of
              websites to set cookies, you may worsen your overall user
              experience, since it will no longer be personalized to you. It may
              also stop you from saving customized settings like login
              information. Browser manufacturers provide help pages relating to
              cookie management in their products.
            </p>
            <p>
              Browser manufacturers provide help pages relating to cookie
              management in their products. Please see below for more
              information.
            </p>
            <ul>
              <li>Google Chrome</li>
              <li>Internet Explorer</li>
              <li>Mozilla Firefox</li>
              <li>Safari (Desktop)</li>
              <li>Safari (Mobile)</li>
              <li>Android Browser</li>
              <li>Opera</li>
              <li>Opera Mobile</li>
            </ul>

            <h1>Blocking and disabling cookies and similar technologies</h1>
            <p>
              Wherever you're located you may also set your browser to block
              cookies and similar technologies, but this action may block our
              essential cookies and prevent our website from functioning
              properly, and you may not be able to fully utilize all of its
              features and services. You should also be aware that you may also
              lose some saved information (e.g. saved login details, site
              preferences) if you block cookies on your browser. Different
              browsers make different controls available to you. Disabling a
              cookie or category of cookie does not delete the cookie from your
              browser, you will need to do this yourself from within your
              browser, you should visit your browser's help menu for more
              information.
            </p>

            <h1>Changes To Our Cookie Policy</h1>
            <p>
              We may change our Service and policies, and we may need to make
              changes to this Cookie Policy so that they accurately reflect our
              Service and policies. Unless otherwise required by law, we will
              notify you (for example, through our Service) before we make
              changes to this Cookie Policy and give you an opportunity to
              review them before they go into effect. Then, if you continue to
              use the Service, you will be bound by the updated Cookie Policy.
              If you do not want to agree to this or any updated Cookie Policy,
              you can delete your account.
            </p>

            <h1>Your Consent</h1>
            <p>
              By using our website/app, registering an account, or making a
              purchase, you hereby consent to our Cookie Policy and agree to its
              terms.
            </p>
            <h1>Contact Us</h1>
            <p>
              Don't hesitate to contact us if you have any questions regarding
              our Cookie Policy.
            </p>
            <ul>
              <li>
                Via our Help Page:{" "}
                <a href="https://help.hapivity.com/">help.hapivity.com</a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default CookiePolicy
