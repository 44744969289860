import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  DropdownMenu,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import { MDBDataTable } from "mdbreact"
import { success } from "toastr"
import TitleBar from "./TitleBar"
import { AvForm, AvField } from "availity-reactstrap-validation"

const ShopListAdmin = props => {
  const history = useHistory()

  const [modal, setModal] = useState(false)
  const [modalPhoto, setModalPhoto] = useState(false)

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    callOrderAPI()
  }, [])

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [shopData, setShopData] = useState([])
  const [packageData, setPackageData] = useState([])
  const [photoData, setPhotoData] = useState([])

  const callOrderAPI = async () => {
    const response = await fetch(url.HAPIVITY_GET_ADMIN_SHOP_LIST)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      console.log("Admin shop list", postresponse.data)
      setShopData(postresponse.data)
      if (selectedShopData) {
        if (
          postresponse.data.findIndex(item => item.id == selectedShopData.id) >=
          0
        )
          setSelectedShopData(
            postresponse.data.find(item => item.id == selectedShopData.id)
          )
      }
      // setTableData(filterData(postresponse.data))
    }

    const packageResponse = await fetch(url.HAPIVITY_GET_ADMIN_PACKAGE_LIST)

    const postPackageResponse = await packageResponse.json()

    if (postPackageResponse.status == 200) {
      console.log("Admin package list", postPackageResponse.data)
      setPackageData(postPackageResponse.data)
      // setTableData(filterData(postresponse.data))
    }

    const photoResponse = await fetch(url.HAPIVITY_GET_ADMIN_PHOTO_LIST)

    const postPhotoResponse = await photoResponse.json()

    if (postPhotoResponse.status == 200) {
      console.log("Admin photo list", postPhotoResponse.data)
      setPhotoData(postPhotoResponse.data)
      // setTableData(filterData(postresponse.data))
    }
  }

  const [selectedShopData, setSelectedShopData] = useState(null)

  const onclick_edit_package = selectedShopData => {
    setModal(true)
    setSelectedShopData(selectedShopData)
  }

  const onclick_add_remove_package = async (action, pid) => {
    console.log(action + " " + pid + " to " + selectedShopData.id)
    let formdata = new FormData()
    formdata.append("pid", pid)
    formdata.append("sid", selectedShopData.id)
    formdata.append("action", action)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_EDIT_SHOP_PACKAGE, requestOptions)
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      callOrderAPI()
    }
  }

  const [photoFilter, setPhotoFilter] = useState("")

  const updatePhotoFilter = e => {
    setPhotoFilter(e.target.value)
  }

  const onclick_edit_photo = selectedShopData => {
    setModalPhoto(true)
    setSelectedShopData(selectedShopData)
  }

  const onclick_add_remove_photo = async (action, pid) => {
    console.log(action + " " + pid + " to " + selectedShopData.id)
    let formdata = new FormData()
    formdata.append("pid", pid)
    formdata.append("sid", selectedShopData.id)
    formdata.append("action", action)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_EDIT_SHOP_PHOTO, requestOptions)
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      callOrderAPI()
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card className="bg-transparent">
              <CardBody>
                <TitleBar />
                <CardTitle className="font-weight-bold font-size-20">
                  Shop List
                </CardTitle>
                {shopData &&
                  shopData.map(item => (
                    <Card className="p-3">
                      <Row>
                        <Col xs={"auto"}>
                          <CardImg
                            src={
                              item.image +
                              "?w=80&h=80&fit=clip&dpr=1&q=75&auto=format"
                            }
                            alt="shop image"
                            style={{
                              width: "80px",
                              objectFit: "cover",
                              aspectRatio: "1/1",
                            }}
                            className="border-radius-16"
                          />
                        </Col>
                        <Col>
                          <Row className="mb-2">
                            <Col>
                              <span className="font-size-16 font-weight-bold">
                                {item.name}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <span>{item.description}</span>
                            </Col>
                          </Row>
                          {/* <Row>
                            <Col>
                              <span className="font-weight-bold font-size-16">
                                {"Original price"}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            {Object.entries(item.price_ori).map(
                              ([key, value]) => (
                                <Col>
                                  <span className="font-weight-bold">
                                    {key + " "}
                                  </span>
                                  <span>{formatter.format(value)}</span>
                                </Col>
                              )
                            )}
                          </Row> */}
                          <Row>
                            <Col>
                              <span className="font-weight-bold font-size-16">
                                {"Package Available"}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              {item.package.map(object => (
                                <Row className="mb-2">
                                  <Col>
                                    <Row className="mb-1">
                                      <Col>
                                        <span className="font-weight-bold">
                                          {object.name}
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row>
                                      {Object.entries(object.price_ori).map(
                                        ([key, value]) => (
                                          <Col>
                                            <span className="font-weight-bold text-primary">
                                              {key + " "}
                                            </span>
                                            <span className="text-primary">
                                              {formatter.format(value)}
                                            </span>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                    <Row>
                                      {Object.entries(
                                        object.price_discount
                                      ).map(([key, value]) => (
                                        <Col>
                                          <span className="font-weight-bold">
                                            {key + " "}
                                          </span>
                                          <span>{formatter.format(value)}</span>
                                        </Col>
                                      ))}
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            {item.photo.map(object => (
                              <Col xs="auto">
                                <Row>
                                  <Col>
                                    <CardImg
                                      src={
                                        object.url +
                                        "?w=100&h=100&fit=clip&dpr=1&q=75&auto=format"
                                      }
                                      alt="shop image"
                                      style={{
                                        width: "100px",
                                        objectFit: "cover",
                                        aspectRatio: "1/1",
                                      }}
                                      className="border-radius-16"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-1">
                                  <Col>
                                    <span className="font-weight-bold">
                                      {object.name}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            ))}
                          </Row>
                          <Row>
                            <Col xs="auto">
                              <button
                                className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                style={{ height: "40px" }}
                                onClick={() => {
                                  // history.push("/admzzz-shop")
                                  onclick_edit_package(item)
                                }}
                              >
                                {"Add/Remove Package"}
                              </button>
                            </Col>
                            <Col xs="auto">
                              <button
                                className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                style={{ height: "40px" }}
                                onClick={() => {
                                  // history.push("/admzzz-shop")
                                  onclick_edit_photo(item)
                                }}
                              >
                                {"Add/Remove Photo"}
                              </button>
                            </Col>
                            <Col xs="auto">
                              <button
                                className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                style={{ height: "40px" }}
                                onClick={() => {
                                  // history.push("/admzzz-shop")
                                }}
                              >
                                {"Edit Product Info"}
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <ModalHeader toggle={() => setModal(!modal)}>
              Add/Remove Package
            </ModalHeader>
            <ModalBody>
              {selectedShopData && (
                <Row className="mb-3">
                  <Col>
                    <span className="font-size-16 font-weight-bold">
                      {selectedShopData.name}
                    </span>
                  </Col>
                </Row>
              )}
              {packageData &&
                packageData.map(item => (
                  <Row className="py-2">
                    <Col>{item.name}</Col>
                    {selectedShopData && (
                      <Col xs="auto">
                        {selectedShopData.package.findIndex(
                          object => object.pid == item.id
                        ) < 0 ? (
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              // history.push("/admzzz-shop")
                              onclick_add_remove_package("add", item.id)
                            }}
                          >
                            {"Add"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              // history.push("/admzzz-shop")
                              onclick_add_remove_package("remove", item.id)
                            }}
                          >
                            {"Remove"}
                          </button>
                        )}
                      </Col>
                    )}
                  </Row>
                ))}
            </ModalBody>
            <ModalFooter>
              <Button type="button" onClick={() => setModal(!modal)}>
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
        <Modal
          toggle={() => setModalPhoto(!modalPhoto)}
          isOpen={modalPhoto}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <ModalHeader toggle={() => setModalPhoto(!modalPhoto)}>
              Add/Remove Photo
            </ModalHeader>
            <ModalBody>
              {selectedShopData && (
                <Row className="mb-3">
                  <Col>
                    <span className="font-size-16 font-weight-bold">
                      {selectedShopData.name}
                    </span>
                  </Col>
                </Row>
              )}
              <AvForm>
                <AvField
                  name="photoFilter"
                  // label="Name"
                  value={photoFilter}
                  className="form-control font-weight-semibold font-size-14 mb-3"
                  placeholder="Search photo"
                  type="text"
                  style={{
                    // border: "2px solid",
                    // borderColor: "#F3F4F8",
                    borderWidth: "2px",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  onChange={updatePhotoFilter}
                />
              </AvForm>
              {photoData &&
                photoData
                  .filter(object =>
                    object.name
                      .toLowerCase()
                      .includes(photoFilter.toLowerCase())
                  )
                  .map(item => (
                    <Row className="py-2">
                      <Col xs="auto">
                        <CardImg
                          src={
                            item.url +
                            "?w=100&h=100&fit=clip&dpr=1&q=75&auto=format"
                          }
                          alt="shop image"
                          style={{
                            width: "100px",
                            objectFit: "cover",
                            aspectRatio: "1/1",
                          }}
                          className="border-radius-16"
                        />
                      </Col>
                      <Col>{item.name}</Col>
                      {selectedShopData && (
                        <Col xs="auto">
                          {selectedShopData.photo.findIndex(
                            object => object.pid == item.id
                          ) < 0 ? (
                            <button
                              className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                              style={{ height: "40px" }}
                              onClick={() => {
                                // history.push("/admzzz-shop")
                                onclick_add_remove_photo("add", item.id)
                              }}
                            >
                              {"Add"}
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                              style={{ height: "40px" }}
                              onClick={() => {
                                // history.push("/admzzz-shop")
                                onclick_add_remove_photo("remove", item.id)
                              }}
                            >
                              {"Remove"}
                            </button>
                          )}
                        </Col>
                      )}
                    </Row>
                  ))}
            </ModalBody>
            <ModalFooter>
              <Button type="button" onClick={() => setModalPhoto(!modalPhoto)}>
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(ShopListAdmin))

ShopListAdmin.propTypes = { user: PropTypes.object }
