import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"

const Home = props => {
  const history = useHistory()

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     history.push("/dashboard")
  //   }
  // }, [])

  useEffect(() => {
    getPageData()
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())

    gtag("event", "page_view", {
      page_title: "Web Home",
    })
  }, [])

  const [pageData, setPageData] = useState(null)
  const [t_faq, sett_faq] = useState({})

  const getPageData = async () => {
    const response = await fetch(url.HAPIVITY_HOME_PAGE)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setPageData(postresponse.data)
      postresponse.data[9].content.forEach(object => {
        sett_faq({ ...t_faq, [object.id]: false })
      })
    }
  }

  const componentHandler = (data, index) => {
    switch (data.item) {
      case "hero":
        return (
          <Card>
            <CardImg className="home-hero-image" src={data.content.image} />

            <CardImgOverlay className="text-center d-flex align-items-center justify-content-center">
              <Row className="">
                <Col className="">
                  <CardTitle className="text-white hero-font-title">
                    {data.content.title.toUpperCase()}
                  </CardTitle>
                  <CardText className="text-white hero-font-subtitle">
                    {data.content.subtitle}
                  </CardText>
                  <Link to="/store">
                    <button
                      className="btn btn-primary font-weight-extrabold font-size-16 px-4"
                      style={{ borderRadius: "16px", height: "54px" }}
                      onClick={() => {
                        gtag("event", "Home Explore")
                      }}
                    >
                      {"EXPLORE"}
                    </button>
                  </Link>
                </Col>
              </Row>
            </CardImgOverlay>
          </Card>
        )
      case "how_it_works":
        return (
          <>
            <Row
              className="mt-5 justify-content-center"
              style={{ textAlign: "center" }}
            >
              <Col xs="auto">
                <span className="text-secondary home-howitworks-title">
                  {data.content.title}
                </span>
              </Col>
            </Row>
            <Row
              className="mt-4 justify-content-center d-flex"
              style={{ paddingLeft: "16px", paddingRight: "16px" }}
            >
              <ScrollContainer className="scroll-container">
                <Row className="flex-nowrap home-howitworks-cards">
                  {data.content.content.map((object, index) => (
                    <Col xs="auto" className="d-flex">
                      <Card
                        className="border-radius-16 shadow-sm"
                        style={{ width: "180px", flexGrow: 1 }}
                      >
                        <CardImg
                          src={object.image}
                          style={{
                            // height: "295px",
                            objectFit: "cover",
                            borderTopLeftRadius: "16px",
                            borderTopRightRadius: "16px",
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px",
                            aspectRatio: "91/46",
                          }}
                        />
                        <CardBody>
                          <Row className="justify-content-center mb-3">
                            <Col xs="auto">
                              <Badge className="rounded-pill bg-F3F4F8 text-secondary font-size-12 font-weight-extrabold p-2">
                                {"Step " +
                                  (index + 1) +
                                  "/" +
                                  data.content.content.length}
                              </Badge>
                            </Col>
                          </Row>
                          <Row>
                            <span className="font-size-14 font-weight-bold text-black text-center">
                              {object.description}
                            </span>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </ScrollContainer>
            </Row>
          </>
        )
      case "writeup":
        return (
          <Row className="mt-5 mb-5 justify-content-center text-center">
            <Col lg={10}>
              <Row className="">
                <span className="font-size-48 text-primary font-weight-black home-writeup-title">
                  {data.content.title_1}
                </span>
              </Row>
              <Row className="">
                <span className="font-size-48 text-secondary font-weight-black home-writeup-title">
                  {data.content.title_2}
                </span>
              </Row>
              <Row className="mt-3">
                <span className="font-size-28 text-secondary font-weight-bold home-writeup-subtitle">
                  {data.content.subtitle}
                </span>
              </Row>
              {typeof data.content.link != "undefined" ? (
                <Link to="/store">
                  <button
                    className="btn btn-primary font-weight-extrabold font-size-16 px-4 mt-4"
                    style={{ borderRadius: "16px", height: "54px" }}
                    //onClick={() => {}}
                  >
                    {"EXPLORE"}
                  </button>
                </Link>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        )
      case "app_download":
        return (
          <>
            <Row className="justify-content-center mb-3">
              <Col xs="auto">
                <span className="font-weight-extrabold font-size-16 text-secondary">
                  {"Get the App"}
                </span>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="2" md="2" sm="3" xs="4" className="p-1 px-lg-2">
                <Card className="shadow-none bg-transparent">
                  <button
                    className="btn bg-white px-1 px-lg-2 px-xl-3"
                    style={{
                      border: "2px solid #F3F4F8",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      gtag("event", "Apple Download")
                    }}
                  >
                    <a
                      href={data.content.apple}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={asset.appstoreDownload}
                        srcSet={`${asset.appstoreDownload} 300w, ${asset.appstoreDownloadx2} 768w, ${asset.appstoreDownloadx3} 1280w`}
                        alt=""
                        style={{
                          // height: "100%",
                          // maxWidth: "100%",
                          width: "100%",
                          aspectRatio: "43/10",
                        }}
                        className="me-1"
                      />
                    </a>
                  </button>
                </Card>
              </Col>
              <Col lg="2" md="2" sm="3" xs="4" className="p-1 px-lg-2">
                <Card className="shadow-none bg-transparent">
                  <button
                    className="btn bg-white px-1 px-lg-2 px-xl-3"
                    style={{
                      border: "2px solid #F3F4F8",
                      borderRadius: "16px",
                    }}
                    onClick={() => {
                      gtag("event", "Google Download")
                    }}
                  >
                    <a
                      href={data.content.google}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={asset.googleDownload}
                        srcSet={`${asset.googleDownload} 300w, ${asset.googleDownloadx2} 768w, ${asset.googleDownloadx3} 1280w`}
                        alt=""
                        style={{
                          // height: "100%",
                          width: "100%",
                          aspectRatio: "43/10",
                        }}
                        className="me-1"
                      />
                    </a>
                  </button>
                </Card>
              </Col>
            </Row>
          </>
        )
      case "alternate":
        return (
          <>
            <Row className="mt-4 justify-content-center bg-360623">
              <Col className="">
                {data.content.map((object, index) => (
                  <Row
                    className="mt-4 justify-content-center d-flex"
                    key={index}
                  >
                    <Col className="d-flex">
                      <Card
                        className="border-radius-16 bg-transparent"
                        style={{ flexGrow: 1 }}
                      >
                        <Row className="no-gutters align-items-center">
                          <Col
                            lg={{ offset: 1, size: 5 }}
                            className={
                              index % 2 == 1 ? "d-block d-lg-none" : ""
                            }
                          >
                            <CardImg
                              className="home-alternate-image"
                              src={object.image}
                              srcSet={`${
                                object.image +
                                "?w=900&h=600&fit=clip&dpr=1&q=75&auto=format"
                              } 1x,
                                         ${
                                           object.image +
                                           "?w=900&h=600&fit=clip&dpr=2&q=37&auto=format"
                                         } 2x,
                                         ${
                                           object.image +
                                           "?w=900&h=600&fit=clip&dpr=3&q=25&auto=format"
                                         } 3x`}
                            />
                          </Col>

                          <Col lg={{ offset: index % 2 == 1 ? 1 : 0, size: 5 }}>
                            <CardBody>
                              <Row>
                                <span className="font-size-20 font-weight-extrabold text-white home-alternate-text">
                                  {object.title.toUpperCase()}
                                </span>
                              </Row>
                              <Row className="mt-3">
                                <span className="font-size-20 font-weight-semibold text-white home-alternate-text">
                                  {object.subtitle}
                                </span>
                              </Row>
                              <Row className="mt-5">
                                <Link
                                  className="font-size-16 font-weight-bold text-white home-alternate-text"
                                  to="/store"
                                >
                                  {"FIND OUT MORE"}
                                </Link>
                              </Row>
                            </CardBody>
                          </Col>

                          <Col
                            lg={5}
                            className={
                              index % 2 == 0 ? "d-none" : "d-none d-lg-block"
                            }
                          >
                            <CardImg
                              src={object.image}
                              srcSet={`${
                                object.image +
                                "?w=928&h=660&fit=clip&dpr=1&q=75&auto=format"
                              } 1x,
                                         ${
                                           object.image +
                                           "?w=928&h=660&fit=clip&dpr=2&q=37&auto=format"
                                         } 2x,
                                         ${
                                           object.image +
                                           "?w=928&h=660&fit=clip&dpr=3&q=25&auto=format"
                                         } 3x`}
                              style={{
                                objectFit: "cover",
                                borderRadius: "16px",
                                aspectRatio: "450/331",
                              }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </>
        )
      case "image":
        return (
          <Card>
            <CardImg
              className="img-fluid"
              src={data.content.image}
              srcSet={`${
                data.content.image +
                "?w=2000&h=1000&fit=clip&dpr=1&q=75&auto=format"
              } 1x,
                       ${
                         data.content.image +
                         "?w=2000&h=1000&fit=clip&dpr=2&q=37&auto=format"
                       } 2x,
                       ${
                         data.content.image +
                         "?w=2000&h=1000&fit=clip&dpr=3&q=25&auto=format"
                       } 3x`}
            />
          </Card>
        )
      case "faq":
        break
        return (
          <>
            <Row className="mt-4 content-justify-center text-center">
              <Col className="">
                <span
                  className="font-weight-black text-secondary hero-font-title"
                  style={{ paddingLeft: "16px", paddingRight: "16px" }}
                >
                  {"Frequently asked questions"}
                </span>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={{ offset: 1, size: 5 }} className="mb-3">
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    {data.content
                      .filter(
                        (object, index) => index < data.content.length / 2
                      )
                      .map(object => (
                        <div key={object.id}>
                          <h2 className="accordion-header">
                            <button
                              className={
                                t_faq[object.id]
                                  ? "accordion-button faq"
                                  : "accordion-button faq collapsed"
                              }
                              type="button"
                              onClick={() => {
                                sett_faq({
                                  ...t_faq,
                                  [object.id]: !t_faq[object.id],
                                })
                                gtag("event", "FAQ")
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {object.title}
                            </button>
                          </h2>

                          <Collapse
                            isOpen={t_faq[object.id]}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body pt-0 faq">
                              {object.content}
                            </div>
                          </Collapse>
                        </div>
                      ))}
                  </div>
                </div>
              </Col>

              <Col lg={"5"} className="mb-3">
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    {data.content
                      .filter(
                        (object, index) => index >= data.content.length / 2
                      )
                      .map(object => (
                        <div key={object.id}>
                          <h2 className="accordion-header">
                            <button
                              className={
                                t_faq[object.id]
                                  ? "accordion-button faq"
                                  : "accordion-button faq collapsed"
                              }
                              type="button"
                              onClick={() => {
                                sett_faq({
                                  ...t_faq,
                                  [object.id]: !t_faq[object.id],
                                })
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {object.title}
                            </button>
                          </h2>

                          <Collapse
                            isOpen={t_faq[object.id]}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body pt-0 faq">
                              {object.content}
                            </div>
                          </Collapse>
                        </div>
                      ))}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-0">
        {/* <div className="container-fluid"> */}
        {pageData &&
          pageData.map((object, index) => componentHandler(object, index))}

        {/* ===== OLD ======
        <Card>
          <CardImg
            className="img-fluid"
            src={asset.heroHome}
            srcSet={`${asset.heroHome} 300w, ${asset.heroHomex2} 768w, ${asset.heroHomex3} 1280w`}
            alt="hero"
          />
          <CardImgOverlay className="text-center d-flex align-items-center justify-content-center">
            <Row className="">
              <Col className="">
                <CardTitle className="text-primary mt-0 hero-font-title">
                  {"Fitness, redefined"}
                </CardTitle>
                <CardText className="text-secondary hero-font-subtitle font-weight-bold">
                  {"In Hapivity, our vision is to take "}{" "}
                  <strong>Virtual Workout</strong>{" "}
                  {
                    " to another level. We believed that with the right motivation, everyone can achieve their fitness's goal."
                  }
                </CardText>
                <button
                  className="btn btn-primary font-weight-extrabold font-size-16 px-4"
                  style={{ borderRadius: "16px", height: "54px" }}
                  onClick={() => {
                    props.showJoinNowAction(true)
                  }}
                >
                  {"Join now"}
                </button>
              </Col>
            </Row>
          </CardImgOverlay>
        </Card>*/}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  // const { error } = state.Home
  return {}
}

export default withRouter(connect(mapStateToProps, { showJoinNowAction })(Home))

Home.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
