import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  DropdownMenu,
  Label,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import { MDBDataTable } from "mdbreact"
import TitleBar from "./TitleBar"

const OrderListAdmin = props => {
  const history = useHistory()

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState("")
  const [modalEdit, setModalEdit] = useState(false)

  const [modalCommission, setModalCommission] = useState(false)

  useEffect(() => {
    callOrderAPI()
  }, [])

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [tableData, setTableData] = useState([])

  const callOrderAPI = async () => {
    const response = await fetch(url.HAPIVITY_GET_PROMO_CODE_ADMIN)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setTableData(filterData(postresponse.data))
    }
  }

  const filterData = data => {
    console.log("Filtering")
    let rows = []
    data.forEach(item =>
      rows.push({
        counter: rows.length + 1,
        code: item.code,
        type: item.type,
        amount: item.amount,
        remaining: item.remaining,
        description: item.description,
        action: (
          <button
            className="btn btn-primary"
            onClick={() => {
              setModal(true)
              setModalData(item)
              setModalEdit(true)
            }}
          >
            {"Details"}
          </button>
        ),
      })
    )
    return rows
  }

  const data = {
    columns: [
      {
        label: "No.",
        field: "counter",
        sort: "asc",
      },
      {
        label: "Promo code",
        field: "code",
        sort: "asc",
      },
      {
        label: "Type",
        field: "type",
      },
      {
        label: "Discount Amount",
        field: "amount",
      },
      {
        label: "Code Remaining",
        field: "remaining",
      },
      {
        label: "Description",
        field: "description",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: tableData,
  }

  function handleValidSubmit(event, values) {
    if (modalEdit) {
      onclick_edit_promo(values)
    } else {
      onclick_add_promo(values)
    }
    console.log(values)
  }

  const onclick_add_promo = async formValues => {
    console.log(formValues)

    let formdata = new FormData()
    formdata.append("type", formValues.type)
    formdata.append("amount", formValues.discount)
    formdata.append("code", formValues.code)
    formdata.append("remaining", formValues.remaining)
    formdata.append("description", formValues.description)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_ADD_PROMO_CODE_ADMIN,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200 && postresponse.success) {
      setModal(false)
      callOrderAPI()
    } else if (!postresponse.success) {
      alert(postresponse.message)
    }
  }

  const onclick_edit_promo = async formValues => {
    console.log(formValues)

    let formdata = new FormData()
    formdata.append("type", formValues.type)
    formdata.append("amount", formValues.discount)
    formdata.append("code", formValues.code)
    formdata.append("remaining", formValues.remaining)
    formdata.append("description", formValues.description)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_EDIT_PROMO_CODE_ADMIN,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200 && postresponse.success) {
      setModal(false)
      callOrderAPI()
    } else if (!postresponse.success) {
      alert(postresponse.message)
    }
  }

  const onclick_delete_promo = async () => {
    let formdata = new FormData()
    formdata.append("code", modalData.code)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_DELETE_PROMO_CODE_ADMIN,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200 && postresponse.success) {
      setModal(false)
      callOrderAPI()
    } else if (!postresponse.success) {
      alert(postresponse.message)
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <TitleBar />
                <Row>
                  <Col>
                    <CardTitle className="font-weight-bold font-size-20">
                      Promo Code
                    </CardTitle>
                  </Col>
                  <Col xs="auto">
                    <button
                      className="btn btn-light btn-block waves-effect waves-light font-size-14"
                      style={{
                        borderRadius: "8px",
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        setModal(true)
                        setModalEdit(false)
                      }}
                    >
                      {"Add New Promo Code"}
                    </button>
                  </Col>
                </Row>
                <MDBDataTable
                  responsive
                  bordered
                  hover
                  noBottomColumns
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <ModalHeader toggle={() => setModal(!modal)}>
                Add/edit promo code
              </ModalHeader>

              <ModalBody>
                <AvField
                  name="type"
                  value={"percentage"}
                  label="Code type"
                  className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-8 border-width-2"
                  placeholder="Code type"
                  type="text"
                  disabled={true}
                  // onChange={e => {
                  //   setSelectedPrice(e.target.value)
                  // }}
                  // validate={{
                  //   required: {
                  //     value: true,
                  //     errorMessage: "Required",
                  //   },
                  // }}
                />
                <AvField
                  name="code"
                  label="Promo code"
                  value={modalEdit ? modalData.code : null}
                  className="form-control font-weight-semibold font-size-14 mb-3"
                  placeholder="Promo code"
                  type="text"
                  style={{
                    // border: "2px solid",
                    // borderColor: "#F3F4F8",
                    borderWidth: "2px",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Required",
                    },
                  }}
                  disabled={modalEdit}
                />
                <AvField
                  name="discount"
                  label="Discount in %"
                  value={modalEdit ? modalData.amount : null}
                  className="form-control font-weight-semibold font-size-14 mb-3"
                  placeholder="Discount in %"
                  type="number"
                  style={{
                    // border: "2px solid",
                    // borderColor: "#F3F4F8",
                    borderWidth: "2px",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Required",
                    },
                  }}
                />
                <AvField
                  name="remaining"
                  label="Remaining promo code"
                  value={modalEdit ? modalData.remaining : null}
                  className="form-control font-weight-semibold font-size-14 mb-3"
                  placeholder="Remaining promo code"
                  type="number"
                  style={{
                    // border: "2px solid",
                    // borderColor: "#F3F4F8",
                    borderWidth: "2px",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Required",
                    },
                  }}
                />
                <AvField
                  name="description"
                  label="Description"
                  value={modalEdit ? modalData.description : null}
                  className="form-control font-weight-semibold font-size-14 mb-3"
                  placeholder="Description"
                  type="text"
                  style={{
                    // border: "2px solid",
                    // borderColor: "#F3F4F8",
                    borderWidth: "2px",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Required",
                    },
                  }}
                />
              </ModalBody>
              <ModalFooter>
                {modalEdit && (
                  <button
                    className="btn btn-danger font-weight-bold font-size-12 border-radius-8 btn-block me-auto"
                    style={{ height: "38px" }}
                    onClick={e => {
                      e.preventDefault()
                      onclick_delete_promo()
                    }}
                  >
                    {"Delete"}
                  </button>
                )}

                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-8 btn-block"
                  style={{ height: "38px" }}
                  type="submit"
                >
                  {modalEdit ? "Save" : "Add"}
                </button>

                <Button type="button" onClick={() => setModal(!modal)}>
                  Close
                </Button>
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(OrderListAdmin))

OrderListAdmin.propTypes = { user: PropTypes.object }
