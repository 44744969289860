import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  DropdownMenu,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

import { AvForm, AvField } from "availity-reactstrap-validation"
import TitleBar from "./TitleBar"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"

const PackageListAdmin = props => {
  const history = useHistory()

  const [modal, setModal] = useState(false)
  const [modalShipping, setModalShipping] = useState(false)
  const [modalPrice, setModalPrice] = useState(false)

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    callOrderAPI()
  }, [])

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [shopData, setShopData] = useState([])
  const [entitlementData, setEntitlementData] = useState([])
  const [shippingData, setShippingData] = useState([])
  const [priceData, setPriceData] = useState([])

  const callOrderAPI = async () => {
    const response = await fetch(url.HAPIVITY_GET_ADMIN_PACKAGE_LIST)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      console.log("Admin package list", postresponse.data)
      setShopData(postresponse.data)
      if (selectedPackageData) {
        if (
          postresponse.data.findIndex(
            item => item.id == selectedPackageData.id
          ) >= 0
        )
          setSelectedPackageData(
            postresponse.data.find(item => item.id == selectedPackageData.id)
          )
      }
    }

    const entitlementResponse = await fetch(
      url.HAPIVITY_GET_ADMIN_ENTITLEMENT_LIST
    )

    const postEntitlementResponse = await entitlementResponse.json()

    if (postEntitlementResponse.status == 200) {
      console.log("Admin entitlement list", postEntitlementResponse.data)
      setEntitlementData(postEntitlementResponse.data)
    }

    const shippingResponse = await fetch(url.HAPIVITY_GET_ADMIN_SHIPPING_LIST)

    const postShippingResponse = await shippingResponse.json()

    if (postShippingResponse.status == 200) {
      console.log("Admin shipping list", postShippingResponse.data)
      setShippingData(postShippingResponse.data)
    }

    const priceResponse = await fetch(url.HAPIVITY_GET_ADMIN_PRICE_LIST)

    const postPriceResponse = await priceResponse.json()

    if (postPriceResponse.status == 200) {
      console.log("Admin shipping list", postPriceResponse.data)
      setPriceData(postPriceResponse.data)
    }
  }

  const [selectedPackageData, setSelectedPackageData] = useState(null)

  const onclick_edit_entitlement = selectedPackageData => {
    setModal(true)
    setSelectedPackageData(selectedPackageData)
  }

  const onclick_add_remove_entitlement = async (action, eid) => {
    console.log(action + " " + eid + " to " + selectedPackageData.id)
    let formdata = new FormData()
    formdata.append("eid", eid)
    formdata.append("pid", selectedPackageData.id)
    formdata.append("action", action)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_EDIT_PACKAGE_ENTITLEMENT,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      callOrderAPI()
    }
  }

  const onclick_edit_shipping = selectedPackageData => {
    setModalShipping(true)
    setSelectedPackageData(selectedPackageData)
  }

  const onclick_add_remove_shipping = async (action, sid) => {
    console.log(action + " " + sid + " to " + selectedPackageData.id)
    let formdata = new FormData()
    formdata.append("sid", sid)
    formdata.append("pid", selectedPackageData.id)
    formdata.append("action", action)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_EDIT_PACKAGE_SHIPPING,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      callOrderAPI()
    }
  }

  const [priceType, setPriceType] = useState(null)
  const [selectedPrice, setSelectedPrice] = useState(null)

  const onclick_edit_price = (selectedPackageData, type) => {
    setModalPrice(true)
    setPriceType(type)
    if (type == "original") setSelectedPrice(selectedPackageData.price_ori)
    else setSelectedPrice(selectedPackageData.price_discount)
    setSelectedPackageData(selectedPackageData)
  }

  const onclick_apply_price = async () => {
    console.log(
      "apply " +
        priceType +
        " price " +
        selectedPrice +
        " to " +
        selectedPackageData.id
    )
    let formdata = new FormData()
    formdata.append("price", selectedPrice)
    formdata.append("pid", selectedPackageData.id)
    formdata.append("action", priceType)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_EDIT_PACKAGE_PRICE,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      callOrderAPI()
    }
  }

  const onclick_toggle_availability = async (pid, published) => {
    console.log(pid + " : " + published)
    let formdata = new FormData()
    formdata.append("pid", pid)
    formdata.append("status", published ? 0 : 1)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_UPDATE_PACKAGE_AVAILABILITY,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      callOrderAPI()
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card className="bg-transparent">
              <CardBody>
                <TitleBar />
                <CardTitle className="font-weight-bold font-size-20">
                  Package List
                </CardTitle>
                {shopData &&
                  shopData.map(item => (
                    <Card className="p-3">
                      <Row className="mb-2">
                        <Col>
                          <span className="font-size-16 font-weight-bold">
                            {item.name}
                          </span>
                        </Col>
                        <Col xs="auto">
                          <button
                            className={
                              item.published
                                ? "btn btn-success font-weight-bold font-size-12 border-radius-12 btn-block"
                                : "btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            }
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_toggle_availability(
                                item.id,
                                item.published
                              )
                            }}
                          >
                            {item.published ? "Available" : "Sold out"}
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <span className="font-size-16 font-weight-bold">
                                {"Shop Assigned"}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <ul>
                                {item.shop.map(object => (
                                  <li>{object.name}</li>
                                ))}
                              </ul>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col>
                              <span className="font-size-16 font-weight-bold">
                                {"Shipping Available"}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <ul>
                                {item.shipping.map(object => (
                                  <li>
                                    {object.country +
                                      " (MYR " +
                                      formatter.format(object.price.MYR) +
                                      ")"}
                                  </li>
                                ))}
                              </ul>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col>
                              <span className="font-size-16 font-weight-bold">
                                {"Entitlement"}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <ul>
                                {item.entitlement.map(object => (
                                  <li>{object.name}</li>
                                ))}
                              </ul>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <span className="font-size-16 font-weight-bold text-primary">
                            {"Price Original"}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        {Object.entries(item.price_ori_list).map(
                          ([key, value]) => (
                            <Col>
                              <span className="font-weight-bold text-primary">
                                {key + " "}
                              </span>
                              <span className="text-primary">
                                {formatter.format(value)}
                              </span>
                            </Col>
                          )
                        )}
                      </Row>
                      <Row>
                        {Object.entries(item.price_discount_list).map(
                          ([key, value]) => (
                            <Col>
                              <span className="font-weight-bold">
                                {key + " "}
                              </span>
                              <span>{formatter.format(value)}</span>
                            </Col>
                          )
                        )}
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <span className="font-size-16 font-weight-bold">
                            {"Price Discount"}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="auto">
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_edit_price(item, "original")
                            }}
                          >
                            {"Update Original Price"}
                          </button>
                        </Col>
                        <Col xs="auto">
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_edit_price(item, "discount")
                            }}
                          >
                            {"Update Discount Price"}
                          </button>
                        </Col>
                        <Col xs="auto">
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              // history.push("/admzzz-shop")
                              onclick_edit_entitlement(item)
                            }}
                          >
                            {"Add/Remove Entitlement"}
                          </button>
                        </Col>
                        <Col xs="auto">
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              // history.push("/admzzz-shop")
                              onclick_edit_shipping(item)
                            }}
                          >
                            {"Add/Remove Shipping"}
                          </button>
                        </Col>
                        {/* <Col xs="auto">
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              // history.push("/admzzz-shop")
                            }}
                          >
                            {"Edit Package Info"}
                          </button>
                        </Col> */}
                      </Row>
                    </Card>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <ModalHeader toggle={() => setModal(!modal)}>
              Add/Remove Entitlement
            </ModalHeader>
            <ModalBody>
              {selectedPackageData && (
                <Row className="mb-3">
                  <Col>
                    <span className="font-size-16 font-weight-bold">
                      {selectedPackageData.name}
                    </span>
                  </Col>
                </Row>
              )}
              {entitlementData &&
                entitlementData.map(item => (
                  <Row className="py-2">
                    <Col>{item.name}</Col>
                    {selectedPackageData && (
                      <Col xs="auto">
                        {selectedPackageData.entitlement.findIndex(
                          object => object.eid == item.id
                        ) < 0 ? (
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_add_remove_entitlement("add", item.id)
                            }}
                          >
                            {"Add"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_add_remove_entitlement("remove", item.id)
                            }}
                          >
                            {"Remove"}
                          </button>
                        )}
                      </Col>
                    )}
                  </Row>
                ))}
            </ModalBody>
            <ModalFooter>
              <Button type="button" onClick={() => setModal(!modal)}>
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
        <Modal
          toggle={() => setModalShipping(!modalShipping)}
          isOpen={modalShipping}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <ModalHeader toggle={() => setModalShipping(!modalShipping)}>
              Add/Remove Shipping
            </ModalHeader>
            <ModalBody>
              {selectedPackageData && (
                <Row className="mb-3">
                  <Col>
                    <span className="font-size-16 font-weight-bold">
                      {selectedPackageData.name}
                    </span>
                  </Col>
                </Row>
              )}
              {shippingData &&
                shippingData.map(item => (
                  <Row className="py-2">
                    <Col>
                      {item.country +
                        " (MYR " +
                        formatter.format(item.price.MYR) +
                        ")"}
                    </Col>
                    {selectedPackageData && (
                      <Col xs="auto">
                        {selectedPackageData.shipping.findIndex(
                          object => object.sid == item.id
                        ) < 0 ? (
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_add_remove_shipping("add", item.id)
                            }}
                          >
                            {"Add"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_add_remove_shipping("remove", item.id)
                            }}
                          >
                            {"Remove"}
                          </button>
                        )}
                      </Col>
                    )}
                  </Row>
                ))}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={() => setModalShipping(!modalShipping)}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
        <Modal
          toggle={() => setModalPrice(!modalPrice)}
          isOpen={modalPrice}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <ModalHeader toggle={() => setModalPrice(!modalPrice)}>
              Update{" "}
              {priceType && priceType == "original"
                ? " Original "
                : " Discount "}{" "}
              Price
            </ModalHeader>
            <ModalBody>
              {selectedPackageData && (
                <>
                  <Row className="mb-3">
                    <Col>
                      <span className="font-size-16 font-weight-bold">
                        {selectedPackageData.name}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col>
                      <span className="font-size-16 font-weight-bold">
                        {"Current price"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    {priceType &&
                      (priceType == "original"
                        ? Object.entries(
                            selectedPackageData.price_ori_list
                          ).map(([key, value]) => (
                            <Col>
                              <span className="font-weight-bold">
                                {key + " "}
                              </span>
                              <span>{formatter.format(value)}</span>
                            </Col>
                          ))
                        : Object.entries(
                            selectedPackageData.price_discount_list
                          ).map(([key, value]) => (
                            <Col>
                              <span className="font-weight-bold">
                                {key + " "}
                              </span>
                              <span>{formatter.format(value)}</span>
                            </Col>
                          )))}
                  </Row>
                </>
              )}
              <AvForm>
                <AvField
                  name="price"
                  value={selectedPrice}
                  className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                  placeholder="Price"
                  type="select"
                  onChange={e => {
                    setSelectedPrice(e.target.value)
                  }}
                >
                  {priceData &&
                    selectedPackageData &&
                    priceData.map((object, index) => (
                      <option value={object.id}>
                        {"(" +
                          object.description +
                          ") MYR" +
                          formatter.format(object.MYR)}
                      </option>
                    ))}
                </AvField>
              </AvForm>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                style={{ height: "40px" }}
                onClick={() => {
                  onclick_apply_price()
                }}
              >
                {"Apply"}
              </button>
              <Button type="button" onClick={() => setModalPrice(!modalPrice)}>
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(PackageListAdmin))

PackageListAdmin.propTypes = { user: PropTypes.object }
