import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  Tooltip,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  CardSubtitle,
  Badge,
  Spinner,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory, useLocation } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import ScrollContainer from "react-indiana-drag-scroll"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ShopCheckout = props => {
  const history = useHistory()
  const query = useQuery()
  const id = query.get("id")
  const selectedPackage = query.get("package")
  const selectedSize = query.get("size")
  const selectedColor = query.get("color")
  const preselectedCountry = query.get("country")

  const [selectedCountry, setselected_country] = useState(query.get("country"))
  const [selectedBillingCountry, setselected_billing_country] = useState(
    query.get("country")
  )
  const [currencyDisplay, setCurrency] = useState(null)

  const [deliveryOption, setDeliveryOption] = useState("completion")

  const updateDeliveryOption = e => {
    if (e.target.checked) {
      setDeliveryOption(e.target.value)
    }
  }

  const [updatedShippingAddress, setUpdatedShippingAddress] = useState(false)
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null)
  const [shippingAddress, setShippingAddress] = useState({
    first_name: "",
    last_name: "",
    contact: "",
    name: "",
    address1: "",
    address2: "",
    town: "",
    state: "",
    postcode: "",
  })
  const [sameBilling, setSameBilling] = useState(true)
  const [updateAddress, setUpdateAddress] = useState(false)
  const [saveAddress, setSaveAddress] = useState(false)
  const [defaultAddress, setdefaultAddress] = useState(false)
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null)
  const [billingAddress, setBillingAddress] = useState({
    first_name: "",
    last_name: "",
    contact: "",
    name: "",
    address1: "",
    address2: "",
    town: "",
    state: "",
    postcode: "",
  })

  const [subtotal, setSubtotal] = useState(0)
  const [shippingFee, setShippingFee] = useState(0)
  const [promo, setPromo] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setTotal(subtotal + shippingFee - promo)
  }, [subtotal, shippingFee, promo])

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   history.push("/dashboard")
    // }
    // setCurrency("MYR")
    //   switch (geoplugin_currencyCode()) {
    //     case "MYR":
    //       setCurrency("MYR")
    //       break
    //     case "SGD":
    //       setCurrency("SGD")
    //       break
    //     case "AUD":
    //       setCurrency("AUD")
    //       break
    //     case "IDR":
    //       setCurrency("IDR")
    //       break
    //     default:
    //       setCurrency("USD")
    //   }
    if (localStorage.getItem("hCurrency")) {
      setCurrency(localStorage.getItem("hCurrency"))
    }
  }, [])

  useEffect(() => {
    if (currencyDisplay) {
      setIsLoading(true)
      getProductData()
    }
  }, [currencyDisplay])

  const [isLoading, setIsLoading] = useState(false)
  const [productData, setProductData] = useState(null)
  const getProductData = async () => {
    const response = await fetch(
      url.HAPIVITY_GET_SHOP_PRODUCT_DETAILS +
        "?id=" +
        id +
        "&currency=" +
        currencyDisplay
    )
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      setProductData(postresponse.data)
      getAddressData()
      setSubtotal(
        postresponse.data.price_ori +
          postresponse.data.debug.package[selectedPackage].price_ori
      )
      // setShippingFee(postresponse.data.details.shipping[selectedCountry].price)
      setShippingFee(
        postresponse.data.debug.package[selectedPackage].shipping[
          selectedCountry
        ].price
      )
      if (postresponse.data.discount != null)
        setPromo(
          postresponse.data.price_ori +
            postresponse.data.debug.package[selectedPackage].price_ori -
            postresponse.data.price_discount -
            postresponse.data.debug.package[selectedPackage].price_discount
        )
    }
  }

  useEffect(() => {
    if (productData) {
      console.log(
        "debug",
        // productData.details.shipping[selectedCountry].express
        productData.debug.package[selectedPackage].shipping[selectedCountry]
          .express
      )
    }
  }, [productData])

  const [addressData, setAddressData] = useState(null)
  const getAddressData = async () => {
    const response = await fetch(
      url.HAPIVITY_GET_USER_ADDRESS_BOOK + "?id=" + user.id
    )
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      setAddressData(postresponse.data)
    }
  }

  useEffect(() => {
    if (addressData && productData) {
      if (
        addressData.filter(object => object.default_shipping == 1).length > 0
      ) {
        var index = addressData.findIndex(
          object => object.default_shipping == 1
        )
        setShippingAddress({
          first_name: addressData[index].first_name,
          last_name: addressData[index].last_name,
          contact: addressData[index].contact,
          name: addressData[index].name,
          address1: addressData[index].address1,
          address2: addressData[index].address2,
          town: addressData[index].town,
          state: addressData[index].state,
          postcode: addressData[index].postcode,
        })
        if (
          productData.details.shipping.findIndex(
            data => data.country == addressData[index].country
          ) >= 0
        )
          setselected_country(
            // productData.details.shipping.findIndex(
            //   data => data.country == addressData[index].country
            // )
            productData.debug.package[selectedPackage].shipping.findIndex(
              data => data.country == addressData[index].country
            )
          )
        // setselected_country(0)
        setSelectedShippingAddress(addressData[index].id)
        setdefaultAddress(addressData[index].default_shipping == 1)
        setUpdatedShippingAddress(false)
        setUpdateAddress(false)
        if (sameBilling) {
          setBillingAddress({
            first_name: addressData[index].first_name,
            last_name: addressData[index].last_name,
            contact: addressData[index].contact,
            name: addressData[index].name,
            address1: addressData[index].address1,
            address2: addressData[index].address2,
            town: addressData[index].town,
            state: addressData[index].state,
            postcode: addressData[index].postcode,
          })
          setSelectedBillingAddress(addressData[index].id)
          if (
            productData.debug.package[selectedPackage].shipping.findIndex(
              data => data.country == addressData[index].country
            ) >= 0
          )
            setselected_billing_country(
              productData.debug.package[selectedPackage].shipping.findIndex(
                data => data.country == addressData[index].country
              )
            )
        }
      }
      setIsLoading(false)
    }
  }, [addressData, productData])

  useEffect(() => {
    if (sameBilling) {
      setSelectedBillingAddress(selectedShippingAddress)
      setBillingAddress({
        ...shippingAddress,
      })
    }
  }, [sameBilling])

  const updateFieldShippingAddress = e => {
    setShippingAddress({
      ...shippingAddress,
      [e.target.name]: e.target.value,
    })
    setUpdatedShippingAddress(true)
    if (sameBilling)
      setBillingAddress({
        ...billingAddress,
        [e.target.name]: e.target.value,
      })
  }

  const updateFieldBillingAddress = e => {
    setBillingAddress({
      ...billingAddress,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    console.log("updated", billingAddress)
  }, [billingAddress])

  // useEffect(() => {
  //   getBraintreeSetting()
  // }, [])

  const [paymentLoading, setPaymentLoading] = useState(false)

  function handleValidSubmit(event, values) {
    console.log(values)
    setPaymentLoading(true)
    payServer()
  }

  const payServer = async () => {
    let formdata = new FormData()
    // formdata.append("nonce", "fake-valid-nonce")
    formdata.append("id", id)
    formdata.append("uid", user.id)
    formdata.append("package", productData.debug.package[selectedPackage].pid)
    formdata.append("medal", productData.debug.package[selectedPackage].medal)
    formdata.append("tshirt", productData.debug.package[selectedPackage].tshirt)
    if (productData.debug.package[selectedPackage].tshirt) {
      formdata.append("size", productData.debug.tshirt_size[selectedSize])
      formdata.append(
        "color",
        productData.debug.tshirt_color[selectedColor].name
      )
    }
    formdata.append("entitlement", deliveryOption)
    formdata.append(
      "country",
      // productData.debug.shipping[selectedCountry].country
      productData.debug.package[selectedPackage].shipping[selectedCountry]
        .country
    )
    formdata.append(
      "billing_country",
      productData.debug.package[selectedPackage].shipping[
        selectedBillingCountry
      ].country
    )
    formdata.append("country_id", selectedCountry)
    formdata.append("currency", currencyDisplay)
    formdata.append("price", total)
    formdata.append("subtotal", subtotal)
    formdata.append("delivery_price", shippingFee)
    if (promoCodeApplied) {
      formdata.append("promo", promo)
      formdata.append("promo_desc", codeApplied)
    } else {
      if (productData && productData.discount != null) {
        formdata.append("promo", promo)
        formdata.append("promo_desc", productData.discount)
      }
    }
    formdata.append("shipping_address", JSON.stringify(shippingAddress))
    formdata.append("billing_address", JSON.stringify(billingAddress))
    formdata.append("update_address", updateAddress)
    formdata.append("save_address", saveAddress)
    formdata.append("default_address", defaultAddress)
    if (selectedShippingAddress != null)
      formdata.append("address_id", selectedShippingAddress)

    const requestOptions = {
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      body: formdata,
    }

    for (var pair of formdata.entries()) {
      console.log(pair[0], pair[1])
    }
    // console.log(formdata.entries())

    const response = await fetch(url.HAPIVITY_POST_CREATE_ORDER, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      setPaymentLoading(false)
      history.replace("/store-payment?id=" + postresponse.data)
      // history.push("/shop-order?id=" + postresponse.data)
    }
  }

  const [promoCode, setPromoCode] = useState("")
  const [promoCodeApplied, setPromoCodeApplied] = useState(false)
  const [codeApplied, setCodeApplied] = useState("")
  const [promoCodeError, setPromoCodeError] = useState(null)

  const updatePromoCode = e => {
    setPromoCode(e.target.value)
  }

  const removeCoupon = () => {
    setPromoCodeApplied(false)
    setCodeApplied("")
    if (productData.discount != null)
      setPromo(
        productData.price_ori +
          productData.debug.package[selectedPackage].price_ori -
          productData.price_discount -
          productData.debug.package[selectedPackage].price_discount
      )
    else setPromo(0)
  }

  const applyCoupon = async () => {
    console.log("apply")
    let formdata = new FormData()
    // formdata.append("nonce", "fake-valid-nonce")
    formdata.append("sid", id)
    formdata.append("uid", user.id)
    formdata.append("package", productData.debug.package[selectedPackage].pid)
    formdata.append("currency", currencyDisplay)
    formdata.append("promo_code", promoCode)

    const requestOptions = {
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      body: formdata,
    }

    for (var pair of formdata.entries()) {
      console.log(pair[0], pair[1])
    }

    const response = await fetch(url.HAPIVITY_APPLY_PROMO, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200 && postresponse.success) {
      // console.log()
      // setSubtotal(postresponse.data.discounted_price)
      setPromo(postresponse.data.discount)
      setPromoCodeApplied(true)
      setPromoCodeError(null)
      setCodeApplied(promoCode)
    } else if (!postresponse.success) {
      setPromoCodeApplied(false)
      setPromoCodeError(postresponse.message)
    }
  }

  const [t_addressdropdown, sett_addressdropdown] = useState(false)
  const [t_addressbillingdropdown, sett_addressbillingdropdown] = useState(
    false
  )

  useEffect(() => {
    if (selectedCountry && productData)
      setShippingFee(
        productData.debug.package[selectedPackage].shipping[selectedCountry]
          .price
      )
    // setShippingFee(productData.details.shipping[selectedCountry].price)
  }, [selectedCountry])

  const [user, setUser] = useState(null)
  useEffect(() => {
    if (props.user) setUser(JSON.parse(props.user))
    else setUser(null)
  }, [props.user])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-0" style={{ backgroundColor: "#FBFBFB" }}>
        {!isLoading ? (
          <div className="container-fluid">
            <Row className="mt-4">
              <Col>
                <Alert
                  color="secondary"
                  role="alert"
                  className="border-radius-8 font-size-14 font-weight-semibold bg-F3F4F8 text-center align-middle"
                >
                  <i className="mdi mdi-shield-lock font-size-16 align-middle me-2" />
                  {
                    "We do not store any of your credit card details, all payments are processed by "
                  }
                  <a href="https://stripe.com/" target="_blank">
                    <img
                      src="https://hapivity.imgix.net/stripe.png?w=106"
                      width="106px"
                      height="24px"
                      border="0"
                    />
                  </a>
                </Alert>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={4} className="mb-4">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col>
                        <span className="font-size-16 font-weight-extrabold text-uppercase">
                          {"1. REVIEW YOUR ORDER"}
                        </span>
                      </Col>
                    </Row>
                    {productData && (
                      <Row>
                        <Col>
                          <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2 mb-0">
                            <CardBody>
                              <Row>
                                <Col xs={"auto"}>
                                  <CardImg
                                    src={
                                      productData.image +
                                      "?w=80&h=80&fit=clip&dpr=1&q=75&auto=format"
                                    }
                                    srcSet={`${
                                      productData.image +
                                      "?w=80&h=80&fit=clip&dpr=1&q=75&auto=format"
                                    } 1x, ${
                                      productData.image +
                                      "?w=80&h=80&fit=clip&dpr=2&q=37&auto=format"
                                    } 2x, ${
                                      productData.image +
                                      "?w=80&h=80&fit=clip&dpr=3&q=25&auto=format"
                                    } 3x`}
                                    alt="hero"
                                    style={{
                                      width: "80px",
                                      objectFit: "cover",
                                      aspectRatio: "1/1",
                                    }}
                                    className="border-radius-16"
                                  />
                                </Col>
                                <Col>
                                  <Row>
                                    <Col>
                                      <span className="font-weight-extrabold font-size-14 text-secondary">
                                        {productData.name}
                                      </span>
                                    </Col>
                                    <Col xs="auto"></Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <span className="font-weight-semibold font-size-14 text-secondary">
                                        {
                                          productData.debug.package[
                                            selectedPackage
                                          ].name
                                        }
                                      </span>
                                    </Col>
                                    <Col xs="auto"></Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col>
                                      {/* <li className="mb-1">
                                        <span className="font-size-14 font-weight-semibold text-secondary">
                                          {"Challenge Entry"}
                                        </span>
                                      </li> */}
                                      {productData.debug.package[
                                        selectedPackage
                                      ].entitlement.map(item => (
                                        <li className="mb-1">
                                          <span className="font-size-14 font-weight-semibold text-secondary">
                                            {item.name}{" "}
                                            {item.type == "tshirt" &&
                                              productData.debug.tshirt_color[
                                                selectedColor
                                              ].name +
                                                " " +
                                                productData.debug.tshirt_size[
                                                  selectedSize
                                                ]}
                                          </span>
                                        </li>
                                      ))}
                                      {/* {productData.debug.package[
                                        selectedPackage
                                      ].medal && (
                                        <li className="mb-1">
                                          <span className="font-size-14 font-weight-semibold text-secondary">
                                            {"Finisher Medal"}
                                          </span>
                                        </li>
                                      )} */}
                                      {/* {productData.debug.package[
                                        selectedPackage
                                      ].tshirt && (
                                        <li className="mb-1">
                                          <span className="font-size-14 font-weight-semibold text-secondary">
                                            {"Jersey " +
                                              productData.debug.tshirt_color[
                                                selectedColor
                                              ].name +
                                              " " +
                                              productData.debug.tshirt_size[
                                                selectedSize
                                              ]}
                                          </span>
                                        </li>
                                      )} */}
                                    </Col>
                                  </Row>

                                  <Row className="mb-3">
                                    <Col>
                                      {productData.discount != null ? (
                                        <span className="font-size-16 text-secondary font-weight-semibold opacity-60 align-middle me-3">
                                          <s>
                                            {productData.currency +
                                              " " +
                                              formatter.format(
                                                productData.price_ori +
                                                  productData.debug.package[
                                                    selectedPackage
                                                  ].price_ori
                                              )}
                                          </s>
                                        </span>
                                      ) : (
                                        <span className="font-size-20 text-secondary font-weight-extrabold align-middle me-3">
                                          {productData.currency +
                                            " " +
                                            formatter.format(
                                              productData.price_ori +
                                                productData.debug.package[
                                                  selectedPackage
                                                ].price_ori
                                            )}
                                        </span>
                                      )}
                                      {productData.discount && (
                                        <span className="font-size-20 text-secondary font-weight-extrabold align-middle">
                                          {productData.currency +
                                            " " +
                                            formatter.format(
                                              productData.price_discount +
                                                productData.debug.package[
                                                  selectedPackage
                                                ].price_discount
                                            )}
                                        </span>
                                      )}
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col>
                                      <span
                                        className="btn btn-link font-size-16 text-primary font-weight-semibold align-middle px-0"
                                        onClick={() => {
                                          history.goBack()
                                        }}
                                      >
                                        {"Edit"}
                                      </span>
                                    </Col>
                                    <Col xs="auto">
                                      <span
                                        className="btn btn-outline-light bg-white font-size-16 text-secondary font-weight-semibold align-middle border-radius-12 py-1"
                                        style={{ cursor: "default" }}
                                      >
                                        {"x1"}
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4} className="mb-4">
                <AvForm
                  id="address-form"
                  onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                >
                  <Card>
                    <CardBody>
                      <Row className="mb-4">
                        <Col>
                          <span className="font-size-16 font-weight-extrabold text-uppercase">
                            {"2. DELIVERY"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <span className="font-size-14 font-weight-bold text-uppercase">
                            {"DELIVERY OPTION"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col>
                          <div className="form-check mb-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="entitlement"
                              id="entitlementRadios1"
                              value="immediate"
                              onChange={updateDeliveryOption}
                              checked={deliveryOption == "immediate"}
                            />
                            <label
                              className="form-check-label font-size-14"
                              htmlFor="entitlementRadios1"
                            >
                              Receive my entitlements as soon as it is available
                              to ship
                            </label>
                          </div>
                          <div className="form-check mb-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="entitlement"
                              id="entitlementRadios2"
                              value="completion"
                              onChange={updateDeliveryOption}
                              checked={deliveryOption == "completion"}
                            />
                            <label
                              className="form-check-label font-size-14 text-secondary"
                              htmlFor="entitlementRadios2"
                            >
                              Receive my entitlements after Challenge completion
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <Card className="shop-detail-alert">
                            <CardText>
                              {productData &&
                                productData.details.shipping_info.map(
                                  (object, index) => (
                                    <li
                                      style={{ color: "#FF3D00" }}
                                      key={index}
                                    >
                                      <span
                                        className="font-size-13 font-weight-semibold align-middle"
                                        style={{ color: "#FF3D00" }}
                                      >
                                        {object.desc}
                                      </span>
                                    </li>
                                  )
                                )}
                              {/* 
                        <span className="font-size-13 font-weight-semibold align-middle"
                          style={{color: "#FF3D00"}}>
                          {
                            "You can join and start the Challenge immediately. Shipping of Medal will be from "
                          }
                          <strong>01 Aug, 2022</strong>
                          {" onwards."}
                        </span>
                      */}
                            </CardText>
                          </Card>
                        </Col>
                      </Row>
                      {/* <Row className="mb-3">
                        <Col>
                          <span className="font-size-14 font-weight-bold text-uppercase">
                            {"CONTACT INFO"}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <span className="font-weight-semibold font-size-12 text-secondary opacity-70">
                            <em>
                              {
                                "You will be asked to verify your address after your Challenge's completion. You can still change your delivery address."
                              }
                            </em>
                          </span>
                        </Col>
                      </Row> */}
                      <Row className="mb-3">
                        <Col>
                          <span className="font-size-14 font-weight-bold text-uppercase">
                            {"DELIVERY ADDRESS"}
                          </span>
                        </Col>
                        <Col xs="auto">
                          <ButtonDropdown
                            isOpen={t_addressdropdown}
                            toggle={e => {
                              e.preventDefault()
                              sett_addressdropdown(!t_addressdropdown)
                            }}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-link font-size-14 font-weight-extrabold text-secondary align-middle p-0 me-1"
                            >
                              <i
                                className={
                                  "bx bx-book-open font-size-16 align-middle me-1"
                                }
                              />
                              <span className="align-middle">
                                {"Address Book"}
                              </span>
                              <i
                                className={
                                  "bx bx-chevron-down font-size-16 align-middle"
                                }
                              />
                            </DropdownToggle>
                            <DropdownMenu>
                              {addressData &&
                                addressData.map((object, index) => (
                                  <DropdownItem
                                    onClick={() => {
                                      setShippingAddress({
                                        first_name: object.first_name,
                                        last_name: object.last_name,
                                        contact: object.contact,
                                        name: object.name,
                                        address1: object.address1,
                                        address2: object.address2,
                                        town: object.town,
                                        state: object.state,
                                        postcode: object.postcode,
                                      })
                                      setselected_country(
                                        // productData.details.shipping.findIndex(
                                        //   data => data.country == object.country
                                        // )
                                        productData.debug.package[
                                          selectedPackage
                                        ].shipping.findIndex(
                                          data => data.country == object.country
                                        )
                                      )
                                      setSelectedShippingAddress(object.id)
                                      setdefaultAddress(
                                        object.default_shipping == 1
                                      )
                                      setUpdatedShippingAddress(false)
                                      setUpdateAddress(false)
                                      if (sameBilling) {
                                        setBillingAddress({
                                          first_name: object.first_name,
                                          last_name: object.last_name,
                                          contact: object.contact,
                                          name: object.name,
                                          address1: object.address1,
                                          address2: object.address2,
                                          town: object.town,
                                          state: object.state,
                                          postcode: object.postcode,
                                        })
                                        setSelectedBillingAddress(object.id)
                                        setselected_billing_country(
                                          // productData.details.shipping.findIndex(
                                          //   data => data.country == object.country
                                          // )
                                          productData.debug.package[
                                            selectedPackage
                                          ].shipping.findIndex(
                                            data =>
                                              data.country == object.country
                                          )
                                        )
                                      }
                                    }}
                                  >
                                    {object.name}
                                  </DropdownItem>
                                ))}
                              {/* <DropdownItem>{"Home"}</DropdownItem>
                              <DropdownItem>{"Office"}</DropdownItem>
                              <DropdownItem>{"Mum"}</DropdownItem> */}
                            </DropdownMenu>
                          </ButtonDropdown>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <span className="font-weight-semibold font-size-12 text-secondary opacity-70">
                            <em>
                              {/* {
                                "You will be asked to verify your address after your Challenge's completion. You can still change your delivery address."
                              } */}
                              {
                                "Verify your address carefully. You cannot change your delivery address after order confirmed."
                              }
                            </em>
                          </span>
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col>
                          <AvField
                            name="name"
                            value={shippingAddress.name}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="Address name (e.g. home) *"
                            type="text"
                            // required
                            // disabled
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required",
                              },
                            }}
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="first_name"
                            value={shippingAddress.first_name}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="First Name *"
                            type="text"
                            // required
                            // disabled
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required",
                              },
                            }}
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="last_name"
                            value={shippingAddress.last_name}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="Last Name *"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required",
                              },
                            }}
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="contact"
                            value={shippingAddress.contact}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="Contact no. *"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required",
                              },
                            }}
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="address1"
                            value={shippingAddress.address1}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="Address 1 *"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required",
                              },
                            }}
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="address2"
                            value={shippingAddress.address2}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="Address 2"
                            type="text"
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="town"
                            value={shippingAddress.town}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="Suburb/town *"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required",
                              },
                            }}
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="state"
                            value={shippingAddress.state}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="State/territory *"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required",
                              },
                            }}
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="postcode"
                            value={shippingAddress.postcode}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="Postcode/ZIP Code *"
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "This field is required",
                              },
                            }}
                            onChange={updateFieldShippingAddress}
                          />
                          <AvField
                            name="country"
                            value={selectedCountry}
                            className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                            placeholder="Country *"
                            type="select"
                            // validate={{
                            //   required: {
                            //     value: true,
                            //     errorMessage: "This field is required",
                            //   },
                            // }}
                            onChange={e => {
                              setselected_country(e.target.value)
                            }}
                            defaultValue={selectedCountry}
                          >
                            {/* <option selected disabled value="0">
                              Country *
                            </option> */}
                            {/* {productData &&
                              productData.details.shipping.map(
                                (object, index) => (
                                  <option value={index}>
                                    {object.country}
                                  </option>
                                )
                              )} */}
                            {productData &&
                              productData.debug.package[
                                selectedPackage
                              ].shipping.map((object, index) => (
                                <option value={index}>{object.country}</option>
                              ))}
                            {/* <option value="au" >Australia</option>
                            <option value="id">Indonesia</option>
                            <option value="my">Malaysia</option>
                            <option value="sg">Singapore</option> */}
                          </AvField>
                          {/* <Row className="mb-3">
                            <Col>
                              <span className="font-size-14 font-weight-bold text-uppercase">
                                {"SELECT DELIVERY"}
                              </span>
                            </Col>
                          </Row> */}
                          <Row className="mb-3">
                            <Col>
                              <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2 mb-0">
                                <CardBody>
                                  <Row>
                                    <Col xs={"auto"} className="pe-0">
                                      <i className="mdi mdi-check-circle text-primary font-size-20 "></i>
                                    </Col>
                                    <Col>
                                      <Row className="mb-2">
                                        <Col>
                                          <span className="font-weight-extrabold font-size-14 text-secondary">
                                            {/* {productData &&
                                              productData.details.shipping[
                                                selectedCountry
                                              ].express} */}
                                            {productData &&
                                              productData.debug.package[
                                                selectedPackage
                                              ].shipping[selectedCountry]
                                                .express}
                                          </span>
                                        </Col>
                                        <Col xs="auto">
                                          <span className="font-size-14 text-secondary font-weight-bold">
                                            {productData &&
                                              productData.currency +
                                                " " +
                                                formatter.format(shippingFee)}
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <span className="font-weight-normal font-size-14 text-secondary">
                                            {/* {productData &&
                                              productData.details.shipping[
                                                selectedCountry
                                              ].duration} */}
                                            {productData &&
                                              productData.debug.package[
                                                selectedPackage
                                              ].shipping[selectedCountry]
                                                .duration}
                                            {/* {productData &&
                                              productData.details.shipping[
                                                selectedCountry
                                              ].tracking == "Tracking" &&
                                              ", " +
                                                productData.details.shipping[
                                                  selectedCountry
                                                ].tracking} */}
                                            {productData &&
                                              productData.debug.package[
                                                selectedPackage
                                              ].shipping[selectedCountry]
                                                .tracking == "Tracking" &&
                                              ", " +
                                                productData.debug.package[
                                                  selectedPackage
                                                ].shipping[selectedCountry]
                                                  .tracking}
                                          </span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          {updatedShippingAddress && !saveAddress && (
                            <Row className="justify-content-center mb-3">
                              <Col xs="auto">
                                <AvInput
                                  type="checkbox"
                                  name="updateAddress"
                                  style={{ width: "18px", height: "18px" }}
                                  value={updateAddress}
                                  onChange={e => {
                                    setUpdateAddress(e.target.checked)
                                    if (e.target.checked)
                                      setSaveAddress(!e.target.checked)
                                  }}
                                />
                              </Col>
                              <Col className="ps-0">
                                <span className="mb-0 font-size-14 font-weight-semibold text-secondary align-middle">
                                  {"Update the address to my address book"}
                                </span>
                              </Col>
                            </Row>
                          )}
                          {!updateAddress && (
                            <Row className="justify-content-center mb-3">
                              <Col xs="auto">
                                <AvInput
                                  type="checkbox"
                                  name="saveAddress"
                                  style={{ width: "18px", height: "18px" }}
                                  value={saveAddress}
                                  onChange={e => {
                                    setSaveAddress(e.target.checked)
                                    if (e.target.checked)
                                      setUpdateAddress(!e.target.checked)
                                  }}
                                />
                              </Col>
                              <Col className="ps-0">
                                <span className="mb-0 font-size-14 font-weight-semibold text-secondary align-middle">
                                  {"Save the new address to my address book"}
                                </span>
                              </Col>
                            </Row>
                          )}
                          <Row className="justify-content-center mb-3">
                            <Col xs="auto">
                              <AvInput
                                type="checkbox"
                                name="defaultAddress"
                                style={{ width: "18px", height: "18px" }}
                                value={defaultAddress}
                                onChange={e => {
                                  setdefaultAddress(e.target.checked)
                                }}
                              />
                            </Col>
                            <Col className="ps-0">
                              <span className="mb-0 font-size-14 font-weight-semibold text-secondary align-middle">
                                {"Set as default address"}
                              </span>
                            </Col>
                          </Row>
                          <Row className="justify-content-center">
                            <Col xs="auto">
                              <AvInput
                                type="checkbox"
                                name="billing"
                                style={{ width: "18px", height: "18px" }}
                                value={sameBilling}
                                onChange={e => {
                                  setSameBilling(e.target.checked)
                                  if (e.target.checked)
                                    setBillingAddress({ ...shippingAddress })
                                }}
                              />
                            </Col>
                            <Col className="ps-0">
                              <span className="mb-0 font-size-14 font-weight-semibold text-secondary align-middle">
                                {"Same billing address"}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {!sameBilling && (
                        <Row className="mb-3">
                          <Col>
                            <span className="font-size-14 font-weight-bold text-uppercase">
                              {"BILLING ADDRESS"}
                            </span>
                          </Col>
                          <Col xs="auto">
                            <ButtonDropdown
                              isOpen={t_addressbillingdropdown}
                              toggle={e => {
                                e.preventDefault()
                                sett_addressbillingdropdown(
                                  !t_addressbillingdropdown
                                )
                              }}
                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-link font-size-14 font-weight-extrabold text-secondary align-middle p-0 me-1"
                              >
                                <i
                                  className={
                                    "bx bx-book-open font-size-16 align-middle me-1"
                                  }
                                />
                                <span className="align-middle">
                                  {"Address Book"}
                                </span>
                                <i
                                  className={
                                    "bx bx-chevron-down font-size-16 align-middle"
                                  }
                                />
                              </DropdownToggle>
                              <DropdownMenu>
                                {addressData &&
                                  addressData.map((object, index) => (
                                    <DropdownItem
                                      onClick={() => {
                                        setBillingAddress({
                                          first_name: object.first_name,
                                          last_name: object.last_name,
                                          contact: object.contact,
                                          name: object.name,
                                          address1: object.address1,
                                          address2: object.address2,
                                          town: object.town,
                                          state: object.state,
                                          postcode: object.postcode,
                                        })
                                        setselected_billing_country(
                                          productData.debug.package[
                                            selectedPackage
                                          ].shipping.findIndex(
                                            data =>
                                              data.country == object.country
                                          )
                                        )
                                        setSelectedBillingAddress(object.id)
                                      }}
                                    >
                                      {object.name}
                                    </DropdownItem>
                                  ))}
                                {/* <DropdownItem>{"Home"}</DropdownItem>
                                <DropdownItem>{"Office"}</DropdownItem>
                                <DropdownItem>{"Mum"}</DropdownItem> */}
                              </DropdownMenu>
                            </ButtonDropdown>
                          </Col>
                        </Row>
                      )}
                      {!sameBilling && (
                        <Row className="mb-3">
                          <Col>
                            <AvField
                              name="name"
                              value={billingAddress.name}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="Address name (e.g. home) *"
                              type="text"
                              // required
                              // disabled
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="first_name"
                              value={billingAddress.first_name}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="First Name *"
                              type="text"
                              // required
                              // disabled
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="last_name"
                              value={billingAddress.last_name}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="Last Name *"
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="contact"
                              value={billingAddress.contact}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="Contact no. *"
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="address1"
                              value={billingAddress.address1}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="Address 1 *"
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="address2"
                              // value="any@email.com"
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="Address 2"
                              type="text"
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="town"
                              value={billingAddress.town}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="Suburb/town *"
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="state"
                              value={billingAddress.state}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="State/territory *"
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="postcode"
                              value={billingAddress.postcode}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="Postcode/ZIP Code *"
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={updateFieldBillingAddress}
                            />
                            <AvField
                              name="country"
                              value={selectedBillingCountry}
                              className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-16 border-width-2 height-56 button-outline-f3f4f8"
                              placeholder="Country *"
                              type="select"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "This field is required",
                                },
                              }}
                              onChange={e => {
                                setselected_billing_country(e.target.value)
                              }}
                            >
                              {productData &&
                                productData.debug.package[
                                  selectedPackage
                                ].shipping.map((object, index) => (
                                  <option value={index}>
                                    {object.country}
                                  </option>
                                ))}
                            </AvField>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </AvForm>
              </Col>
              <Col lg={4} className="mb-4">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col>
                        <span className="font-size-16 font-weight-extrabold text-uppercase">
                          {"3. Order Summary"}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {promoCodeError && (
                          <Alert
                            color="danger"
                            className="border-radius-16 border-width-2"
                            // style={{ borderRadius: "16px" }}
                          >
                            {promoCodeError}
                          </Alert>
                        )}
                        {promoCodeApplied && (
                          <Alert
                            color="success"
                            className="border-radius-16 border-width-2"
                            // style={{ borderRadius: "16px" }}
                          >
                            {"Promo code applied"}
                          </Alert>
                        )}
                        <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2">
                          <CardBody>
                            <Row>
                              <Col>
                                <Row className="pe-2">
                                  <Col>
                                    <input
                                      className="form-control pe-0 font-size-14 font-weight-semibold border-none"
                                      type="text"
                                      placeholder="Promo Code"
                                      onChange={updatePromoCode}
                                      disabled={promoCodeApplied}
                                    />
                                  </Col>
                                  <Col xs="auto">
                                    <button
                                      className="btn btn-primary font-weight-bold font-size-14 border-radius-16 align-middle px-3"
                                      style={{ height: "39px" }}
                                      onClick={() => {
                                        if (!promoCodeApplied) applyCoupon()
                                        else removeCoupon()
                                      }}
                                    >
                                      {promoCodeApplied ? "Remove" : "Apply"}
                                    </button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2">
                          <CardBody>
                            <Row>
                              <Col>
                                <Row className="mb-2">
                                  <Col>
                                    <span className="font-weight-bold font-size-14 text-secondary">
                                      {"Subtotal"}
                                    </span>
                                  </Col>
                                  <Col xs="auto">
                                    <span className="font-size-14 text-secondary font-weight-bold">
                                      {productData &&
                                        productData.currency +
                                          " " +
                                          formatter.format(subtotal)}
                                    </span>
                                  </Col>
                                </Row>
                                {/* <Row className="mb-2">
                                  <Col>
                                    <span className="font-weight-semibold font-size-13 text-secondary opacity-70">
                                      {"Duties & taxes included"}
                                    </span>
                                  </Col>
                                </Row> */}
                                <Row>
                                  <Col>
                                    <span className="font-weight-bold font-size-14 text-secondary">
                                      {/* {productData &&
                                        "Shipping to " +
                                          productData.details.shipping[
                                            selectedCountry
                                          ].country} */}
                                      {productData &&
                                        "Shipping to " +
                                          productData.debug.package[
                                            selectedPackage
                                          ].shipping[selectedCountry].country}
                                    </span>
                                  </Col>
                                  <Col xs="auto">
                                    <span className="font-size-14 text-secondary font-weight-bold">
                                      {productData &&
                                        productData.currency +
                                          " " +
                                          formatter.format(shippingFee)}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col>
                                    <span className="font-weight-semibold font-size-13 text-secondary opacity-70">
                                      {/* {productData &&
                                        productData.details.shipping[
                                          selectedCountry
                                        ].express} */}
                                      {productData &&
                                        productData.debug.package[
                                          selectedPackage
                                        ].shipping[selectedCountry].express}
                                      {" ( "}
                                      {/* {productData &&
                                        productData.details.shipping[
                                          selectedCountry
                                        ].duration} */}
                                      {productData &&
                                        productData.debug.package[
                                          selectedPackage
                                        ].shipping[selectedCountry].duration}
                                      {/* {productData &&
                                        productData.details.shipping[
                                          selectedCountry
                                        ].tracking == "Tracking" &&
                                        ", " +
                                          productData.details.shipping[
                                            selectedCountry
                                          ].tracking} */}
                                      {productData &&
                                        productData.debug.package[
                                          selectedPackage
                                        ].shipping[selectedCountry].tracking ==
                                          "Tracking" &&
                                        ", " +
                                          productData.debug.package[
                                            selectedPackage
                                          ].shipping[selectedCountry].tracking}
                                      {" )"}
                                    </span>
                                  </Col>
                                </Row>
                                {productData &&
                                  (productData.discount != null ||
                                    promoCodeApplied) && (
                                    <Row>
                                      <Col>
                                        <span className="font-weight-bold font-size-14 text-secondary">
                                          {"PROMO"}
                                        </span>
                                      </Col>
                                      <Col xs="auto">
                                        <span className="font-size-14 text-secondary font-weight-bold">
                                          {productData &&
                                            "-" +
                                              productData.currency +
                                              " " +
                                              formatter.format(promo)}
                                        </span>
                                      </Col>
                                    </Row>
                                  )}
                                {productData &&
                                  !promoCodeApplied &&
                                  productData.discount != null && (
                                    <Row className="mb-2">
                                      <Col>
                                        <span className="font-weight-semibold font-size-13 text-secondary opacity-70">
                                          {/* {"NEWLAUNCH -20%"} */}
                                          {productData.discount}
                                        </span>
                                      </Col>
                                    </Row>
                                  )}
                                {promoCodeApplied && (
                                  <Row className="mb-2">
                                    <Col>
                                      <span className="font-weight-semibold font-size-13 text-secondary opacity-70">
                                        {codeApplied}
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="border-radius-16 button-outline-f3f4f8 shadow-none border-width-2 bg-primary mb-0">
                          <CardBody>
                            <Row>
                              <Col>
                                <Row className="mb-3">
                                  <Col>
                                    <span className="font-weight-semibold font-size-16 text-white">
                                      {"Total"}
                                    </span>
                                  </Col>
                                  <Col xs="auto">
                                    <span className="font-size-20 text-white font-weight-extrabold">
                                      {productData &&
                                        productData.currency +
                                          " " +
                                          formatter.format(total)}
                                    </span>
                                  </Col>
                                </Row>
                                {/* <Row className="mb-3">
                                  <Col></Col>
                                  <Col xs="auto">
                                    <span className="font-weight-bold font-size-14 text-white opacity-70">
                                      <s>{"RP 299,231"}</s>
                                    </span>
                                  </Col>
                                </Row> */}
                                <Row className="px-2">
                                  <button
                                    className="btn bg-white text-primary btn-block font-weight-extrabold font-size-14 border-radius-16 align-middle "
                                    style={{ height: "56px" }}
                                    form="address-form"
                                    type="submit"
                                    onClick={() => {
                                      // pay()
                                      // setPaymentError(null)
                                    }}
                                    disabled={paymentLoading}
                                  >
                                    {paymentLoading ? (
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-1" />
                                    ) : (
                                      <i
                                        className={
                                          "bx bx-credit-card font-size-16 align-middle me-1"
                                        }
                                      />
                                    )}{" "}
                                    {paymentLoading
                                      ? "Placing your order"
                                      : "Place Order"}
                                  </button>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Row className="mt-3">
                          <Col className="justify-content-center">
                            <Row
                              className="justify-content-center py-1"
                              xs="auto"
                            >
                              <Col xs="auto" className="px-0">
                                <img
                                  src={asset.iconDeliveryGuarantee}
                                  srcSet={`${asset.iconDeliveryGuarantee} 300w, ${asset.iconDeliveryGuaranteex2} 768w, ${asset.iconDeliveryGuaranteex3} 1280w`}
                                  alt=""
                                  style={{
                                    width: "auto",
                                    height: "16px",
                                  }}
                                />
                              </Col>
                              <Col>
                                <span className="font-size-12 font-weight-semibold text-secondary">
                                  {"Delivery Guarantee"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="justify-content-center">
                            <Row
                              xs="auto"
                              className="justify-content-center py-1"
                            >
                              <Col xs="auto" className="px-0">
                                <img
                                  src={asset.iconCheckoutSecure}
                                  srcSet={`${asset.iconCheckoutSecure} 300w, ${asset.iconCheckoutSecurex2} 768w, ${asset.iconCheckoutSecurex3} 1280w`}
                                  alt=""
                                  style={{
                                    width: "auto",
                                    height: "16px",
                                  }}
                                />
                              </Col>
                              <Col>
                                <span className="font-size-12 font-weight-semibold text-secondary">
                                  {"Secure Payments"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="container-fluid">
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Spinner
                  className=""
                  color="primary"
                  style={{ width: "50px", height: "50px" }}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(ShopCheckout))

ShopCheckout.propTypes = { user: PropTypes.object }
