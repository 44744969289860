import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"

const Disclaimer = props => {
  useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		function gtag() { dataLayer.push(arguments); }
		gtag('js', new Date());

		gtag('event', 'page_view', { page_title: 'Web Disclaimer' })
	}, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-5">
        <Row className="justify-content-center">
          <Col lg={10}>
            <h1 className="mt-3">Disclaimer</h1>
            <p>Updated at 2022-06-01</p>

            <p>
              Hapivity hereby grants you access to hapivity.com (“the Website”)
              and invites you to purchase the services offered here.
            </p>

            <h1>Definitions and key terms</h1>
            <p>
              To help explain things as clearly as possible in this Disclaimer,
              every time any of these terms are referenced, are strictly defined
              as:
            </p>
            <ul>
              <li>
                Cookie: small amount of data generated by a website and saved by
                your web browser. It is used to identify your browser, provide
                analytics, remember information about you such as your language
                preference or login information.
              </li>
              <li>
                Company: when this policy mentions “Company,” “we,” “us,” or
                “our,” it refers to Beuniq Sdn Bhd, SB 05-06, Menara Paragon,
                Persiaran Bestari, Cyber 11, 63100 Cyberjaya, Selangor, Malaysia
                that is responsible for your information under this Disclaimer.
              </li>
              <li>
                Service: refers to the service provided by Hapivity as described
                in the relative terms (if available) and on this platform.
              </li>
              <li>
                Website: site, which can be accessed via this URL: hapivity.com
              </li>
              <li>
                You: a person or entity that is registered with Hapivity to use
                the Services.
              </li>
            </ul>

            <h1>Limited liability</h1>
            <p>
              Hapivity endeavours to update and/or supplement the content of the
              website/app on a regular basis. Despite our care and attention,
              content may be incomplete and/or incorrect.
            </p>
            <p>
              The materials offered on the website/app are offered without any
              form of guarantee or claim to their correctness. These materials
              can be changed at any time without prior notice from Hapivity.
            </p>
            <p>
              Particularly, all prices on the website/app are stated subject to
              typing and programming errors. No liability is assumed for the
              implications of such errors. No agreement is concluded on the
              basis of such errors.
            </p>
            <p>
              Hapivity shall not bear any liability for hyperlinks to websites
              or services of third parties included on the website/app. From our
              website/app, you can visit other websites by following hyperlinks
              to such external sites. While we strive to provide only quality
              links to useful and ethical websites, we have no control over the
              content and nature of these sites. These links to other websites
              do not imply a recommendation for all the content found on these
              sites. Site owners and content may change without notice and may
              occur before we have the opportunity to remove a link which may
              have gone ‘bad’.
            </p>
            <p>
              Please be also aware that when you leave our website/app, other
              sites may have different privacy policies and terms which are
              beyond our control. Please be sure to check the Privacy Policies
              of these sites as well as their "Terms of Service" before engaging
              in any business or uploading any information.
            </p>
            <h1>Links to Other Websites Disclaimer</h1>
            <p>
              This Disclaimer applies only to the Services. The Services may
              contain links to other websites not operated or controlled by
              Hapivity. We are not responsible for the content, accuracy or
              opinions expressed in such websites, and such websites are not
              investigated, monitored or checked for accuracy or completeness by
              us. Please remember that when you use a link to go from the
              Services to another website, our Privacy Policy is no longer in
              effect. Your browsing and interaction on any other website,
              including those that have a link on our platform, is subject to
              that website’s own rules and policies. Such third parties may use
              their own cookies or other methods to collect information about
              you. If You click on a third party link, You will be directed to
              that third party's site. We strongly advise You to review the
              Privacy Policy and Terms of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h1>Errors and Omissions Disclaimer</h1>
            <p>
              Hapivity is not responsible for any content, code or any other
              imprecision.
            </p>
            <p>Hapivity does not provide warranties or guarantees.</p>
            <p>
              In no event shall Hapivity be liable for any special, direct,
              indirect, consequential, or incidental damages or any damages
              whatsoever, whether in an action of contract, negligence or other
              tort, arising out of or in connection with the use of the Service
              or the contents of the Service. Hapivity reserves the right to
              make additions, deletions, or modifications to the contents on the
              Service at any time without prior notice.
            </p>

            <h1>General Disclaimer</h1>
            <p>
              The Hapivity Service and its contents are provided "as is" and "as
              available" without any warranty or representations of any kind,
              whether express or implied. Hapivity is a distributor and not a
              publisher of the content supplied by third parties; as such,
              Hapivity exercises no editorial control over such content and
              makes no warranty or representation as to the accuracy,
              reliability or currency of any information, content, service or
              merchandise provided through or accessible via the Hapivity
              Service. Without limiting the foregoing, Hapivity specifically
              disclaims all warranties and representations in any content
              transmitted on or in connection with the Hapivity Service or on
              sites that may appear as links on the Hapivity Service, or in the
              products provided as a part of, or otherwise in connection with,
              the Hapivity Service, including without limitation any warranties
              of merchantability, fitness for a particular purpose or
              non-infringement of third party rights. No oral advice or written
              information given by Hapivity or any of its affiliates, employees,
              officers, directors, agents, or the like will create a warranty.
              Price and availability information is subject to change without
              notice. Without limiting the foregoing, Hapivity does not warrant
              that the Hapivity Service will be uninterrupted, uncorrupted,
              timely, or error-free.
            </p>
            <h1>Copyright Disclaimer</h1>
            <p>
              All intellectual property rights concerning these materials are
              vested in Hapivity. Copying, distribution and any other use of
              these materials is not permitted without the written permission of
              Hapivity, except and only to the extent otherwise provided in
              regulations of mandatory law (such as the right to quote), unless
              otherwise stated for certain materials.
            </p>
            <h1>Fitness Disclosure</h1>
            <p>
              Please read this Fitness disclosure before applying any of the
              information on the website/app.
            </p>
            <p>
              We show information about Fitness and Nutritional advice. The
              information on this website/app such as text, graphics, images,
              and other materials created by Hapivity or obtained from
              licensors, and other materials contained on Hapivity
              (collectively, “content”) is intended solely for informational
              purposes and may not be used as a substitute for professional
              advice and/or information, as circumstances will vary from person
              to person. You should not act or rely upon this information
              without seeking professional advice. Do not attempt any of the
              suggested actions, solutions, remedies, or instructions found on
              this website/app without first consulting with a qualified
              professional. The materials are not intended to be nor do they
              constitute actionable professional advice. If you use any of the
              information that we provide on Hapivity is at your own risk.
            </p>
            <p>
              Regular exercise is not always without risk, even for healthy
              individuals. Certain types of exercise are riskier thank others
              and all exercise is risky for some individuals.
            </p>
            <p>
              It’s the same with diet. Some dietary recommendations are healthy
              for the majority of people but potentially dangerous to others.
            </p>
            <p>
              The exercises provided by Hapivity are for educational and
              entertainment purposes only, and is not to be interpreted as a
              recommendation for a specific treatment plan, product, or course
              of action. Exercise is not without risks, and this or any other
              exercise program may result in injury. They include but are not
              limited to: risk of injury, aggravation of a pre-existing
              condition, or adverse effect or over-exertion such as muscle
              strain, abnormal blood pressure, fainting, disorders of heartbeat,
              and very rare instances of heart attack. To reduce the risk of
              injury, before beginning this or any exercise program, please
              consult a healthcare provider for appropriate exercise
              prescription and safety precautions. The exercise instruction and
              advice presented are in no way intended as a substitute for
              medical consultation. Hapivity disclaims any liability from and in
              connection with this program. As with any exercise program, if at
              any point during your workout you begin to feel faint, dizzy, or
              have physical discomfort, you should stop immediately and consult
              a physician.
            </p>
            <h1>Your Consent</h1>
            <p>
              We've updated our Disclaimer to provide you with complete
              transparency into what is being set when you visit our site and
              how it's being used. By using our website/app, registering an
              account, or making a purchase, you hereby consent to our
              Disclaimer and agree to its terms.
            </p>

            <h1>Changes To Our Disclaimer</h1>
            <p>
              Should we update, amend or make any changes to this document so
              that they accurately reflect our Service and policies. Unless
              otherwise required by law, those changes will be prominently
              posted here. Then, if you continue to use the Service, you will be
              bound by the updated Disclaimer. If you do not want to agree to
              this or any updated Disclaimer, you can delete your account.
            </p>
            <h1>Contact Us</h1>
            <p>
              Don't hesitate to contact us if you have any questions regarding
              this Disclaimer.
            </p>
            <ul>
              <li>
                Via our Help Page:{" "}
                <a href="https://help.hapivity.com/">help.hapivity.com</a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Disclaimer
