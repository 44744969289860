import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  Tooltip,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  CardSubtitle,
  Badge,
  Spinner,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory, useLocation } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import ScrollContainer from "react-indiana-drag-scroll"

import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ShopPaymentResult = props => {
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState(null)

  const [currencyDisplay, setCurrency] = useState(null)
  const [isFailed, setIsFailed] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log(stripe)
      console.log(paymentIntent)
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!")
          history.replace("/store-order?id=" + id)
          // setPaymentLoading(true)
          // paymentSuccess()
          break
        case "processing":
          setMessage("Your payment is processing.")
          break
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.")
          setIsFailed(true)
          break
        default:
          setMessage("Something went wrong.")
          setIsFailed(true)
          break
      }
    })
  }, [stripe])

  const history = useHistory()
  const query = useQuery()
  const id = query.get("oid")

  useEffect(() => {
    if (localStorage.getItem("hCurrency")) {
      setCurrency(localStorage.getItem("hCurrency"))
    }
  }, [])

  useEffect(() => {
    if (currencyDisplay) {
      // setIsLoading(true)
      // getProductData()
    }
  }, [currencyDisplay])

  const [isLoading, setIsLoading] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)

  const paymentSuccess = async () => {
    let formdata = new FormData()
    // formdata.append("nonce", "fake-valid-nonce")
    formdata.append("id", id)

    const requestOptions = {
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_STRIPE_PAYMENT_SUCCESS,
      requestOptions
    )
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      // setPaymentLoading(false)
      history.replace("/store-order?id=" + id)
      // history.push("/shop-order?id=" + postresponse.data)
    }
  }

  const [user, setUser] = useState(null)
  useEffect(() => {
    if (props.user) setUser(JSON.parse(props.user))
    else setUser(null)
  }, [props.user])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-0" style={{ backgroundColor: "#FBFBFB" }}>
        {!isLoading ? (
          <div className="container-fluid">
            <Row className="mt-4">
              <Col>
                <Alert
                  color="secondary"
                  role="alert"
                  className="border-radius-8 font-size-14 font-weight-semibold bg-F3F4F8 text-center align-middle"
                >
                  <i className="mdi mdi-shield-lock font-size-16 align-middle me-2" />
                  {
                    "We do not store any of your credit card details, all payments are processed by "
                  }
                  <a href="https://stripe.com/" target="_blank">
                    <img
                      src="https://hapivity.imgix.net/stripe.png?w=106"
                      width="106px"
                      height="24px"
                      border="0"
                    />
                  </a>
                </Alert>
              </Col>
            </Row>
            {paymentLoading && (
              <Row className="justify-content-center mt-4">
                <Col xs="auto">
                  <Spinner
                    className=""
                    color="primary"
                    style={{ width: "50px", height: "50px" }}
                  />
                </Col>
              </Row>
            )}
            {isFailed && (
              <Row className="justify-content-center mt-4">
                <Col xs={"auto"}>
                  <Row>
                    <Col>
                      <span className="font-size-36 text-primary font-weight-black">
                        {"Payment failed"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className="font-size-16 text-secondary">
                        {
                          "The was an error processing your payment, please try again"
                        }
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-4 justify-content-center">
                    <Col>
                      <span className="font-size-20 font-weight-black text-secondary">
                        {"Need help?"}
                      </span>
                    </Col>
                  </Row>
                  <Row className="mt-4 justify-content-center d-flex">
                    <Col md={6} className="d-flex">
                      <Card
                        className="border-radius-16"
                        style={{ flexGrow: 1 }}
                      >
                        <CardImg
                          className="mx-auto mt-3"
                          src={asset.contactChat}
                          srcSet={`${asset.contactChat} 300w, ${asset.contactChatx2} 768w, ${asset.contactChatx3} 1280w`}
                          alt="hero"
                          style={{
                            height: "48px",
                            width: "48px",
                          }}
                        />
                        <CardBody>
                          <Row>
                            <span className="font-size-14 font-weight-bold text-black text-center">
                              {
                                "Chat with us using the live chat on our website"
                              }
                            </span>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} className="d-flex">
                      <Card
                        className="border-radius-16"
                        style={{ flexGrow: 1, cursor: "pointer" }}
                        onClick={() => {
                          window.open("https://beuniq.tawk.help/", "_blank")
                          return null
                        }}
                      >
                        <CardImg
                          className="mx-auto mt-3"
                          src={asset.contactHelp}
                          srcSet={`${asset.contactHelp} 300w, ${asset.contactHelpx2} 768w, ${asset.contactHelpx3} 1280w`}
                          alt="hero"
                          style={{
                            height: "48px",
                            width: "48px",
                          }}
                        />
                        <CardBody>
                          <Row className="justify-content-center">
                            <Col xs="auto">
                              <span className="font-size-14 font-weight-bold text-black text-center">
                                {"Visit our "}
                              </span>
                              <Link
                                to=""
                                className="font-size-14 font-weight-bold text-primary text-center"
                              >
                                {"Help Center"}
                              </Link>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="no-gutters px-3">
                    <button
                      className="btn btn-primary font-weight-extrabold font-size-16 border-radius-16 btn-block"
                      style={{ height: "56px" }}
                      onClick={() => {
                        history.push("/store-payment?id=" + id)
                      }}
                    >
                      {"Return to payment page"}
                    </button>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <div className="container-fluid">
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Spinner
                  className=""
                  color="primary"
                  style={{ width: "50px", height: "50px" }}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(ShopPaymentResult))

ShopPaymentResult.propTypes = {}
