import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  DropdownMenu,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import { MDBDataTable } from "mdbreact"
import { success } from "toastr"
import { AvForm, AvField } from "availity-reactstrap-validation"
import TitleBar from "./TitleBar"

const PriceListAdmin = props => {
  const history = useHistory()

  const [modal, setModal] = useState(false)

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    callOrderAPI()
  }, [])

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [priceData, setPriceData] = useState([])
  const [packageData, setPackageData] = useState([])

  const callOrderAPI = async () => {
    const response = await fetch(url.HAPIVITY_GET_ADMIN_PRICE_LIST)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      console.log("Admin price list", postresponse.data)
      setPriceData(postresponse.data)
      if (selectedPriceData) {
        if (
          postresponse.data.findIndex(
            item => item.id == selectedPriceData.id
          ) >= 0
        )
          setSelectedPriceData(
            postresponse.data.find(item => item.id == selectedPriceData.id)
          )
      }
      // setTableData(filterData(postresponse.data))
    }
  }

  const [selectedPriceData, setSelectedPriceData] = useState(null)
  const [actionType, setActionType] = useState(null)

  const onclick_edit_price = selectedPriceData => {
    setModal(true)
    setActionType("edit")
    setSelectedPriceData(selectedPriceData)
  }

  const onclick_add_price = () => {
    setModal(true)
    setActionType("add")
    setSelectedPriceData(null)
  }

  function handleValidSubmit(event, values) {
    onclick_apply_price(values)
  }

  const onclick_apply_price = async formValues => {
    console.log(formValues)

    let formdata = new FormData()
    formdata.append("AUD", formValues.aud)
    formdata.append("GBP", formValues.gbp)
    formdata.append("IDR", formValues.idr)
    formdata.append("MYR", formValues.myr)
    formdata.append("SGD", formValues.sgd)
    formdata.append("USD", formValues.usd)
    formdata.append("pid", selectedPriceData ? selectedPriceData.id : 0)
    formdata.append(
      "description",
      formValues.description ? formValues.description : ""
    )
    formdata.append("action", actionType)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_EDIT_PRICE, requestOptions)
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setModal(false)
      callOrderAPI()
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card className="bg-transparent">
              <CardBody>
                <TitleBar />
                <CardTitle className="font-size-20 font-weight-bold">
                  Price List
                </CardTitle>
                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block mb-3"
                  style={{ height: "40px" }}
                  onClick={() => {
                    // history.push("/admzzz-shop")
                    onclick_add_price()
                  }}
                >
                  {"Add a new Price"}
                </button>
                {priceData &&
                  priceData.map(item => (
                    <Card className="p-3">
                      <Row>
                        <Col>
                          <Row className="mb-2">
                            <Col>
                              <span className="font-size-16 font-weight-bold">
                                {item.description ? item.description : "-"}
                              </span>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col>
                              <span className="font-weight-bold">{"AUD "}</span>
                              <span className="">
                                {formatter.format(item.AUD)}
                              </span>
                            </Col>
                            <Col>
                              <span className="font-weight-bold">{"GBP "}</span>
                              <span className="">
                                {formatter.format(item.GBP)}
                              </span>
                            </Col>
                            <Col>
                              <span className="font-weight-bold">{"IDR "}</span>
                              <span className="">
                                {formatter.format(item.IDR)}
                              </span>
                            </Col>
                            <Col>
                              <span className="font-weight-bold">{"MYR "}</span>
                              <span className="">
                                {formatter.format(item.MYR)}
                              </span>
                            </Col>
                            <Col>
                              <span className="font-weight-bold">{"SGD "}</span>
                              <span className="">
                                {formatter.format(item.SGD)}
                              </span>
                            </Col>
                            <Col>
                              <span className="font-weight-bold">{"USD "}</span>
                              <span className="">
                                {formatter.format(item.USD)}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="auto">
                              <button
                                className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                style={{ height: "40px" }}
                                onClick={() => {
                                  // history.push("/admzzz-shop")
                                  onclick_edit_price(item)
                                }}
                              >
                                {"Edit Price"}
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <ModalHeader toggle={() => setModal(!modal)}>
                {actionType == "add" ? "Add" : "Edit"}
                {" Price"}
              </ModalHeader>
              <ModalBody>
                {
                  <>
                    <AvField
                      name="description"
                      label="Description"
                      value={
                        selectedPriceData ? selectedPriceData.description : null
                      }
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="Description"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="aud"
                      label="AUD"
                      value={selectedPriceData ? selectedPriceData.AUD : null}
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="AUD"
                      type="number"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="gbp"
                      label="GBP"
                      value={selectedPriceData ? selectedPriceData.GBP : null}
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="GBP"
                      type="number"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="idr"
                      label="IDR"
                      value={selectedPriceData ? selectedPriceData.IDR : null}
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="IDR"
                      type="number"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="myr"
                      label="MYR"
                      value={selectedPriceData ? selectedPriceData.MYR : null}
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="MYR"
                      type="number"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="sgd"
                      label="SGD"
                      value={selectedPriceData ? selectedPriceData.SGD : null}
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="SGD"
                      type="number"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="usd"
                      label="USD"
                      value={selectedPriceData ? selectedPriceData.USD : null}
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="USD"
                      type="number"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                  </>
                }
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-8 btn-block"
                  style={{ height: "38px" }}
                  type="submit"
                >
                  {"Save"}
                </button>
                <Button type="button" onClick={() => setModal(!modal)}>
                  Close
                </Button>
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(PriceListAdmin))

PriceListAdmin.propTypes = { user: PropTypes.object }
