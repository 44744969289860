import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container, Modal } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

import LoginModal from "pages/Hapivity/LoginModal"
import SignupModal from "pages/Hapivity/SignupModal"
import SignupVerificationModal from "pages/Hapivity/SignupVerificationModal"
import ForgetPasswordModal from "pages/Hapivity/ForgetPasswordModal"
import ResetPasswordModal from "pages/Hapivity/ResetPasswordModal"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
} from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
// import logo from "assets/images/logo.svg"

//hapivity asset
import { asset } from "helpers/asset_helper"

//Import config
import { facebook, google } from "../../config"

const Login = props => {
  const history = useHistory()

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     history.push("/dashboard")
  //   }
  // }, [])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    if (!loading) {
      props.loginUser(values, props.history)
      setLoading(true)
    }
  }

  const signIn = (res, type) => {
    const { socialLogin } = props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  useEffect(() => {
    console.log(props.error)
    if (loading) {
      setLoading(false)
    }
  }, [props.error])

  //modal login
  const [modal_login, setmodal_login] = useState(false)

  function tog_login() {
    setmodal_login(!modal_login)
    removeBodyCss()
  }

  //modal signup
  const [modal_signup, setmodal_signup] = useState(false)

  function tog_signup() {
    setmodal_signup(!modal_signup)
    removeBodyCss()
  }

  //modal forget password
  const [modal_forget_password, setmodal_forget_password] = useState(false)

  function tog_forget_password() {
    setmodal_forget_password(!modal_forget_password)
    removeBodyCss()
  }

  //modal reset password
  const [modal_reset_password, setmodal_reset_password] = useState(false)

  function tog_reset_password() {
    setmodal_reset_password(!modal_reset_password)
    removeBodyCss()
  }

  //modal email verificaton
  const [modal_signup_verification, setmodal_signup_verification] = useState(
    false
  )

  function tog_signup_verification() {
    setmodal_signup_verification(!modal_signup_verification)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Hapivity</title>
        </MetaTags>
        {/* <button
          className="btn btn-primary btn-block waves-effect waves-light"
          onClick={tog_login}
        >
          {"toggle login"}
        </button> */}
        {modal_login && (
          <LoginModal
            isOpen={modal_login}
            toggle={() => {
              tog_login()
            }}
            onSignup={() => {
              setmodal_login(false)
              tog_signup()
            }}
            onForgetPassword={() => {
              setmodal_login(false)
              tog_forget_password()
            }}
          />
        )}
        <ForgetPasswordModal
          isOpen={modal_forget_password}
          toggle={() => {
            tog_forget_password()
          }}
          onEmailSend={() => {
            setmodal_forget_password(false)
            tog_reset_password()
          }}
        />
        <ResetPasswordModal
          isOpen={modal_reset_password}
          toggle={() => {
            tog_reset_password()
          }}
          onLogin={() => {
            setmodal_reset_password(false)
            tog_login()
          }}
        />
        <SignupModal
          isOpen={modal_signup}
          toggle={() => {
            tog_signup()
          }}
          onLogin={() => {
            setmodal_signup(false)
            tog_login()
          }}
          onVerify={() => {
            setmodal_signup(false)
            tog_signup_verification()
          }}
        />
        <SignupVerificationModal
          isOpen={modal_signup_verification}
          toggle={() => {
            tog_signup_verification()
          }}
          onLogin={() => {
            setmodal_signup_verification(false)
            tog_login()
          }}
          onSignup={() => {
            setmodal_signup_verification(false)
            tog_signup()
          }}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { registerUser, loginUser, apiError, socialLogin })(
    Login
  )
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
