import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"

const FBDataDeletion = props => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content px-5">
        <Row className="justify-content-center">
          <Col lg={10}>
            <h1 className="mt-3">DisclaimerData Deletion</h1>
            <p>Updated at 2022-06-01</p>

            <h1>Facebook Data Deletion Instructions URL</h1>
            <p>
              Hapivity does not save your Facebook personal data on its server.
            </p>
            <p>
              However, according to Facebook policy, we have to provide User
              Data Deletion Callback URL or Data Deletion Instructions URL. If
              you want to delete your data from Hapivity App, you can remove
              your information by following these steps:
            </p>
            <ol>
              <li>
                Go to your Facebook Account’s Setting & Privacy. Click
                “Settings”
              </li>
              <li>
                Look for “Apps and Websites” and you will see all of the apps
                and websites you linked with your Facebook.
              </li>
              <li>Search and Click “Hapivity” in the search bar.</li>
              <li>
                Service: refers to the service provided by Hapivity as described
                in the relative terms (if available) and on this platform.{" "}
              </li>
              <li>Scroll and click “Remove”.</li>
              <li>
                Congratulations, you have successfully removed your app
                activities and data from the Hapivity App
              </li>
            </ol>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default FBDataDeletion
