import PropTypes, { object } from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  DropdownMenu,
  Input,
} from "reactstrap"

// Redux
import { batch, connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import { MDBDataTable } from "mdbreact"
import TitleBar from "./TitleBar"

const BatchControlListAdmin = props => {
  const history = useHistory()

  const [tab, setTab] = useState("list")

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState("")

  useEffect(() => {
    callOrderAPI()
  }, [])

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [tableData, setTableData] = useState([])
  const [tableDataRaw, setTableDataRaw] = useState([])

  const callOrderAPI = async () => {
    const response = await fetch(url.HAPIVITY_GET_NEW_BATCH_ORDER)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setTableDataRaw(postresponse.data)
      // setTableData(filterData(postresponse.data))
    }

    const responseBatch = await fetch(url.HAPIVITY_GET_BATCH_LIST)

    const postresponseBatch = await responseBatch.json()

    setModalData(postresponseBatch.data)
  }

  const [selectedOID, setSelectedOID] = useState([])
  const [batchType, setBatchType] = useState("new")
  const [batchId, setBatchId] = useState("")

  const addToSelected = oid => {
    if (selectedOID.findIndex(object => object == oid) < 0) {
      setSelectedOID([...selectedOID, oid])
      setTableData(filterData(tableDataRaw))
    } else {
      setSelectedOID(selectedOID.filter(object => object != oid))
    }
  }

  useEffect(() => {
    setTableData(filterData(tableDataRaw))
  }, [tableDataRaw, selectedOID])

  function handleValidSubmit(event, values) {
    onclick_assign_batch(values)
  }

  const onclick_assign_batch = async formValues => {
    let formdata = new FormData()
    formdata.append("type", formValues.batch_type)
    formdata.append("name", formValues.batch_name)
    formdata.append("oid", JSON.stringify(selectedOID))
    if (formValues.batch_type == "existing") formdata.append("bid", batchId)
    // formdata.append("oid", 471)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_ASSIGN_BATCH, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (postresponse.status == 200) {
      setModal(false)
      callOrderAPI()
      setSelectedOID([])
    }
  }

  const exportCsv = async bid => {
    window.open(url.HAPIVITY_EXPORT_BATCH_CSV + "?bid=" + bid, "_blank")
  }

  const [selectedCsv, setSelectedCsv] = useState()
  const [isCsvPicked, setIsCsvPicked] = useState(false)
  const changeCSVHandler = e => {
    console.log("change handler", e.target.files[0])
    setSelectedCsv(e.target.files[0])
    setIsCsvPicked(true)

    var reader = new FileReader()
    // reader.onload = function (e) {
    //   document.getElementById("imgPreview").src = e.target.result
    // }
    reader.readAsDataURL(e.target.files[0])
  }

  useEffect(() => {
    if (isCsvPicked) {
      uploadCsv()
    }
  }, [selectedCsv])

  const uploadCsv = async () => {
    let formdata = new FormData()
    if (isCsvPicked) {
      formdata.append("file", selectedCsv, selectedCsv.name)
    }

    const requestOptions = {
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_READ_STRIPE_CSV, requestOptions)
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      // callOrderAPI()
    }
  }

  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [receiptId, setReceiptId] = useState("")
  const changeHandler = e => {
    console.log("change handler", e.target.files[0])
    console.log("change handler", e.target.id)
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true)
    setReceiptId(e.target.id)

    var reader = new FileReader()
    // reader.onload = function (e) {
    //   document.getElementById("imgPreview").src = e.target.result
    // }
    reader.readAsDataURL(e.target.files[0])
  }

  useEffect(() => {
    if (receiptId != "") {
      uploadReceipt(receiptId)
    }
  }, [receiptId, selectedFile])

  function handleClick(e, handleId) {
    document.getElementById(handleId).click()
  }

  const uploadReceipt = async bid => {
    let formdata = new FormData()
    formdata.append("bid", bid)
    if (isFilePicked) {
      formdata.append("file", selectedFile, selectedFile.name)
    }

    const requestOptions = {
      method: "POST",
      // headers: { "content-type": "multipart/form-data" },
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_UPDATE_BATCH_RECEIPT,
      requestOptions
    )
    const postresponse = await response.json()

    console.log(postresponse)

    if (response.status == 200) {
      callOrderAPI()
    }
  }

  const filterData = data => {
    let rows = []
    data.forEach(item =>
      rows.push({
        order_id: item.id,
        order_date: item.created_at.split(" ")[0],
        product_name: item.name.split(",")[0],
        package: item.package_name.split(":")[0],
        completion_status: (
          (item.distance_completed / item.total_distance) *
          100
        ).toFixed(2),
        entitlement: item.entitlement,
        total: item.currency + " " + item.amount.toFixed(2),
        name: item.first_name + " " + item.last_name,
        shipping_status: item.status,
        tracking_number: item.tracking_number,
        country: item.country,
        details: (
          <button
            className="btn btn-primary"
            onClick={() => {
              addToSelected(item.id)
            }}
          >
            {selectedOID.findIndex(object => object == item.id) < 0
              ? "Add"
              : "Selected"}
          </button>
        ),
        // invoice: (
        //   <Link>
        //     <button className="btn btn-primary">{"Print"}</button>
        //   </Link>
        // ),
        // refund: (
        //   <Link>
        //     <button className="btn btn-danger">{"Refund"}</button>
        //   </Link>
        // ),
      })
    )
    return rows
  }

  const data = {
    columns: [
      {
        label: "OID",
        field: "order_id",
        sort: "asc",
      },
      {
        label: "Date",
        field: "order_date",
      },
      {
        label: "Name",
        field: "product_name",
      },
      {
        label: "Package",
        field: "package",
      },
      {
        label: "Completion",
        field: "completion_status",
      },
      {
        label: "Entitlement",
        field: "entitlement",
      },
      {
        label: "Total",
        field: "total",
      },
      {
        label: "Name",
        field: "name",
      },
      {
        label: "Country",
        field: "country",
      },
      {
        label: "Shipping",
        field: "shipping_status",
      },
      {
        label: "Details",
        field: "details",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        {tab == "list" ? (
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <TitleBar />
                  <Row>
                    <Col>
                      <CardTitle className="font-weight-bold font-size-20">
                        Batch List
                      </CardTitle>
                    </Col>
                    <Col xs="auto">
                      <button
                        className={
                          "btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                        }
                        style={{ height: "40px" }}
                        onClick={e => {
                          e.preventDefault()
                          // setTab("add")
                          handleClick(e, "stripe_upload")
                        }}
                      >
                        {"Stripe upload"}
                      </button>
                      <Input
                        type="file"
                        className="form-control"
                        id="stripe_upload"
                        accept=".csv"
                        // aria-describedby="inputGroupFileAddon04"
                        // aria-label="Upload"
                        onChange={changeCSVHandler}
                        hidden
                      />
                    </Col>
                    <Col xs="auto">
                      <button
                        className={
                          "btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                        }
                        style={{ height: "40px" }}
                        onClick={() => {
                          setTab("add")
                        }}
                      >
                        {"Manage Batch"}
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    {modalData ? (
                      modalData.length > 0 ? (
                        modalData.map((item, index) => (
                          <Col key={index} md={4}>
                            <Card className="p-3">
                              <CardBody>
                                <CardTitle className="font-weight-bold font-size-16">
                                  {item.name}
                                </CardTitle>
                                <CardSubtitle className="font-weight-bold font-size-14 mb-3">
                                  {item.status}
                                </CardSubtitle>
                                <Row>
                                  {item.status == "new" ? (
                                    <Col xs="auto">
                                      <button
                                        className={
                                          "btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                        }
                                        style={{ height: "40px" }}
                                        onClick={e => {
                                          e.preventDefault()
                                          handleClick(e, item.id)
                                        }}
                                      >
                                        {"Upload Receipt"}
                                      </button>
                                    </Col>
                                  ) : (
                                    <Col xs="auto">
                                      <button
                                        className={
                                          "btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                        }
                                        style={{ height: "40px" }}
                                        onClick={() => {
                                          window.open(item.receipt, "_blank")
                                        }}
                                      >
                                        {"Download Receipt"}
                                      </button>
                                    </Col>
                                  )}
                                  <Col xs="auto">
                                    <button
                                      className={
                                        "btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                      }
                                      style={{ height: "40px" }}
                                      onClick={() => {
                                        exportCsv(item.id)
                                      }}
                                    >
                                      {"Csv"}
                                    </button>
                                    <Input
                                      type="file"
                                      className="form-control"
                                      id={item.id}
                                      accept="image/*,application/pdf"
                                      // aria-describedby="inputGroupFileAddon04"
                                      // aria-label="Upload"
                                      onChange={changeHandler}
                                      hidden
                                    />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        ))
                      ) : (
                        <Col md={4}>
                          <Card className="p-3">
                            <CardBody>
                              <CardTitle className="font-weight-bold font-size-16">
                                {"No Batch Yet"}
                              </CardTitle>
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    ) : null}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <TitleBar />
                  <Row>
                    <Col>
                      <CardTitle className="font-weight-bold font-size-20">
                        Add Batch
                      </CardTitle>
                    </Col>
                    <Col xs="auto">
                      <button
                        className={
                          "btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                        }
                        style={{ height: "40px" }}
                        onClick={() => {
                          setTab("list")
                        }}
                      >
                        {"Batch List"}
                      </button>
                    </Col>
                    <Col xs="auto">
                      <button
                        className={
                          "btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                        }
                        style={{ height: "40px" }}
                        onClick={() => {
                          setModal(true)
                        }}
                        disabled={selectedOID.length < 1}
                      >
                        {"Add to batch"}
                      </button>
                    </Col>
                  </Row>

                  <MDBDataTable
                    responsive
                    bordered
                    hover
                    noBottomColumns
                    data={data}
                    paging={false}
                    multipleCheckboxes
                    checkbox
                    bodyCheckboxID="id1"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <ModalHeader toggle={() => setModal(!modal)}>
                Add to batch
              </ModalHeader>
              <ModalBody>
                <AvField
                  name="batch_type"
                  value={batchType}
                  label="Batch type"
                  className="form-control font-weight-semibold font-size-14 mb-3 text-secondary border-radius-8 border-width-2"
                  placeholder="Batch type"
                  type="select"
                  onChange={e => {
                    setBatchType(e.target.value)
                  }}
                >
                  <option value={"new"} defaultValue>
                    New
                  </option>
                  <option value={"existing"}>Existing</option>
                </AvField>
                {batchType == "new" ? (
                  <AvField
                    name="batch_name"
                    label="Batch name"
                    // value={
                    //   selectedShippingData ? selectedShippingData.country : null
                    // }
                    className="form-control font-weight-semibold font-size-14 mb-3"
                    placeholder="Batch name"
                    type="text"
                    style={{
                      // border: "2px solid",
                      // borderColor: "#F3F4F8",
                      borderWidth: "2px",
                      borderRadius: "8px",
                      height: "40px",
                    }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Required",
                      },
                    }}
                  />
                ) : null}
                {batchType == "existing" ? (
                  <AvField
                    name="Batch"
                    label="Select a batch"
                    // value={selectedPrice}
                    className="form-control font-weight-semibold font-size-14 mb-3 border-width-2"
                    placeholder="Batch"
                    type="select"
                    onChange={e => {
                      setBatchId(e.target.value)
                    }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Required",
                      },
                    }}
                  >
                    {modalData
                      ? modalData.map((object, index) => (
                          <option key={index} value={object.id}>
                            {object.name}
                          </option>
                        ))
                      : null}
                  </AvField>
                ) : null}
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-8 btn-block"
                  style={{ height: "38px" }}
                  type="submit"
                >
                  {"Save"}
                </button>
                <Button type="button" onClick={() => setModal(!modal)}>
                  Close
                </Button>
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(BatchControlListAdmin))

BatchControlListAdmin.propTypes = { user: PropTypes.object }
