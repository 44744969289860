import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState, useRef } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroup,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import { MDBDataTable } from "mdbreact"
import TitleBar from "./TitleBar"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import moment from "moment"

const OrderListAdmin = props => {
  const history = useHistory()
  const refDate = useRef(null)

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState("")

  useEffect(() => {
    callOrderAPI()
  }, [])

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [tableData, setTableData] = useState([])
  const [countrySelected, setCountrySelected] = useState("")
  const [statusSelected, setStatusSelected] = useState("")
  const [entitlementSelected, setEntitlementSelected] = useState("")
  const [packageSelected, setPackageSelected] = useState("")
  const [packageSelectedDisplay, setPackageSelectedDisplay] = useState("")
  const [challengeSelected, setChallengeSelected] = useState("")
  const [challengeSelectedDisplay, setChallengeSelectedDisplay] = useState("")
  const [completionSelected, setCompletionSelected] = useState("")
  const [dateStartSelected, setDateStartSelected] = useState(null)
  const [dateEndSelected, setDateEndSelected] = useState(null)
  const [dateSelected, setDateSelected] = useState("")
  const [batchSelected, setBatchSelected] = useState("")
  const [batchSelectedDisplay, setBatchSelectedDisplay] = useState("")

  const [countryDropdown, setCountryDropdown] = useState(false)
  const [statusDropdown, setStatusDropdown] = useState(false)
  const [entitlementDropdown, setEntitlementDropdown] = useState(false)
  const [packageDropdown, setPackageDropdown] = useState(false)
  const [challengeDropdown, setChallengeDropdown] = useState(false)
  const [completionDropdown, setCompletionDropdown] = useState(false)
  const [dateDropdown, setDateDropdown] = useState(false)
  const [batchDropdown, setBatchDropdown] = useState(false)

  const [batchData, setBatchData] = useState([])

  useEffect(() => {
    callOrderAPI()
  }, [
    countrySelected,
    statusSelected,
    entitlementSelected,
    packageSelected,
    challengeSelected,
    completionSelected,
    dateStartSelected,
    dateEndSelected,
    batchSelected,
  ])

  const callOrderAPI = async () => {
    let formdata = new FormData()
    formdata.append("country", countrySelected)
    if (statusSelected != "") formdata.append("status", statusSelected)
    if (entitlementSelected != "")
      formdata.append("entitlement", entitlementSelected)
    if (packageSelected != "") formdata.append("package", packageSelected)
    if (challengeSelected != "") formdata.append("challenge", challengeSelected)
    if (completionSelected != "") {
      formdata.append("completion", completionSelected)
      if (completionSelected != "100")
        formdata.append("completion_max", parseInt(completionSelected) + 10)
    }
    if (dateStartSelected)
      formdata.append(
        "date_start",
        moment(dateStartSelected).format("YYYY-MM-DD")
      )
    if (dateEndSelected)
      formdata.append("date_end", moment(dateEndSelected).format("YYYY-MM-DD"))
    if (batchSelected != "") formdata.append("batch", batchSelected)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_GET_ORDER_LIST, requestOptions)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setTableData(filterData(postresponse.data))
    }

    const responseBatch = await fetch(url.HAPIVITY_GET_BATCH_LIST)

    const postresponseBatch = await responseBatch.json()

    setBatchData(postresponseBatch.data)
  }

  const filterData = data => {
    let rows = []
    data.forEach(item =>
      rows.push({
        order_id: item.id,
        order_date: item.created_at.split(" ")[0],
        product: (
          <>
            <Row>
              <Col>{item.name.split(",")[0]}</Col>
            </Row>
            <Row>
              <Col>{item.package_name.split(":")[0]}</Col>
            </Row>
            {item.size && (
              <Row>
                <Col>{item.size}</Col>
              </Row>
            )}
            {item.color && (
              <Row>
                <Col>{item.color}</Col>
              </Row>
            )}
          </>
        ),
        completion_status: (
          (item.distance_completed / item.total_distance) *
          100
        ).toFixed(2),
        entitlement: item.entitlement,
        total: (
          <>
            <Row>
              <Col>{item.currency + " " + item.amount.toFixed(2)}</Col>
            </Row>
            <Row>
              <Col>{item.payment_status}</Col>
            </Row>
          </>
        ),
        name: item.first_name + " " + item.last_name,
        contact: (
          <>
            <Row>
              <Col>{item.contact}</Col>
            </Row>
            <Row>
              <Col>{item.email}</Col>
            </Row>
          </>
        ),
        delivery_detail: orderAddress(item),
        shipping_status: item.status,
        tracking_number: item.tracking_number,
        details: (
          <button
            className="btn btn-primary"
            onClick={() => {
              setModal(!modal)
              setModalData(item)
            }}
          >
            {"Details"}
          </button>
        ),
        // invoice: (
        //   <Link>
        //     <button className="btn btn-primary">{"Print"}</button>
        //   </Link>
        // ),
        // refund: (
        //   <Link>
        //     <button className="btn btn-danger">{"Refund"}</button>
        //   </Link>
        // ),
      })
    )
    return rows
  }

  const orderAddress = data => {
    let address = ""
    address += data.address1 + ", "
    if (data.address2) address += data.address2 + ", "

    address +=
      data.town + ", " + data.state + ", " + data.postcode + ", " + data.country

    return (
      <>
        <Row>
          <Col>{data.address1}</Col>
        </Row>
        {data.address2 && (
          <Row>
            <Col>{data.address2}</Col>
          </Row>
        )}
        <Row>
          <Col>{data.town + ", " + data.state}</Col>
        </Row>
        <Row>
          <Col>{data.postcode + " " + data.country}</Col>
        </Row>
      </>
    )
  }

  // const orderAddress = data => {
  //   let address = ""
  //   address += data.address1 + ", "
  //   if (data.address2) address += data.address2 + ", "

  //   address +=
  //     data.town + ", " + data.state + ", " + data.postcode + ", " + data.country

  //   return address
  // }

  const data = {
    columns: [
      {
        label: "OID",
        field: "order_id",
        sort: "asc",
      },
      {
        label: "Date",
        field: "order_date",
      },
      {
        label: "Product",
        field: "product",
      },
      {
        label: "Completion",
        field: "completion_status",
      },
      {
        label: "Entitlement",
        field: "entitlement",
      },
      {
        label: "Total",
        field: "total",
      },
      {
        label: "Name",
        field: "name",
      },
      {
        label: "Contact",
        field: "contact",
      },
      {
        label: "Shipping Address",
        field: "delivery_detail",
      },
      {
        label: "Shipping",
        field: "shipping_status",
      },
      {
        label: "Details",
        field: "details",
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <TitleBar />
                <Row className="mb-3">
                  <Col xs="auto">
                    <Dropdown
                      isOpen={countryDropdown}
                      toggle={() => setCountryDropdown(!countryDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Country"} <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setCountrySelected("")
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCountrySelected("Malaysia")
                          }}
                        >
                          {"Malaysia"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCountrySelected("Singapore")
                          }}
                        >
                          {"Singapore"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCountrySelected("United Kingdom")
                          }}
                        >
                          {"United Kingdom"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCountrySelected("United States")
                          }}
                        >
                          {"United States"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCountrySelected("Australia")
                          }}
                        >
                          {"Australia"}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Dropdown
                      isOpen={statusDropdown}
                      toggle={() => setStatusDropdown(!statusDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Filter : " + statusSelected}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setStatusSelected("")
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setStatusSelected("Not Shipped")
                          }}
                        >
                          {"Not Shipped"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setStatusSelected("Shipped")
                          }}
                        >
                          {"Shipped"}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Dropdown
                      isOpen={entitlementDropdown}
                      toggle={() =>
                        setEntitlementDropdown(!entitlementDropdown)
                      }
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Entitlement : " + entitlementSelected}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setEntitlementSelected("")
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setEntitlementSelected("immediate")
                          }}
                        >
                          {"Immediate"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setEntitlementSelected("completion")
                          }}
                        >
                          {"Completion"}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Dropdown
                      isOpen={packageDropdown}
                      toggle={() => setPackageDropdown(!packageDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Package : " + packageSelectedDisplay}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setPackageSelected("")
                            setPackageSelectedDisplay("")
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setPackageSelected("1")
                            setPackageSelectedDisplay("Package A")
                          }}
                        >
                          {"Package A"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setPackageSelected("2")
                            setPackageSelectedDisplay("Package B")
                          }}
                        >
                          {"Package B"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setPackageSelected("3")
                            setPackageSelectedDisplay("Package C")
                          }}
                        >
                          {"Package C"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setPackageSelected("4")
                            setPackageSelectedDisplay("Package D")
                          }}
                        >
                          {"Package D"}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Dropdown
                      isOpen={challengeDropdown}
                      toggle={() => setChallengeDropdown(!challengeDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Challenge : " + challengeSelectedDisplay}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setChallengeSelected("")
                            setChallengeSelectedDisplay("")
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setChallengeSelected("1")
                            setChallengeSelectedDisplay("Deadwood")
                          }}
                        >
                          {"Deadwood"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setChallengeSelected("3")
                            setChallengeSelectedDisplay("Taranaki")
                          }}
                        >
                          {"Taranaki"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setChallengeSelected("4")
                            setChallengeSelectedDisplay("Zombie")
                          }}
                        >
                          {"Zombie"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setChallengeSelected("5")
                            setChallengeSelectedDisplay("Seoraksan")
                          }}
                        >
                          {"Seoraksan"}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Dropdown
                      isOpen={completionDropdown}
                      toggle={() => setCompletionDropdown(!completionDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Completion : " + completionSelected}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("")
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("0")
                          }}
                        >
                          {"0%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("10")
                          }}
                        >
                          {"10%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("20")
                          }}
                        >
                          {"20%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("30")
                          }}
                        >
                          {"30%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("40")
                          }}
                        >
                          {"40%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("50")
                          }}
                        >
                          {"50%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("60")
                          }}
                        >
                          {"60%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("70")
                          }}
                        >
                          {"70%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("80")
                          }}
                        >
                          {"80%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("90")
                          }}
                        >
                          {"90%"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setCompletionSelected("100")
                          }}
                        >
                          {"100%"}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Dropdown
                      isOpen={batchDropdown}
                      toggle={() => setBatchDropdown(!batchDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Batch : " + batchSelectedDisplay}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setBatchSelected("")
                            setBatchSelectedDisplay("")
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        {batchData
                          ? batchData.map((object, index) => (
                              <DropdownItem
                                onClick={() => {
                                  setBatchSelected(object.id)
                                  setBatchSelectedDisplay(object.name)
                                }}
                              >
                                {object.name}
                              </DropdownItem>
                            ))
                          : null}
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  <Col xs="auto">
                    <Dropdown
                      isOpen={dateDropdown}
                      toggle={() => setDateDropdown(!dateDropdown)}
                    >
                      <DropdownToggle className="btn btn-primary" caret>
                        {"Date : " + dateSelected}{" "}
                        <i className="mdi mdi-chevron-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setDateSelected("")
                            setDateStartSelected(null)
                            setDateEndSelected(null)
                          }}
                        >
                          {"All"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setDateSelected("Today")
                            setDateStartSelected(new Date())
                            setDateEndSelected(null)
                          }}
                        >
                          {"Today"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setDateSelected("7 Days ago")
                            let myDate = new Date()
                            let minusSeven = new Date(
                              myDate.setDate(myDate.getDate() - 7)
                            )
                            setDateStartSelected(minusSeven)
                            setDateEndSelected(new Date())
                          }}
                        >
                          {"7 Days ago"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setDateSelected("30 Days ago")
                            let myDate = new Date()
                            let minusThirty = new Date(
                              myDate.setDate(myDate.getDate() - 30)
                            )
                            setDateStartSelected(minusThirty)
                            setDateEndSelected(new Date())
                          }}
                        >
                          {"30 Days ago"}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setDateSelected("Custom Date")
                            setDateStartSelected(null)
                            setDateEndSelected(null)
                          }}
                        >
                          {"Custom Date"}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Col>
                  {dateSelected == "Custom Date" && (
                    <Col xs="auto">
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="Date Range"
                        options={{
                          mode: "range",
                          dateFormat: "d-m-y",
                        }}
                        onChange={e => {
                          if (e.length == 2) {
                            setDateStartSelected(e[0])
                            setDateEndSelected(e[1])
                          } else if (e.length == 1) {
                            setDateStartSelected(e[0])
                            setDateEndSelected(null)
                          }
                        }}
                        ref={refDate}
                      />
                    </Col>
                  )}
                </Row>
                <CardTitle className="font-weight-bold font-size-20">
                  Order
                </CardTitle>
                <MDBDataTable
                  responsive
                  bordered
                  hover
                  noBottomColumns
                  data={data}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <ModalHeader toggle={() => setModal(!modal)}>
              Order Details
            </ModalHeader>
            <ModalBody>
              {Object.entries(modalData).map(([key, value]) => (
                <Row className="mb-1">
                  <Col>
                    <span className="font-weight-bold">{key + " "}</span>
                  </Col>
                  <Col>
                    <span>{value}</span>
                  </Col>
                </Row>
              ))}
            </ModalBody>
            <ModalFooter>
              <Button type="button" onClick={() => setModal(!modal)}>
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(OrderListAdmin))

OrderListAdmin.propTypes = { user: PropTypes.object }
