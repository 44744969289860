import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Button,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardSubtitle,
  Alert,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  Spinner,
  DropdownMenu,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import * as url from "helpers/url_helper"
import { MDBDataTable } from "mdbreact"
import { success } from "toastr"
import { AvForm, AvField } from "availity-reactstrap-validation"
import TitleBar from "./TitleBar"

const PhotoListAdmin = props => {
  const history = useHistory()

  const [modal, setModal] = useState(false)
  const [modalPhotoCountry, setModalPhotoCountry] = useState(false)

  var formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })

  useEffect(() => {
    callOrderAPI()
  }, [])

  useEffect(() => {
    // console.log("propsuser", props.user)
    if (props.user) {
      const userJSON = JSON.parse(props.user)
      if (userJSON.id == 1 || userJSON.id == 66) {
        // setadmLogin(true)
      } else {
        history.push("/admzzz-home")
      }
    } else {
      history.push("/admzzz-home")
    }
  }, [])

  const [photoData, setPhotoData] = useState([])
  const [countryData, setCountryData] = useState([])

  const [photoFilter, setPhotoFilter] = useState("")

  const updatePhotoFilter = e => {
    setPhotoFilter(e.target.value)
  }

  const callOrderAPI = async () => {
    const response = await fetch(url.HAPIVITY_GET_ADMIN_PHOTO_LIST)

    const postresponse = await response.json()

    if (postresponse.status == 200) {
      console.log("Admin photo list", postresponse.data)
      setPhotoData(postresponse.data)
      if (selectedPhotoData) {
        if (
          postresponse.data.findIndex(
            item => item.id == selectedPhotoData.id
          ) >= 0
        )
          setSelectedPhotoData(
            postresponse.data.find(item => item.id == selectedPhotoData.id)
          )
      }
    }

    const shippingResponse = await fetch(url.HAPIVITY_GET_ADMIN_SHIPPING_LIST)

    const postShippingResponse = await shippingResponse.json()

    if (postShippingResponse.status == 200) {
      console.log("Admin shipping list", postShippingResponse.data)
      setCountryData(postShippingResponse.data)
    }
  }

  const [selectedPhotoData, setSelectedPhotoData] = useState(null)
  const [actionType, setActionType] = useState(null)

  const onclick_edit_photo = selectedPhotoData => {
    setModal(true)
    setActionType("edit")
    setSelectedPhotoData(selectedPhotoData)
  }

  const onclick_add_photo = () => {
    setModal(true)
    setActionType("add")
    setSelectedPhotoData(null)
  }

  function handleValidSubmit(event, values) {
    onclick_apply_photo(values)
  }

  const onclick_apply_photo = async formValues => {
    console.log(formValues)

    let formdata = new FormData()
    formdata.append("url", formValues.url)
    formdata.append("pid", selectedPhotoData ? selectedPhotoData.id : 0)
    formdata.append("name", formValues.name ? formValues.name : "")
    formdata.append("action", actionType)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(url.HAPIVITY_EDIT_PHOTO, requestOptions)
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      setModal(false)
      callOrderAPI()
    }
  }

  const onclick_edit_country = selectedPhotoData => {
    setModalPhotoCountry(true)
    setSelectedPhotoData(selectedPhotoData)
  }

  const onclick_add_remove_country = async (action, sid) => {
    console.log(action + " " + sid + " to " + selectedPhotoData.id)
    let formdata = new FormData()
    formdata.append("sid", sid)
    formdata.append("pid", selectedPhotoData.id)
    formdata.append("action", action)

    const requestOptions = {
      method: "POST",
      body: formdata,
    }

    const response = await fetch(
      url.HAPIVITY_EDIT_PHOTO_COUNTRY,
      requestOptions
    )
    const postresponse = await response.json()

    if (postresponse.status == 200) {
      callOrderAPI()
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Hapivity</title>
      </MetaTags>
      <div className="page-content container-fluid">
        <Row>
          <Col className="col-12">
            <Card className="bg-transparent">
              <CardBody>
                <TitleBar />
                <CardTitle className="font-size-20 font-weight-bold">
                  Photo List
                </CardTitle>
                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block mb-3"
                  style={{ height: "40px" }}
                  onClick={() => {
                    // history.push("/admzzz-shop")
                    onclick_add_photo()
                  }}
                >
                  {"Add a new Photo"}
                </button>
                <AvForm>
                  <AvField
                    name="photoFilter"
                    // label="Name"
                    value={photoFilter}
                    className="form-control font-weight-semibold font-size-14 mb-3"
                    placeholder="Search photo"
                    type="text"
                    style={{
                      // border: "2px solid",
                      // borderColor: "#F3F4F8",
                      borderWidth: "2px",
                      borderRadius: "8px",
                      height: "40px",
                    }}
                    onChange={updatePhotoFilter}
                  />
                </AvForm>
                <Row>
                  {photoData &&
                    photoData
                      .filter(object =>
                        object.name
                          .toLowerCase()
                          .includes(photoFilter.toLowerCase())
                      )
                      .map(item => (
                        <Col md={3}>
                          <Card className="p-3">
                            <Row>
                              <Col>
                                <Row className="mb-2">
                                  <Col>
                                    <span className="font-size-16 font-weight-bold">
                                      {item.name ? item.name : "-"}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col>
                                    <span className="font-size-16 font-weight-bold">
                                      {item.url}
                                    </span>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col>
                                    <CardImg
                                      src={
                                        item.url +
                                        "?w=100&h=100&fit=clip&dpr=1&q=75&auto=format"
                                      }
                                      alt="shop image"
                                      style={{
                                        width: "100px",
                                        objectFit: "cover",
                                        aspectRatio: "1/1",
                                      }}
                                      className="border-radius-16"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs="auto">
                                    <button
                                      className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                      style={{ height: "40px" }}
                                      onClick={() => {
                                        // history.push("/admzzz-shop")
                                        onclick_edit_photo(item)
                                      }}
                                    >
                                      {"Edit Photo"}
                                    </button>
                                  </Col>
                                  <Col xs="auto">
                                    <button
                                      className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                                      style={{ height: "40px" }}
                                      onClick={() => {
                                        // history.push("/admzzz-shop")
                                        onclick_edit_country(item)
                                      }}
                                    >
                                      {"Add/Remove Country"}
                                    </button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setModal(!modal)}
          isOpen={modal}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <AvForm onValidSubmit={(e, v) => handleValidSubmit(e, v)}>
              <ModalHeader toggle={() => setModal(!modal)}>
                {actionType == "add" ? "Add" : "Edit"}
                {" Photo"}
              </ModalHeader>
              <ModalBody>
                {
                  <>
                    <AvField
                      name="name"
                      label="Name"
                      value={selectedPhotoData ? selectedPhotoData.name : null}
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="Name"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                    />
                    <AvField
                      name="url"
                      label="URL"
                      value={selectedPhotoData ? selectedPhotoData.url : null}
                      className="form-control font-weight-semibold font-size-14 mb-3"
                      placeholder="URL"
                      type="text"
                      style={{
                        // border: "2px solid",
                        // borderColor: "#F3F4F8",
                        borderWidth: "2px",
                        borderRadius: "8px",
                        height: "40px",
                      }}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter url",
                        },
                      }}
                    />
                  </>
                }
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-primary font-weight-bold font-size-12 border-radius-8 btn-block"
                  style={{ height: "38px" }}
                  type="submit"
                >
                  {"Save"}
                </button>
                <Button type="button" onClick={() => setModal(!modal)}>
                  Close
                </Button>
              </ModalFooter>
            </AvForm>
          </div>
        </Modal>

        <Modal
          toggle={() => setModalPhotoCountry(!modalPhotoCountry)}
          isOpen={modalPhotoCountry}
          role="dialog"
          centered={true}
          autoFocus={true}
          tabIndex="-1"
        >
          <div>
            <ModalHeader
              toggle={() => setModalPhotoCountry(!modalPhotoCountry)}
            >
              Add/Remove Country
            </ModalHeader>
            <ModalBody>
              {selectedPhotoData && (
                <Row className="mb-3">
                  <Col>
                    <span className="font-size-16 font-weight-bold">
                      {selectedPhotoData.name}
                    </span>
                  </Col>
                </Row>
              )}
              {countryData &&
                countryData.map(item => (
                  <Row className="py-2">
                    <Col>{item.country}</Col>
                    {selectedPhotoData && (
                      <Col xs="auto">
                        {selectedPhotoData.country.findIndex(
                          object => object.sid == item.id
                        ) < 0 ? (
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_add_remove_country("add", item.id)
                            }}
                          >
                            {"Add"}
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary font-weight-bold font-size-12 border-radius-12 btn-block"
                            style={{ height: "40px" }}
                            onClick={() => {
                              onclick_add_remove_country("remove", item.id)
                            }}
                          >
                            {"Remove"}
                          </button>
                        )}
                      </Col>
                    )}
                  </Row>
                ))}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                onClick={() => setModalPhotoCountry(!modalPhotoCountry)}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { user } = state.Login
  return { user }
}

export default withRouter(connect(mapStateToProps, {})(PhotoListAdmin))

PhotoListAdmin.propTypes = { user: PropTypes.object }
