import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardImg,
  Tooltip,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  CardSubtitle,
  Badge,
  Spinner,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap"

import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";


export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://hapivity.com/store-payment-result?oid=" + props.oid,
        // return_url: "https://localhost:8000/store-payment-result?oid=" + props.oid,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Row className="px-2">
          <button
            className="btn-primary text-white btn-block font-weight-extrabold font-size-14 border-radius-16 align-middle mt-3"
            style={{ height: "56px" }}
            id="submit"
            // onClick={() => {
            //   // pay()
            //   // setPaymentError(null)
            // }}
            disabled={isLoading || !stripe || !elements}
          >
            {(isLoading || !stripe || !elements) ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-1" />
            ) : (
              <i
                className={
                  "bx bx-credit-card font-size-16 align-middle me-1"
                }
              />
            )}{" "}
            {(isLoading || !stripe || !elements)
              ? "Proceeding to payment"
              : "Pay now" }
          </button>
      </Row>
      {/* <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button> */}
      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
      {message && (
                    <Row className="mt-3">
                      <Col>
                        <Alert
                          color="danger"
                          role="alert"
                          className="border-radius-8 font-size-14 font-weight-semibold align-middle"
                        >
                          {message}
                        </Alert>
                      </Col>
                    </Row>
                  )}
    </form>
  );
}